import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Modal, Input, InputNumber } from 'antd';
import { } from '@ant-design/icons';
import { AccountInvoiceDetailsModel } from './AccountInvoiceDetailsModel';

interface AccountInvoiceRefundModalProps {
  model: AccountInvoiceDetailsModel;
  onOk?: (response: any) => any;
  onCancel?: () => any;
  afterClose?: () => any;
}

export const AccountInvoiceRefundModal: React.FC<AccountInvoiceRefundModalProps> = observer(({ model, onOk, onCancel, afterClose }) => {
  const { refundVisible } = model;
  const [form] = Form.useForm();

  useEffect(() => {
    if (refundVisible) {
      form.setFieldsValue({
        amount: '',
        reason: ''
      });
    }
  }, [form, refundVisible]);

  return (
    <Modal
      title="Refund Transaction"
      visible={refundVisible}
      onOk={() => form.submit()}
      afterClose={afterClose}
      okText="Refund"
      onCancel={model.handleCancelRefund}
      confirmLoading={model.refundLoading}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={model.handleOkRefund}
      >
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{
            validator: (_rule: any, value: any) => {
              if (!value) {
                return Promise.reject('Please input amount');
              }

              return Promise.resolve();
            }
          }]}
          required
        >
          <InputNumber
            style={{ minWidth: '30%' }}
            placeholder="Amount"
            min={0}
            formatter={(value: any) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => String(value).replace(/(,*)/g, '')}
          />
        </Form.Item>

        <Form.Item
          label="Reason"
          name="reason"
          rules={[
            { required: true, message: 'Please input refund reason' },
            { min: 2, message: 'Reason should be at least 2 characters long' },
          ]}
        >
          <Input.TextArea placeholder="Reason" rows={4} autoSize={{ minRows: 3, maxRows: 10 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
});