import React, { useEffect } from 'react';
import { EventEmitter } from 'utils/EventEmitter';

interface BaseProps {
  callback: (payload: any) => any;
}

interface WithEventProps {
  event: string;
  events?: string[];
}

interface WithEventsProps {
  event?: string;
  events: string[];
}

type EventListenerProps = BaseProps & (WithEventProps | WithEventsProps)

export const EventListener: React.FC<EventListenerProps> = ({ children, event, events, callback }) => {
  // eslint-disable-next-line
  useEffect(() => EventEmitter.on((events ? events.join(',') : event) || '', callback), []);

  return children as any;
}