import bind from 'bind-decorator';
import { observable } from 'mobx';

export interface Pagination {
  skip: number,
  take: number
}

export class Paginable {
  @observable loading: boolean = false;
  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 10,
    total: 0
  };

  @observable filters: IIndexable = {};
  @observable sorter: IIndexable = {};

  loaded: boolean = false;

  @bind
  async fetchFromApi(pagination?: Pagination, filters?: IIndexable): Promise<any> {
    return Promise.resolve({ pagination, filters });
  }

  @bind
  async processResponse(response: any) {
    return Promise.resolve();
  }

  @bind
  async fetchWithPaging(pagination?: Pagination) {
    this.loading = true;

    try {
      const filters = this.getResultFilters();
      const response: any = await this.fetchFromApi(pagination, filters);
      const responseData: any = response.data.data;

      await this.processResponse(responseData);

      this.pagintaion.skip = responseData.skip;
      this.pagintaion.take = responseData.take;
      this.pagintaion.total = responseData.total;
      this.pagintaion.page = (responseData.skip / responseData.take) + 1;
    } catch (e) {
    }

    this.loading = false;
    this.loaded = true;
  }

  @bind
  takeChange(_current: number, pageSize: number) {
    this.fetchWithPaging({
      skip: 0,
      take: pageSize
    });
  }

  @bind
  paginationChange(current: number) {
    this.fetchWithPaging({
      skip: (current - 1) * this.pagintaion.take,
      take: this.pagintaion.take
    });
  }

  @bind
  getResultFilters() {
    const filters = this.filters;
    const sorter = this.sorter;

    let resultFilters: IIndexable = {};

    if (sorter.order) {
      resultFilters.orderBy = sorter.columnKey;
      resultFilters.orderDirection = sorter.order === 'ascend' ? 0 : 1;
    }

    resultFilters = { ...resultFilters, ...this.mapFilters(filters) };

    return resultFilters;
  }

  @bind
  mapFilters(filters: IIndexable) {
    return filters;
  }

  @bind
  tableFiltersChange(_pagination: any, filters: IIndexable, sorter: IIndexable) {
    this.filters = filters;
    this.sorter = sorter;

    this.fetchWithPaging({
      skip: 0,
      take: this.pagintaion.take
    });
  }

  @bind
  refresh() {
    this.fetchWithPaging({
      skip: this.pagintaion.skip,
      take: this.pagintaion.take
    });
  }
}