import React, { useState, useEffect, useMemo } from 'react';
import Payment from 'payment';
import Cards from 'react-credit-cards';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { Row, Col, Form, Input, Button, Typography, Space } from 'antd';
import {} from '@ant-design/icons';
import { InvoiceViewModel } from './InvoiceViewModel';
import { hexToRgb } from 'utils/Colors';

interface InvoiceViewFormProps {
  model: InvoiceViewModel;
}

export const InvoiceViewForm: React.FC<InvoiceViewFormProps> = observer(({ model }) => {
  const [validateTrigger, setValidateTrigger] = useState('onSubmit');
  const appearanceClass = 'animate__animated animate__fadeIn';
  const color = model.account?.accentColor || '#96c94e';
  const inputStyled = useMemo(() => {
    const rgbColor = hexToRgb(color);

    return style({
      $nest: {
        '&:hover': {
          borderColor: color
        },
        '&:focus': {
          borderColor: color,
          boxShadow: `0 0 0 2px rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.2)`
        }
      }
    });
  }, [color]);

  // auto scroll on reveal
  useEffect(() => {
    setTimeout(() => {
      const payEl: HTMLDivElement | null = document.querySelector('.invoice-pay');
      const inputEl: HTMLInputElement | null = document.querySelector('.ant-form input');

      payEl?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      // auto focus
      setTimeout(() => inputEl?.focus(), 300);
    });
  }, []);

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: 16, textAlign: 'center' }}>
        Credit Card Authorization
      </Typography.Title>
      <Row className="invoice-pay">
        <Col span={12}>
          <div className={appearanceClass}>
            <Cards
              number={model.number}
              name={model.name}
              expiry={model.expiry}
              cvc={model.cvc}
              focused={model.focused}
            />
          </div>
        </Col>
        <Col span={12}>
          {model.paymentSuccess && (
            <div className="invoice-pay-success">
              <div className="success-checkmark">
                <div className="check-icon">
                  <span className="icon-line line-tip"></span>
                  <span className="icon-line line-long"></span>
                  <div className="icon-circle"></div>
                  <div className="icon-fix"></div>
                </div>
              </div>
              <Typography.Title level={4}>Payment received!</Typography.Title>
              {model.retUrl ? (
                <Button type="primary" onClick={() => window.location.href = model.retUrl}>
                  Go Back To Website
                </Button>
              ) : (
                <Typography.Paragraph>Thank you.</Typography.Paragraph>
              )}
            </div>
          )}
          <div className="invoice-pay-form">
            <Form
              className={appearanceClass}
              onValuesChange={model.onValuesChange}
              onFinish={model.handleSubmit}
              onFinishFailed={() => setValidateTrigger('onChange')}
              onFocus={model.handleFocus}
            >
              <Form.Item
                name="number"
                rules={[{
                  required: true,
                  message: 'Please input card number'
                }, {
                  validator: (_rule: any, value: any) => {
                    if (!Payment.fns.validateCardNumber(value)) {
                      return Promise.reject('Please input correct card number');
                    }

                    return Promise.resolve();
                  }
                }]}
                validateTrigger={validateTrigger}
                validateFirst
              >
                <Input name="number" type="tel" placeholder="Card Number" maxLength={64} className={inputStyled}/>
              </Form.Item>

              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input your name on card' }]}
                validateTrigger={validateTrigger}
                validateFirst
              >
                <Input name="name" placeholder="Name on Card" maxLength={64} className={inputStyled}/>
              </Form.Item>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="expiryMonth"
                    rules={[{ required: true, message: 'Required' }]}
                    validateTrigger={validateTrigger}
                    validateFirst
                  >
                    <Input type="tel" name="expiryMonth" placeholder="Month" maxLength={2} className={inputStyled}/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="expiryYear"
                    rules={[{
                      required: true,
                      message: 'Required'
                    }, ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }

                        if (!Payment.fns.validateCardExpiry(`${getFieldValue('expiryMonth')}/${value}`)) {
                          return Promise.reject('Invalid expiry');
                        }

                        return Promise.resolve();
                      },
                    })]}
                    validateTrigger={validateTrigger}
                    validateFirst
                  >
                    <Input type="tel" name="expiryYear" placeholder="Year" maxLength={4} className={inputStyled}/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="cvc"
                    rules={[{
                      required: true,
                      message: 'Required'
                    }, {
                      validator: (_rule: any, value: any) => {
                        if (!Payment.fns.validateCardCVC(value)) {
                          return Promise.reject('Invalid cvc');
                        }

                        return Promise.resolve();
                      }
                    }]}
                    validateTrigger={validateTrigger}
                    validateFirst
                  >
                    <Input type="tel" name="cvc" placeholder="CVC" maxLength={5} className={inputStyled}/>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={model.formLoading}
                    style={{ backgroundColor: color, borderColor: color }}
                  >
                    Submit
                  </Button>
                  {model.paymentError && (
                    <span className="ant-form-item-has-error">
                    <span className="ant-form-item-explain">
                      Credit card operation error.
                    </span>
                  </span>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
});