import bind from 'bind-decorator';
import { observable } from 'mobx';
import { Account, AccountProps } from 'models/Account';
import { Paginable, Pagination } from 'models/Paginable';
import { getAccounts } from 'services/Admin';

export class AdminAccountsModel extends Paginable {
  @observable accounts: Account[] = [];
  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 50,
    total: 0
  };

  @bind
  async processResponse(response: any) {
    this.accounts = response.items.map((item: AccountProps) => {
      return new Account(item);
    });
  }

  @bind
  async fetchFromApi(pagination?: Pagination, filters?: IIndexable) {
    return await getAccounts(pagination, filters);
  }

  @bind
  mapFilters(filters: IIndexable) {
    const resultFilters: IIndexable = {};

    if (filters.name) {
      resultFilters.name = filters.name[0];
    }

    return resultFilters;
  }
}