import { getIpAddress } from 'services/Ipify';
import { AuthStore } from 'stores/Auth';

const checkIpAccounts = [
  '18076284-2b3e-4905-8ed1-169c9576206e',
  '0e983cf3-535d-4457-b145-c4105e08adb0'
];
const allowRules: IIndexable = {
  '0e983cf3-535d-4457-b145-c4105e08adb0': (ip: string) => { // Brookfield Acura
    return ['209.64.109.62', '69.129.237.254'].includes(ip);
  }
};

let currentIp = '';

export const getIp = async () => {
  if (currentIp) {
    return currentIp;
  }

  const ipAddress = await getIpAddress();

  currentIp = ipAddress;

  return currentIp;
};

export const shouldCheckIp = (accountId: string) => {
  return AuthStore.authenticated && !AuthStore.isAdmin && checkIpAccounts.includes(accountId);
};

export const shouldRestrictIp = async (accountId: string) => {
  const ip = await getIp();
  const rule = allowRules[accountId];

  if (rule) {
    return !rule(ip);
  }

  return true;
};