import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { Result, Button } from 'antd';
import { AuthStore } from '../stores/Auth';
import { GlobalStore } from '../stores/Global';
import { AccountsStore } from '../stores/Accounts';
import { shouldCheckIp } from 'utils/IpRestrictions';
import { IpifyCheck } from 'components/IpifyCheck';

export const AccountRoute = ({ component: Component, ...rest }: any) => {
  const { params }: { params: { accountId: string } } = useRouteMatch();
  const currentAccount = AccountsStore.getAccount(params.accountId);
  const currentAccountId = currentAccount ? currentAccount.accountId : '';

  useEffect(() => {
    if (currentAccountId) {
      GlobalStore.setCurrentAccountId(currentAccountId);
    }
  }, [currentAccountId]);

  if (shouldCheckIp(currentAccountId)) {
    return (
      <Route {...rest} render={props => (
        <IpifyCheck
          accountId={currentAccountId}
          pass={() => (
            <Component {...props} account={currentAccount} />
          )}
          restrict={(ip: string) => (
            <Result
              status="403"
              title="403"
              subTitle={`Sorry, this page is forbidden. Your ip address is ${ip}.`}
            />
          )}
        />
      )} />
    );
  }

  return (
    <Route {...rest} render={props => (
      AuthStore.authenticated ? (
        currentAccount ? (
          <Component {...props} account={currentAccount} />
        ) : (
            <Result
              status="404"
              title="404"
              subTitle="Sorry, we cannot find given company account for you."
              extra={
                <Button type="primary" onClick={() => GlobalStore.navigate('/')}>
                  Back Home
                </Button>
              }
            />
          )
      ) : (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
          />
        )
    )} />
  );
};