interface EventEmitterEvents {
  [key: string]: EventEmitterCallback[];
}

interface EventEmitterCallback {
  (payload: any): any;
}

const events: EventEmitterEvents = {};

export const EventEmitter = {
  on: (event: string, callback: EventEmitterCallback) => {
    if (!event) {
      return;
    }

    const eventKeys: string[] = event.split(',').map((e) => e.trim());
    const disposers: (() => any)[] = [];

    eventKeys.forEach((eventKey: string) => {
      if (!events[eventKey]) {
        events[eventKey] = [];
      }

      const length = events[eventKey].push(callback);

      disposers.push(() => events[eventKey].splice(length - 1, 1));
    });


    return () => disposers.forEach((dispose) => dispose());
  },
  trigger: (event: string, payload?: any) => {
    if (!events[event]) {
      return;
    }

    events[event].forEach((callback) => callback(payload));
  },
  off: (event: string, callback?: EventEmitterCallback) => {
    if (!events[event]) {
      return;
    }

    if (callback) {
      const index = events[event].indexOf(callback);

      if (index >= 0) {
        events[event].splice(index, 1);
      }
    } else {
      events[event] = [];
    }
  }
};