import qs from 'query-string';
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { GlobalStore } from 'stores/Global';
import { AuthStore } from 'stores/Auth';
import { notification } from 'antd';

const apiUrl = (url: string): string => {
  let middle = url[0] !== '/' ? '/' : '';

  return `${GlobalStore.apiUrl}${middle}${url}`;
};
const withToken = (config: AxiosRequestConfig = {}): AxiosRequestConfig => {
  if (!config.headers) {
    config.headers = {};
  }

  if (AuthStore.token) {
    config.headers.Authorization = AuthStore.token;
  }

  return config;
};

export const Api = {
  post(url: string, data: object, config = {}) {
    return Axios
      .post(apiUrl(url), data, withToken(config))
      .catch((err) => {
        this.handleError(err);

        throw err;
      });
  },

  put(url: string, data: object, config = {}) {
    return Axios
      .put(apiUrl(url), data, withToken(config))
      .catch((err) => {
        this.handleError(err);

        throw err;
      });
  },

  get(url: string, params = {}, config = {}) {
    let query = qs.stringify(params);

    return Axios
      .get(`${apiUrl(url)}${query ? '?' + query : ''}`, withToken(config))
      .catch((err) => {
        this.handleError(err);

        throw err;
      });
  },

  delete(url: string, config = {}) {
    return Axios
      .delete(apiUrl(url), withToken(config))
      .catch((err) => {
        this.handleError(err);

        throw err;
      });
  },

  handleError(err: AxiosError) {
    const responseData: any = err.response && err.response.data;
    const responseConfig: any = err.response && err.response.config;

    // skip outside handled errors
    if (err.response && responseConfig.errorsHandled && responseConfig.errorsHandled.includes(err.response.status)) {
      return;
    }

    if (err.response && err.response.status === 401) {
      if (responseConfig.authorizationRequest) {
        AuthStore.sessionExpired(false);
      } else {
        AuthStore.sessionExpired();
      }

      return;
    }

    if (responseData) {
      if (responseData.error) {
        notification.error({
          message: 'Request Error',
          description: responseData.error.message
        });
      } else {
        notification.error({
          message: 'Request Error',
          description: 'There was an error while performing request'
        });
      }
    } else {
      if (err.response && err.response.status) {
        notification.error({
          message: 'Request Error',
          description: 'There was an error while performing request. Try again later'
        });
      } else {
        notification.error({
          message: 'Request Error',
          description: 'Internet Connection appears to be offline'
        });
      }
    }

    if (process.env.NODE_ENV === 'development') {
      console.error('%O', err);
    }
  }
};