import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Modal, Input, Select } from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { AccountAccessModel } from './AccountAccessModel';
import { AccountUserRoles, AccountUserNamesExplain } from 'models/AccountUser';

interface AccountInviteModalProps {
  model: AccountAccessModel;
}

export const AccountInviteModal: React.FC<AccountInviteModalProps> = observer(({ model }) => {
  const { modalVisible } = model;
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        name: '',
        email: '',
        message: '',
        role: AccountUserRoles.OWNER
      });
    }
  }, [form, modalVisible]);

  return (
    <Modal
      title="Invite User"
      visible={modalVisible}
      onOk={() => form.submit()}
      okText="Invite"
      onCancel={model.handleCancel}
      confirmLoading={model.modalLoading}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={model.handleOk}
      >
        <Form.Item
          label="User Name"
          name="name"
          rules={[
            { required: true, message: 'Please input user name' },
            { min: 4, message: 'User name should be at least 4 characters long' }
          ]}
        >
          <Input placeholder="User Name" prefix={<UserOutlined />} autoFocus />
        </Form.Item>

        <Form.Item
          label="User Email"
          name="email"
          rules={[
            { required: true, message: 'Please input user email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
        >
          <Input placeholder="User Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          label="Personalized Message (Optional)"
          name="message"
        >
          <Input.TextArea placeholder="Message" rows={4} autoSize={{ minRows: 3, maxRows: 10 }} />
        </Form.Item>

        <Form.Item
          label="User Role"
          name="role"
        >
          <Select>
            {Object
              .keys(AccountUserRoles)
              .filter((key) => (AccountUserRoles as IIndexable)[key] !== AccountUserRoles.NONE)
              .map((key) => (
                <Select.Option
                  key={key}
                  value={(AccountUserRoles as IIndexable)[key]}
                >
                  {(AccountUserNamesExplain as IIndexable)[key]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
});