import bind from 'bind-decorator';
import { observable } from 'mobx';
import { getCustomer } from 'services/Customers';
import { Customer } from 'models/Customer';
import { openInvoiceChargeModal } from 'components/Account/View/AccountInvoiceModal';
import { Invoice } from 'models/Invoice';

export class AccountCustomerDetailsModel {
  @observable loading: boolean = true;
  @observable customer: Customer | null = null;

  accountId: string = '';
  customerId: string = '';

  constructor(accountId: string, customerId: string) {
    this.accountId = accountId;
    this.customerId = customerId;
  }

  @bind
  async fetchCustomer() {
    this.loading = true;

    try {
      const response = await getCustomer(this.accountId, this.customerId);

      this.customer = new Customer(response.data.data);
    } catch (e) {
    }

    this.loading = false;
  }

  @bind
  chargeInvoice(invoices: Invoice[]) {
    openInvoiceChargeModal(this.accountId, this.customer!, invoices, () => {
    });
  }
}