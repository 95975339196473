import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthStore } from '../stores/Auth';

export const HomeRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route {...rest} render={props => (
      AuthStore.authenticated ? (
        <Component {...props} />
      ) : (
          <Redirect to={{
            pathname: '/sign-in',
            state: { from: props.location }
          }} />
        )
    )} />
  );
};