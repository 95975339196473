import React from 'react';
import { InvoiceViewModel } from './InvoiceViewModel';
import { formatCurrency } from 'utils/Currency';

interface InvoiceViewBodyProps {
  model: InvoiceViewModel;
}

export const InvoiceViewBody: React.FC<InvoiceViewBodyProps> = ({ model }) => {
  const color = model.account?.accentColor || '#96c94e';
  const total = model.invoice?.amount || 0;
  const tax = model.invoice?.tax || 0;

  return (
    <table style={{ marginTop: '32px' }}>
      <thead>
      <tr>
        <th align="left">
          Description
        </th>
        <th align="center"></th>
        <th align="center">
          Amount
        </th>
      </tr>
      </thead>
      <tbody>
      {tax ? (
        <tr>
          <td style={{ padding: '8px 0' }}>
            Service Charge:
          </td>
          <td>
          </td>
          <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
            {formatCurrency(total, model.invoice?.currency)}
          </td>
        </tr>
      ) : null}
      {tax ? (
        <tr>
          <td style={{ padding: '8px 0' }}>
            Taxes:
          </td>
          <td>
          </td>
          <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
            {formatCurrency(tax, model.invoice?.currency)}
          </td>
        </tr>
      ) : null}
      <tr>
        <td style={{ padding: '8px 0' }}>
          {tax ? 'TOTAL:' : 'Service Charge:'}
        </td>
        <td>
        </td>
        <td style={{ borderBottom: `1px solid ${color}`, padding: '8px 0' }} align="center">
          <strong style={{ color }}>{formatCurrency(total + tax, model.invoice?.currency)}</strong>
        </td>
      </tr>
      </tbody>
    </table>
  );
};