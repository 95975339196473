import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { style } from 'typestyle';
import { Button, Result, Skeleton, Space } from 'antd';
import { FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { InvoiceViewModel } from './InvoiceViewModel';
import { proxyImage } from 'utils/Image';
import { InvoiceViewForm } from './InvoiceViewForm';
import { InvoiceViewBodyRO } from './InvoiceViewBodyRO';
import { InvoiceViewBody } from './InvoiceViewBody';
import { InvoiceStatus } from 'models/Invoice';

import 'react-credit-cards/es/styles-compiled.css';
import './InvoiceStyle.less';

interface InvoiceViewProps {
  model: InvoiceViewModel;
}

export const InvoiceView: React.FC<InvoiceViewProps> = observer(({ model }) => {
  const [formVisible, setFormVisible] = useState(false);
  const appearanceClass = 'animate__animated animate__fadeIn';
  const color = model.account?.accentColor || '#96c94e';
  const logo = proxyImage(model.account?.logo || '');
  const buttonStyled = useMemo(() => {
    return style({
      $nest: {
        '&:active, &:hover, &:focus': {
          borderColor: color,
          color
        }
      }
    });
  }, [color]);

  const showPayment = useCallback((e: any) => {
    e.preventDefault();

    setFormVisible(true);
  }, [setFormVisible]);

  if (model.error) {
    if (model.error.response && model.error.response.status === 404) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, we cannot find this invoice."
        />
      );
    }

    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        }
      />
    );
  }

  if (model.loading) {
    return (
      <div className="invoice-check-page">
        <table cellPadding={0} cellSpacing={0} className="body" style={{ border: 'none' }}>
          <tbody>
          <tr>
            <td className="container">
              <div className="content">
                <Skeleton title={{ width: '100%' }} paragraph={{ rows: 0 }} active/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }

  const paymentButton = model.invoice?.status === InvoiceStatus.PENDING ? (
    <table cellPadding={0} cellSpacing={0} className="btn btn-primary" style={{ border: 'none' }}>
      <tbody>
      <tr>
        <td align="center">
          <table cellPadding={0} cellSpacing={0} style={{ border: 'none', margin: '0 auto' }}>
            <tbody>
            <tr>
              <td>
                <a
                  href={window.location.href}
                  onClick={showPayment}
                  style={{ backgroundColor: color }}
                >
                  Make Secure Online Payment
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
    </table>
  ) : null;

  return (
    <div className="invoice-check-page">
      <table cellPadding={0} cellSpacing={0} className="body" style={{ border: 'none' }}>
        <tbody className={appearanceClass}>
        <tr>
          <td>&nbsp;</td>
          <td className="container">
            <div className="content">
              <Space className="invoice-check-page__buttons">
                  <Button
                    className={buttonStyled}
                    icon={<PrinterOutlined/>}
                    onClick={model.print}
                  >
                    Print Invoice
                  </Button>
                  <Button
                    className={buttonStyled}
                    icon={<FilePdfOutlined/>}
                    loading={model.pdfLoading}
                    onClick={model.downloadPdf}
                  >
                    Download PDF
                  </Button>
              </Space>
              {/* START CENTERED WHITE CONTAINER */}
              <table className="main">
                {/* START MAIN CONTENT AREA */}
                <tbody>
                <tr>
                  <td align="center" style={{ paddingTop: '18px' }}>
                    <img src={logo} width={160} alt={model.account?.name}/>
                  </td>
                </tr>
                <tr>
                  <td className="wrapper" style={{ paddingTop: '0px' }}>
                    <table cellPadding={0} cellSpacing={0} style={{ border: 'none' }}>
                      <tbody>
                      <tr>
                        <td>
                          <table>
                            <tbody>
                            <tr>
                              <td>Invoice #<strong>{model.invoice?.order}</strong></td>
                              <td style={{ paddingBottom: '12px' }}>{moment(model.invoice?.created).format('ll')}</td>
                            </tr>
                            <tr>
                              <td style={{ width: '65%' }}>
                                <div>
                                  From:
                                </div>
                                <strong>{model.account?.name}</strong>
                                <div style={{ maxWidth: 155 }}>
                                  {model.account?.address}
                                </div>
                              </td>
                              <td>
                                <div>
                                  Bill To:
                                </div>
                                <strong>{model.invoice?.customer.name || '—'}</strong>
                                {model.invoice?.customer.company && model.invoice?.customer.name !== model.invoice?.customer.company ? (
                                  <div style={{ maxWidth: 155 }}>
                                    {model.invoice?.customer.company}
                                  </div>
                                ) : null}
                                <div style={{ maxWidth: 155 }}>
                                  {model.invoice?.customer.address || model.repairOrder?.customerAddress}
                                </div>
                                {model.invoice?.billingFrom ? <div>Billing Period:</div> : null}
                                {model.invoice?.billingFrom ? <div>{moment(model.invoice.billingFrom).format('MMM D, YYYY')} - {moment(model.invoice.billingTo).format('MMM D, YYYY')}</div> : null}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          {model.repairOrder ? <InvoiceViewBodyRO model={model}/> : <InvoiceViewBody model={model}/>}
                          {formVisible ? <InvoiceViewForm model={model}/> : paymentButton}
                          {model.invoice?.status === InvoiceStatus.COMPLETE ? (
                            <div
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginTop: 20,
                                fontSize: 20
                            }}
                            >
                              Payment Received!
                            </div>
                          ) : null}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {/* END MAIN CONTENT AREA */}
                </tbody>
              </table>
              {/* START FOOTER */}
              <div className="footer">
                <table cellPadding={0} cellSpacing={0} style={{ border: 'none', marginBottom: 20 }}>
                  <tbody>
                  <tr>
                    <td className="content-block">
                        <span className="apple-link">
                          <a href={window.location.href}>© {model.account?.name}</a>
                        </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              {/* END FOOTER */}
              {/* END CENTERED WHITE CONTAINER */}
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
});