import React from 'react';
import { observer } from 'mobx-react';
import { Button, Typography, Table, Space, Skeleton, Row, Col, Popconfirm, Select, Tag, Pagination } from 'antd';
import { PlusOutlined, UserDeleteOutlined, SendOutlined, BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { AccountAccessModel } from './AccountAccessModel';
import { AccountInviteModal } from './AccountInviteModal';
import { AccountUser, AccountUserProps, AccountUserRoles, AccountUserNames } from 'models/AccountUser';
import { AuthStore } from 'stores/Auth';
import { GlobalStore } from 'stores/Global';

import './AccountAccessStyle.less'

interface AccountAccessViewProps {
  model: AccountAccessModel;
  inline?: boolean;
}

export const AccountAccessView: React.FC<AccountAccessViewProps> = observer(({ model, inline }) => {
  const currentUserId = AuthStore.currentUser.userId;
  const hasDefaults = model.accountSettings?.repairOrdersEnabled() && GlobalStore.isCurrentAccountOwner();
  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '30px'
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: AccountUser) => {
      if (record.userId === currentUserId) {
        return (
          <Space>
            {name}
            <Tag color="magenta">Me</Tag>
          </Space>
        );
      }

      if (record.pending) {
        return (
          <Space>
            {name}
            <Tag>Pending</Tag>
          </Space>
        );
      }

      return name;
    }
  }, {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email: string) => <a href={`mailto:${email}`}>{email}</a>
  }, {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: '160px',
    render: (role: string, record: AccountUser) => {
      if (record.userId === currentUserId) {
        const roleKey: any = Object
          .keys(AccountUserRoles)
          .find((key) => (AccountUserRoles as IIndexable)[key] === role);

        return (AccountUserNames as IIndexable)[roleKey] || '-';
      }

      return (
        <Select
          style={{ width: '100%' }}
          defaultValue={record.role}
          onChange={(role: AccountUserRoles) => model.handleRoleChange(record.userId, role)}
        >
          {Object
            .keys(AccountUserRoles)
            .filter((key) => (AccountUserRoles as IIndexable)[key] !== AccountUserRoles.NONE)
            .map((key) => (
              <Select.Option
                key={key}
                value={(AccountUserRoles as IIndexable)[key]}
              >
                {(AccountUserNames as IIndexable)[key]}
              </Select.Option>
            ))}
        </Select>
      );
    }
  }, {
    title: 'Action',
    key: 'action',
    width: hasDefaults ? '240px' : '180px',
    render: (_text: any, record: AccountUser) => {
      return (
        <Space>
          <Popconfirm
            title="Are you sure?"
            disabled={record.userId === currentUserId}
            onConfirm={() => model.handleRevoke(record.userId)}
          >
            <Button
              disabled={record.userId === currentUserId}
              icon={<UserDeleteOutlined />}
              danger
            >
              Delete
            </Button>
          </Popconfirm>
          {record.pending && (
            <Button
              icon={<SendOutlined />}
              onClick={() => model.handleResend(record.userId)}
            >
              Resend
            </Button>
          )}
          {hasDefaults && !record.pending && (
            <Button
              icon={record.isDefault ? <CheckSquareOutlined /> : <BorderOutlined />}
              onClick={() => model.handleDefaultToggle(record.userId, !record.isDefault)}
            >
              Default
            </Button>
          )}
        </Space>
      );
    },
  }];

  if (model.loading) {
    return (
      <Skeleton active />
    );
  }

  const data: any[] = model.users.map((user, i) => ({
    ...user,
    original: user,
    '#': i + 1
  }));
  const pagedData: any[] = data.slice(model.pagintaion.skip, model.pagintaion.skip + model.pagintaion.take);

  return (
    <div className="account-customers tl">
      {!inline && (
        <Typography.Title level={2} className="tc">Manage Access Privileges</Typography.Title>
      )}

      <Table
        columns={columns}
        dataSource={pagedData}
        loading={model.tableLoading}
        rowKey={(record: AccountUserProps) => record.userId}
        pagination={false}
        footer={() => (
          <Row>
            <Col span={6}>
              <Button type="primary" onClick={model.handleInvite}><PlusOutlined /> Add User</Button>
            </Col>
            <Col className="tr" span={18}>
              <Pagination
                className="account-customers__pagination"
                showSizeChanger
                onShowSizeChange={model.takeChange}
                onChange={model.paginationChange}
                defaultCurrent={1}
                current={(model.pagintaion.skip / model.pagintaion.take) + 1}
                total={data.length}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Col>
          </Row>
        )}
      />

      <AccountInviteModal model={model} />
    </div>
  );
})