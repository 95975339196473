import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Space } from 'antd';
import { DeleteOutlined, CaretUpOutlined, CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

import './EditableTable.less';

const EditableContext = React.createContext<FormInstance | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [wrapHeight, setWrapHeight] = useState(32);
  const inputRef = useRef<any>(null);
  const wrapRef = useRef<HTMLDivElement>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    if (!editing) {
      setWrapHeight(wrapRef.current!.clientHeight);
    }

    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (e) {
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input.TextArea ref={inputRef} onPressEnter={save} onBlur={save} style={{ height: wrapHeight }} />
      </Form.Item>
    ) : (
        <div
          ref={wrapRef}
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

interface EditableTableSchema {
  [key: string]: 'number' | 'string';
}

type EditableTableProps = Parameters<typeof Table>[0] & {
  value?: IIndexable[],
  schema: EditableTableSchema,
  onChange?: (value: IIndexable[]) => any
};

interface EditableTableState {
  dataSource: IIndexable[];
  count: number;
  value: IIndexable[] | undefined;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export class EditableTable extends React.Component<EditableTableProps, EditableTableState> {
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];

  constructor(props: EditableTableProps) {
    super(props);

    const schemaKeys: string[] = Object.keys(props.schema);
    const schemaColumns: IIndexable[] = schemaKeys.map((key: string) => {
      return {
        title: key[0].toUpperCase() + key.substring(1, key.length),
        dataIndex: key,
        width: `${90 / schemaKeys.length}%`,
        editable: true,
      };
    });
    this.columns = [...schemaColumns, {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record: { key: React.Key }) =>
        this.state.dataSource.length >= 1 ? (
          <>
            <Space size="small">
              <Popconfirm title="Are you sure?" onConfirm={() => this.handleDelete(record.key)}>
                <Button icon={<DeleteOutlined />} size="small" danger />
              </Popconfirm>
              <Input.Group compact>
                <Button
                  icon={<CaretUpOutlined />}
                  onClick={() => this.handleUp(record.key)}
                  size="small"
                />
                <Button
                  icon={<CaretDownOutlined />}
                  onClick={() => this.handleDown(record.key)}
                  size="small"
                />
              </Input.Group>
            </Space>
          </>
        ) : null,
    }] as any;

    this.state = {
      dataSource: EditableTable.mapDS(props.value || []),
      count: (props.value || []).length,
      value: props.value
    };
  }

  static mapDS = (dataSource: IIndexable[]) => {
    return dataSource.map((record: IIndexable, i) => ({ ...record, key: i }));
  };

  static getDS = (dataSource: IIndexable[]) => {
    return dataSource.map((record: IIndexable) => {
      const { key, ...rest } = record;

      return { ...rest };
    });
  };

  static getDerivedStateFromProps(props: EditableTableProps, state: EditableTableState) {
    if (props.value !== state.value) {
      return {
        dataSource: EditableTable.mapDS(props.value || []),
        count: (props.value || []).length,
        value: props.value
      };
    }

    return {
      dataSource: state.dataSource,
      count: state.count,
      value: props.value
    };
  }

  handleDelete = (key: React.Key) => {
    const dataSource = [...this.state.dataSource];

    this.setState({ dataSource: dataSource.filter((item) => item.key !== key) }, this.triggerChange);
  };

  handleUp = (key: React.Key) => {
    const dataSource = [...this.state.dataSource];
    const index = dataSource.findIndex((item) => item.key === key);

    if (index > 0) {
      const replaced = dataSource[index - 1];

      dataSource[index - 1] = dataSource[index];
      dataSource[index] = replaced;

      this.setState({ dataSource }, this.triggerChange);
    }
  };

  handleDown = (key: React.Key) => {
    const dataSource = [...this.state.dataSource];
    const index = dataSource.findIndex((item) => item.key === key);

    if (index < dataSource.length - 1) {
      const replaced = dataSource[index + 1];

      dataSource[index + 1] = dataSource[index];
      dataSource[index] = replaced;

      this.setState({ dataSource }, this.triggerChange);
    }
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const { schema } = this.props;
    const newData: IIndexable = {
      ...Object.keys(schema).reduce((acc: object, key: string) => {
        const type = schema[key];
        const name = key[0].toUpperCase() + key.substring(1, key.length);

        return {
          ...acc,
          [key]: type === 'number' ? '0' : `${name} ${count + 1}`
        };
      }, {}),
      key: count,
    };

    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    }, this.triggerChange);
  };

  handleSave = (row: IIndexable) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData }, this.triggerChange);
  };

  triggerChange = () => {
    this.props.onChange?.(EditableTable.getDS(this.state.dataSource));
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: IIndexable) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <>
        <Table
          size="small"
          className="xsmall"
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          footer={() => (
            <Button onClick={this.handleAdd} size="small" type="primary">
              <PlusOutlined />Add a Row
            </Button>
          )}
          pagination={false}
        />
      </>
    );
  }
}