import React from 'react';
import qs from 'query-string';
import { Result, Button } from 'antd'
import { Redirect, useLocation } from 'react-router-dom';
import { GlobalStore } from 'stores/Global';
import { AccountsStore } from 'stores/Accounts';

export const OnboardingRoute: React.FC = () => {
  const { search } = useLocation();
  const { code, state }: any = qs.parse(search);
  const account = AccountsStore.getAccount(state);

  if (account && code && state) {
    return (
      <Redirect to={`/account/${account.slug || account.accountId}?code=${code}&state=${state}`} />
    );
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, we cannot find this page for you."
      extra={
        <Button type="primary" onClick={() => GlobalStore.navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};