import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Col, Pagination, Row, Skeleton, Table, Tag, Tooltip, Typography } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { AdminAccountsModel } from './AdminAccountsModel';
import { AccountProps } from 'models/Account';
import { GlobalStore } from 'stores/Global';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';

interface AdminAccountsViewProps {
  model: AdminAccountsModel;
}

export const AdminAccountsView: React.FC<AdminAccountsViewProps> = observer(({ model }) => {
  const { accounts, loading, loaded } = model;

  if (loading && !loaded) {
    return (
      <Skeleton active />
    );
  }

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '40px'
  }, {
    title: 'Logo',
    dataIndex: 'logo',
    key: 'logo',
    width: '80px',
    render: (url: string) => <img src={url} alt="Logo" width="50" height="50" />
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '180px',
    sorter: true,
    filteredValue: model.filters.name || null,
    sortOrder: model.sorter.columnKey === 'name' && model.sorter.order,
    ...getColumnSearchProps('by name')
  }, {
    title: 'Account Id',
    dataIndex: 'accountId',
    key: 'accountId'
  }, {
    title: 'Stripe Account Id',
    dataIndex: 'stripeAccountId',
    key: 'stripeAccountId',
    render: (stripeAccountId: string) => <span>{stripeAccountId || '—'}</span>,
  }, {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '80px',
    render: (currency: string) => <Tag color="green">{currency}</Tag>
  }, {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: '120px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'created' && model.sorter.order,
    render: (created: any) => {
      return (
        <Tooltip title={created ? moment(created).format('LLL') : ''}>
          <span>
            {created ? moment(created).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    }
  }, {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
    width: '120px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'updated' && model.sorter.order,
    render: (updated: any) => {
      return (
        <Tooltip title={updated ? moment(updated).format('LLL') : ''}>
          <span>
            {updated ? moment(updated).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    }
  }, {
    title: 'Action',
    key: 'action',
    width: '105px',
    render: (_text: any, record: AccountProps) => (
      <Button
        size="small"
        icon={<FileSearchOutlined />}
        onClick={() => GlobalStore.navigate(`/admin/accounts/${record.accountId}`)}
      >
        View
      </Button>
    )
  }];

  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: any[] = accounts.map((account, i) => ({
    ...account,
    original: account,
    '#': currentSkip + i + 1
  }));

  return (
    <div className="admin-accounts">
      <Typography.Title level={4}>All Accounts</Typography.Title>
      <Table
        columns={columns as any}
        size="small"
        className="xsmall"
        dataSource={data}
        pagination={false}
        loading={model.loading}
        rowKey={(record: AccountProps) => record.accountId}
        onChange={model.tableFiltersChange}
        footer={() => (
          <Row>
            <Col className="tr" span={24}>
              <Pagination
                size="small"
                className="account-view__pagination"
                showSizeChanger
                onShowSizeChange={model.takeChange}
                onChange={model.paginationChange}
                defaultPageSize={model.pagintaion.take}
                defaultCurrent={1}
                current={currentPage}
                total={currentTotal}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Col>
          </Row>
        )}
      />
    </div>
  );
});