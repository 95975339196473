import React, { useEffect, useState } from 'react';
import { Form, Input, AutoComplete } from 'antd';
import { LoadingOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { getCurrencies } from 'services/Accounts';
import { GlobalStore } from 'stores/Global';

export const CurrencySuggest: React.FC<{ value: string, anyCurrency?: boolean, onChange: (value: string) => any }> = (props) => {
  const [currencySuggestLoading, setCurrencySuggestLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const searchCurrenciesSuggest = async () => {
    setCurrencySuggestLoading(true);

    try {
      const response = await getCurrencies(GlobalStore.getCurrentAccountId());
      const defaultCurrencies = (props.anyCurrency ? ['Any'] : []) as any;
      const currencies = response.data.data;

      setCurrencies(defaultCurrencies.concat(currencies).map((currency: string) => ({
        value: currency,
        label: currency,
        key: currency
      })));

      if (currencies.length === 1) {
        props.onChange(currencies[0]);
      }
    } catch (e) {
    } finally {
      setCurrencySuggestLoading(false);
    }
  };

  useEffect(() => {
    searchCurrenciesSuggest().catch(() => '');
  }, []);

  return (
    <Form.Item
      label="Currency"
      name="currency"
      rules={[
        () => ({
          validator(_rule: any, value: any) {
            if (!value) {
              return Promise.reject('Please select currency');
            }

            if (!currencies.find((c: any) => value === c.value)) {
              return Promise.reject('Please select valid currency');
            }

            return Promise.resolve();
          },
        }),
      ]}
      required
    >
      <AutoComplete
        options={currencies}
        onChange={props.onChange}
        disabled={currencies.length <= 1}
      >
        <Input
          placeholder="Currency"
          value={props.value}
          prefix={<DollarCircleOutlined/>}
          suffix={currencySuggestLoading ? <LoadingOutlined/> : null}
        />
      </AutoComplete>
    </Form.Item>
  )
};