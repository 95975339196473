import { memoize } from 'lodash';

export const isAutoload = memoize((location: any) => {
  const { pathname } = location;
  const publicMatches = [
    /^\/secure\/.+$/ig
  ];

  return !publicMatches.some((re) => pathname.match(re));
});

export const getSignInRetpathUrl = () => {
  return `/sign-in?retpath=${window.location.pathname}`;
};

export const getInvoiceUrl = (invoiceId: string) => {
  return `${process.env.REACT_APP_ORIGIN_URL}/secure/${invoiceId}`;
};

export const getInvoiceCheckUrl = (invoiceId: string) => {
  return `${process.env.REACT_APP_ORIGIN_URL}/invoice/${invoiceId}`;
};

export const getAccountRoute = (accountId: string, slug: string | null, route: string = '') => {
  const appendix = route ? `/${route}` : '';

  if (slug) {
    return `/account/${slug}${appendix}`;
  }

  return `/account/${accountId}${appendix}`;
};