import { observable } from 'mobx';
import { RepairOrderProps } from './RepairOrder';

export interface CustomerProps {
  accountId: string,
  customerId: string,
  created: string,
  updated: string,
  name: string,
  email: string | null,
  phoneNumber: string | null,
  company: string | null,
  address: string | null,
  extraEmails: string | null,
  currency: string | null,
  notes: string,
  stripeCustomerId: string | null
}

export class Customer {
  @observable name: string = '';
  @observable email: string | null = '';
  @observable phoneNumber: string | null = '';
  @observable company: string | null = '';
  @observable address: string | null = '';
  @observable extraEmails: string | null = '';
  @observable currency: string = '';
  @observable notes: string = '';

  accountId: string = '';
  customerId: string = '';
  created: string = '';
  updated: string = '';
  stripeCustomerId: string | null = null;

  constructor(data: CustomerProps) {
    Object.assign(this, data);
  }

  static fromRepairOrder(repairOrder: RepairOrderProps) {
    return new Customer({
      accountId: 'ro-account-' + repairOrder.roNumber,
      customerId: 'ro-customer-' + repairOrder.roNumber,
      created: repairOrder.opened,
      updated: repairOrder.opened,
      name: repairOrder.customerName,
      email: repairOrder.customerEmail || '',
      phoneNumber: repairOrder.customerPhone || '',
      company: '',
      address: '',
      extraEmails: '',
      notes: '',
      currency: '',
      stripeCustomerId: null
    });
  }
}