import bind from 'bind-decorator';
import moment from 'moment';
import { observable } from 'mobx';
import { Paginable, Pagination } from 'models/Paginable';
import { FI, FIProps } from 'models/FI';
import { addAccountFI, getAccountFIs, removeAccountFI } from 'services/Admin';
import { rawPhoneNumber } from 'utils/Phone';
import { AuthStore } from 'stores/Auth';

export class AdminAccountFIModel extends Paginable {
  @observable modalLoading: boolean = false;
  @observable modalVisible: boolean = false;
  @observable fis: FI[] = [];
  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 20,
    total: 0
  };

  accountId: string = '';

  constructor(accountId: string) {
    super();

    this.accountId = accountId;
  }

  @bind
  async processResponse(response: any) {
    this.fis = response.items.map((item: FIProps) => {
      return new FI(item);
    });
  }

  @bind
  async fetchFromApi(pagination?: Pagination, filters?: IIndexable) {
    return await getAccountFIs(this.accountId, pagination, filters);
  }

  @bind
  mapFilters(filters: IIndexable) {
    const resultFilters: IIndexable = {};

    if (filters.customerName) {
      resultFilters.customerName = filters.customerName[0];
    }

    if (filters.email) {
      resultFilters.email = filters.email[0];
    }

    return resultFilters;
  }

  @bind
  async handleRemoveFI(fiId: string) {
    await removeAccountFI(this.accountId, fiId);

    this.refresh();
  }

  @bind
  handleAdd() {
    this.modalVisible = true;
  }

  @bind
  handleCancel() {
    this.modalVisible = false;
  }

  @bind
  async handleOk(values: any) {
    this.modalLoading = true;

    let response = null;

    try {
      if (values.phone) {
        values.phone = rawPhoneNumber(values.phone);
      }

      response = await addAccountFI(this.accountId, {
        id: values.id,
        customerName: values.customerName,
        email: values.email,
        phone: values.phone,
        salesDate: values.salesDate ? values.salesDate.toISOString() : null,
        make: values.make,
        model: values.model,
        year: values.year,
        mileage: values.mileage,
        vin: values.vin,
      });

      await this.refresh();

      this.modalVisible = false;
    } catch (e) {
    }

    this.modalLoading = false;

    return response;
  }

  @bind
  prePopulateFI() {
    const now = moment();
    const currentUser = AuthStore.currentUser;

    return {
      id: now.format('YYYYMMDD'),
      customerName: currentUser.name,
      email: currentUser.email,
      phone: '',
      salesDate: now,
      make: 'Toyota',
      model: 'Corolla',
      year: '2018',
      mileage: '42000',
      vin: '4Y1SL65848Z411439',
    };
  }
}