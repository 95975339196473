import query from 'query-string';

import { Api } from './Api';

export const stripeExpress = (clientId: string, accountId: string, user: { email: string, name: string }) => {
  const [firstName, lastName] = user.name.split(' ');

  return query.stringifyUrl({
    url: 'https://connect.stripe.com/express/oauth/authorize',
    query: {
      'client_id': clientId,
      'stripe_user[business_type]': 'company',
      'stripe_user[email]': user.email,
      'stripe_user[first_name]': firstName,
      'stripe_user[last_name]': lastName,
      'suggested_capabilities[]': 'card_payments',
      'state': accountId
    }
  });
};

export const goToConnectPage = (clientId: string, accountId: string, user: { email: string, name: string }) => {
  window.location.href = stripeExpress(clientId, accountId, user);
};

export const goToDashboardPage = async (accountId: string) => {
  try {
    const response = await Api.get(`/accounts/${accountId}/express/dashboard`);

    if (response.data) {
      window.location.href = response.data.data;
    }
  } catch (e) {
  }
};