import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Modal, Input, InputNumber, DatePicker } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, NumberOutlined, CarOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { AdminAccountROModel } from './AdminAccountROModel';
import { EditableTable } from 'components/Table/EditableTable';

interface AdminAccountROModalProps {
  model: AdminAccountROModel;
}

export const AdminAccountROModal: React.FC<AdminAccountROModalProps> = observer(({ model }) => {
  const { modalVisible } = model;
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue(model.prePopulateRO());
    }
  }, [model, form, modalVisible]);

  return (
    <Modal
      title={model.activeRepairOrder ? 'Edit Repair Order' : 'Add New Repair Order'}
      visible={modalVisible}
      onOk={() => form.submit()}
      okText={model.activeRepairOrder ? 'Update' : 'Add'}
      onCancel={model.handleCancel}
      confirmLoading={model.modalLoading}
      width={1000}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={model.handleOk}
      >
        <Form.Item
          label="RO Number"
          name="roNumber"
          rules={[
            { required: true, message: 'Please input RO number' }
          ]}
        >
          <Input
            placeholder="RO Number"
            prefix={<NumberOutlined />}
            disabled={Boolean(model.activeRepairOrder)}
            autoFocus
          />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input name' }
          ]}
        >
          <Input placeholder="Name" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
          required
        >
          <Input
            placeholder="Email"
            prefix={<MailOutlined />}
            disabled={Boolean(model.activeRepairOrder)}
          />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
        >
          <Input type="tel" placeholder="Phone Number" prefix={<PhoneOutlined />} />
        </Form.Item>

        <Form.Item
          label="Address"
          name="address"
        >
          <Input placeholder="Address" prefix={<HomeOutlined />} />
        </Form.Item>

        <Form.Item
          label="Make"
          name="make"
        >
          <Input placeholder="Make" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Model"
          name="model"
        >
          <Input placeholder="Model" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Year"
          name="year"
        >
          <Input placeholder="Year" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Mileage"
          name="mileage"
        >
          <Input placeholder="Mileage" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="VIN"
          name="vin"
        >
          <Input placeholder="VIN #" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Opened"
          name="opened"
          rules={[
            { required: true, message: 'Please select the date' }
          ]}
          required
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Closed"
          name="closed"
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Labor Items"
          name="laborItems"
          style={{ flexFlow: 'column' }}
        >
          <EditableTable schema={{
            description: 'string',
            quantity: 'number',
            total: 'number'
          }} />
        </Form.Item>

        <Form.Item
          label="Part Items"
          name="partItems"
          style={{ flexFlow: 'column' }}
        >
          <EditableTable schema={{
            description: 'string',
            quantity: 'number',
            total: 'number'
          }} />
        </Form.Item>

        <Form.Item
          label="Subtotals"
          name="subtotals"
          style={{ flexFlow: 'column' }}
        >
          <EditableTable schema={{
            name: 'string',
            amount: 'number',
            warranty: 'number',
            internal: 'number'
          }} />
        </Form.Item>

        <Form.Item
          label="Internal Status"
          name="internalStatus"
        >
          <Input placeholder="Internal Status" prefix={<SettingOutlined />} />
        </Form.Item>

        <Form.Item
          label="Balance Due"
          name="balanceDue"
          required
        >
          <InputNumber
            style={{ minWidth: '30%' }}
            placeholder="Balance Due"
            min={0}
          />
        </Form.Item>

        <Form.Item
          label="Rewards Discount"
          name="rewardsDiscount"
        >
          <InputNumber
            style={{ minWidth: '30%' }}
            placeholder="Rewards Discount"
            min={0}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
});