import { Api } from './Api';

export interface NotificationOptions {
  text?: boolean;
  email?: boolean;
  push?: boolean;
}

export const sendNotification = (accountId: string, invoiceId: string, options?: NotificationOptions) => {
  return Api.post(`/accounts/${accountId}/invoices/${invoiceId}/notification`, {}, options ? {
    params: {
      text: options.text || false,
      email: options.email || false,
      push: options.push || false
    }
  } : {});
};
