import React from 'react';
import { Account } from 'models/Account';

import { AccountReportsView } from 'components/Account/Reports/AccountReportsView';
import { AccountReportsModel } from 'components/Account/Reports/AccountReportsModel';

export const AccountReportsRoute: React.FC<{ account: Account }> = ({ account }) => {
  const model = new AccountReportsModel(account.accountId);

  return (
    <AccountReportsView model={model} />
  );
};