import { AuthStore } from './Auth';

const adminEmails = [
  'tony@mobile-dealer.com',
  'andrei@mobile-dealer.com',
  'andrei.mikhalevich@gmail.com',
  'v.lyahovic@gmail.com',
  'nastya.v.mikhalevich@gmail.com'
];

export class AccessStore {
  static get createInvoice(): boolean {
    return adminEmails.includes(AuthStore.currentUser.email);
  }
}