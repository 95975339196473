import React from 'react';

import { AccountCreateView } from 'components/Account/Create/AccountCreateView';
import { AccountCreateModel } from 'components/Account/Create/AccountCreateModel';

export const AccountCreateRoute: React.FC = () => {
  const model = new AccountCreateModel();

  return (
    <AccountCreateView model={model} />
  );
};