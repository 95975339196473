import React from 'react';
import { Spin } from 'antd';

export const PageLoading: React.FC<{ tip?: string }> = ({ tip }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'canter',
    flexDirection: 'column',
    height: '100%'
  }}>
    <Spin size="large" tip={tip} />
  </div>
);