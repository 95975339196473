import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Modal, Input, DatePicker } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, NumberOutlined, CarOutlined } from '@ant-design/icons';
import { AdminAccountFIModel } from './AdminAccountFIModel';

interface AdminAccountFIModalProps {
  model: AdminAccountFIModel;
}

export const AdminAccountFIModal: React.FC<AdminAccountFIModalProps> = observer(({ model }) => {
  const { modalVisible } = model;
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue(model.prePopulateFI());
    }
  }, [model, form, modalVisible]);

  return (
    <Modal
      title={'Add New F&I'}
      visible={modalVisible}
      onOk={() => form.submit()}
      okText={'Add'}
      onCancel={model.handleCancel}
      confirmLoading={model.modalLoading}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={model.handleOk}
      >
        <Form.Item
          label="Id"
          name="id"
          rules={[
            { required: true, message: 'Please input id' }
          ]}
        >
          <Input placeholder="Id" prefix={<NumberOutlined />} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="customerName"
          rules={[
            { required: true, message: 'Please input name' }
          ]}
        >
          <Input placeholder="Name" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
          required
        >
          <Input placeholder="Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
        >
          <Input type="tel" placeholder="Phone Number" prefix={<PhoneOutlined />} />
        </Form.Item>

        <Form.Item
          label="Sales Date"
          name="salesDate"
          rules={[
            { required: true, message: 'Please select the date' }
          ]}
          required
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Make"
          name="make"
        >
          <Input placeholder="Make" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Model"
          name="model"
        >
          <Input placeholder="Model" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Year"
          name="year"
        >
          <Input placeholder="Year" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="Mileage"
          name="mileage"
        >
          <Input placeholder="Mileage" prefix={<CarOutlined />} />
        </Form.Item>

        <Form.Item
          label="VIN"
          name="vin"
        >
          <Input placeholder="VIN #" prefix={<CarOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  )
});