import { Api } from './Api';

export interface GetRepairOrdersFilters {
  from: string;
  to: string;
  roNumber?: string;
  customerEmail?: string;
  customerName?: string;
  orderBy?: string;
  orderDirection?: 0 | 1;
  internalStatus?: string;
  status?: string;
}

export interface GetRepairOrdersPagination {
  skip: number;
  take: number;
}

export const getRepairOrders = (accountId: string, pagination?: GetRepairOrdersPagination, filters?: GetRepairOrdersFilters) => {
  return Api.get(`/integration/accounts/${accountId}/repair-orders`, { ...pagination, ...filters });
};

export const extractRepairOrders = (accountId: string) => {
  return Api.get(`/integration/accounts/${accountId}/repair-orders/extract`);
};