import qs from 'query-string';
import React, { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { InvoiceView } from 'components/Invoice/InvoiceView';
import { InvoiceViewModel } from 'components/Invoice/InvoiceViewModel';

export const InvoiceRoute = () => {
  const { params }: { params: { invoiceId: string } } = useRouteMatch();
  const { search } = useLocation();
  const { retUrl } = qs.parse(search);
  const model = new InvoiceViewModel(params.invoiceId);

  useEffect(() => {
    if (retUrl) {
      const { origin, pathname } = window.location;

      window.history.replaceState(null, document.title, origin + pathname);

      model.setRetUrl(retUrl ? String(retUrl) : '');
    }
  }, [model, retUrl]);

  useEffect(() => { model.fetchInvoice() }, [model]);

  return (
    <InvoiceView model={model} />
  );
};