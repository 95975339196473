import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Typography } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { LoginModel } from './LoginModel';

import './LoginStyle.less'

interface LoginViewProps {
  model: LoginModel;
}

const { Title, Text } = Typography;

export const LoginView: React.FC<LoginViewProps> = observer(({ model }) => {
  return (
    <Row justify="center" align="middle" className="login-page">
      <Form
        className="login-page__form box"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={model.finishSignin}
      >
        <div className="tc mb-5">
          <a href="/" className="brand">md</a>
          <Title level={4} className="mb-0 mt-3">Sign-in</Title>
          <Text type="secondary">get started with our service</Text>
        </div>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
        >
          <Input placeholder="Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password' },
            { min: 6, message: 'Password should be at least 6 characters long' }
          ]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item className="tr">
          <Link to="/forgot">Forgot password</Link>

          <Button
            type="primary"
            htmlType="submit"
            className="mt-3"
            loading={model.loading}
            block
          >
            Sign-in
          </Button>
        </Form.Item>

        <div className="tc">
          <Text type="secondary">
            <span>Don't have an account yet?</span> <Link to="/sign-up">Create one now!</Link>
          </Text>
        </div>
      </Form>
    </Row>
  );
});