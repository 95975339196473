import bind from 'bind-decorator';
import { observable } from 'mobx';
import { Customer, CustomerProps } from 'models/Customer';
import { getCustomers, addCustomer, editCustomer, removeCustomer } from 'services/Customers';
import { notification } from 'antd';
import { rawPhoneNumber } from 'utils/Phone';

export class AccountCustomersModel {
  @observable loading: boolean = true;
  @observable tableLoading: boolean = false;
  @observable modalLoading: boolean = false;
  @observable modalVisible: boolean = false;
  @observable activeCustomer: Customer | null = null;
  @observable customers: Customer[] = [];

  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 500,
    total: 0
  };
  @observable searchTerm: string = '';

  loaded: boolean = false;
  accountId: string = '';

  constructor(accountId: string) {
    this.accountId = accountId;
  }

  @bind
  async fetchCustomers(pagination?: { skip: number, take: number, page: number }) {
    if (this.loaded) {
      this.tableLoading = true;
    } else {
      this.loading = true;
    }

    try {
      const response = await getCustomers(this.accountId, {
        term: this.searchTerm,
        skip: pagination ? pagination.skip : this.pagintaion.skip,
        take: pagination ? pagination.take : this.pagintaion.take
      });
      const responseData = response.data.data;

      this.customers = responseData.items.map((item: CustomerProps) => {
        return new Customer(item);
      });

      this.pagintaion.skip = responseData.skip;
      this.pagintaion.take = responseData.take;
      this.pagintaion.total = responseData.total;

      if (pagination) {
        this.pagintaion.page = pagination.page;
      }
    } catch (e) {
    }

    this.tableLoading = false;
    this.loading = false;
    this.loaded = true;
  }

  @bind
  takeChange(_current: number, pageSize: number) {
    this.fetchCustomers({
      skip: 0,
      take: pageSize,
      page: 1
    });
  }

  @bind
  paginationChange(current: number) {
    this.fetchCustomers({
      skip: (current - 1) * this.pagintaion.take,
      take: this.pagintaion.take,
      page: current
    });
  }

  @bind
  handleAdd() {
    this.activeCustomer = null;
    this.modalVisible = true;
  }

  @bind
  handleEdit(customer: Customer) {
    this.activeCustomer = customer;
    this.modalVisible = true;
  }

  @bind
  async handleRemove(customer: Customer) {
    let response = null;

    try {
      response = await removeCustomer(this.accountId, customer.customerId);

      notification.success({
        message: 'Customer Successfully Removed',
        description: `Customer ${customer.name} was removed`
      });

      await this.fetchCustomers();
    } catch (e) {
    }

    return response;
  }

  @bind
  handleCancel() {
    this.modalVisible = false;
  }

  @bind
  async handleOk(values: any) {
    this.modalLoading = true;

    let response = null;

    try {
      if (this.activeCustomer) {
        response = await editCustomer(this.accountId, this.activeCustomer.customerId, {
          name: values.name,
          email: values.email,
          phoneNumber: rawPhoneNumber(values.phone),
          company: values.company,
          address: values.address,
          extraEmails: values.extraEmails,
          currency: values.currency,
          notes: values.notes
        });

        notification.success({
          message: 'Customer Successfully Edited',
          description: `Customer ${values.name} was saved`
        });
      } else {
        response = await addCustomer(this.accountId, {
          name: values.name,
          email: values.email,
          phoneNumber: rawPhoneNumber(values.phone),
          company: values.company,
          address: values.address,
          extraEmails: values.extraEmails,
          currency: values.currency,
          notes: values.notes
        });

        notification.success({
          message: 'Customer Successfully Created',
          description: `Customer ${values.name} was created and saved`
        });
      }

      await this.fetchCustomers();

      this.modalVisible = false;
      this.activeCustomer = null;
    } catch (e) {
    }

    this.modalLoading = false;

    return response;
  }

  @bind
  handleSearch(term: string) {
    this.searchTerm = term;
    this.pagintaion.skip = 0;
    this.pagintaion.page = 1;

    this.fetchCustomers();
  }

  @bind
  handleSearchReset() {
    this.searchTerm = '';
    this.pagintaion.skip = 0;
    this.pagintaion.page = 1;

    this.fetchCustomers();
  }
}