import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Skeleton, Table, Row, Col, Typography, Pagination, Space, Tooltip, Button } from 'antd';
import { FileAddOutlined, FileSearchOutlined, CheckOutlined, DownloadOutlined } from '@ant-design/icons';
import { RepairOrdersModel } from './RepairOrdersModel';
import { RepairOrdersDetails } from './RepairOrdersDetails';
import { RepairOrderProps } from 'models/RepairOrder';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';
import { getColumnDatesProps } from 'components/Filters/TableDatesFilter';
import { formatPhoneNumber } from 'utils/Phone';
import { openInvoiceCreateModal } from 'components/Account/View/AccountInvoiceModal';
import { getFormattedNumber } from 'utils/Currency';
import { EventListener } from 'components/EventListener';
import { EVENTS } from '~/enums/common';

import './RepairOrdersStyle.less';

interface RepairOrdersViewProps {
  accountId: string;
  filters?: IIndexable;
  defaultTake?: number;
  onInvoiceCreated?: (data: any) => any;
}

export const RepairOrdersView: React.FC<RepairOrdersViewProps> = observer(({ accountId, filters, defaultTake, onInvoiceCreated }) => {
  const model = useMemo(() => {
    const roModel = new RepairOrdersModel(accountId, { defaultTake });

    if (!filters || !Object.keys(filters).length) {
      roModel.fetchForAccount();
    }

    return roModel;
    // eslint-disable-next-line
  }, [accountId]);

  useEffect(() => {
    filters && model.outsideFiltersChange(filters);
  }, [model, filters]);

  if (model.loading) {
    return (
      <Skeleton active />
    );
  }

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '60px',
    fixed: 'left'
  }, {
    title: <span>RO&nbsp;Number</span>,
    dataIndex: 'roNumber',
    key: 'roNumber',
    width: '140px',
    sorter: true,
    fixed: 'left',
    filteredValue: model.filters.roNumber || null,
    sortOrder: model.sorter.columnKey === 'roNumber' && model.sorter.order,
    render: (roNumber: string, record: RepairOrderProps) => {
      return (
        <RepairOrdersDetails accountId={model.accountId} repairOrder={record} onInvoiceCreated={onInvoiceCreated}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                {roNumber}
              </span>
            </Tooltip>
          )}
        </RepairOrdersDetails>
      );
    },
    ...getColumnSearchProps('by ro number')
  }, {
    title: 'Customer',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '200px',
    sorter: true,
    fixed: 'left',
    filteredValue: model.filters.customerName || null,
    sortOrder: model.sorter.columnKey === 'customerName' && model.sorter.order,
    ...getColumnSearchProps('by customer name')
  }, {
    title: 'Email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
    width: '200px',
    sorter: true,
    filteredValue: model.filters.customerEmail || null,
    sortOrder: model.sorter.columnKey === 'customerEmail' && model.sorter.order,
    render: (customerEmail: string | null) => customerEmail || '—',
    ...getColumnSearchProps('by email address')
  }, {
    title: 'Phone',
    dataIndex: 'customerPhone',
    key: 'customerPhone',
    width: '160px',
    sorter: true,
    filteredValue: model.filters.customerPhone || null,
    sortOrder: model.sorter.columnKey === 'customerPhone' && model.sorter.order,
    render: (customerPhone: string | null) => formatPhoneNumber(customerPhone) || '—',
  }, {
    title: 'Opened',
    dataIndex: 'opened',
    key: 'opened',
    width: '120px',
    sorter: true,
    filteredValue: model.filters.opened || model.outsideFilters.opened || null,
    sortOrder: model.sorter.columnKey === 'opened' && model.sorter.order,
    render: (created: any) => {
      return (
        <Tooltip title={created ? moment(created).format('LLL') : ''}>
          <span>
            {created ? moment(created).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
    ...getColumnDatesProps()
  }, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: '100px',
    render: (total: number) => getFormattedNumber(total)
  }, {
    title: 'Status',
    dataIndex: 'internalStatus',
    key: 'internalStatus',
    width: '160px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'internalStatus' && model.sorter.order,
    render: (status: string) => status || '—'
  }, {
    title: <span>Create&nbsp;Invoice</span>,
    key: 'action',
    width: '100px',
    className: 'account-view__actions',
    fixed: 'right',
    render: (_text: any, record: RepairOrderProps) => (
      <Space size="small">
        {record.invoiceStatus ? (
          <Tooltip title="Invoiced" mouseEnterDelay={0.5}>
            <Button
              size="small"
              icon={<CheckOutlined />}
              onClick={() => ''}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Create invoice" mouseEnterDelay={0.5}>
            <Button
              size="small"
              icon={<FileAddOutlined />}
              onClick={() => openInvoiceCreateModal(model.accountId, record, onInvoiceCreated || ((data) => data))}
              disabled={record.total === 0}
            />
          </Tooltip>
        )}
        <RepairOrdersDetails accountId={model.accountId} repairOrder={record} onInvoiceCreated={onInvoiceCreated}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <Button size="small" icon={<FileSearchOutlined />} onClick={openDrawer} />
            </Tooltip>
          )}
        </RepairOrdersDetails>
      </Space>
    ),
  }];
  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: RepairOrderProps[] = model.repairOrders.map((repairOrder, i) => ({
    ...repairOrder,
    original: repairOrder,
    '#': currentSkip + i + 1
  }));

  return (
    <EventListener events={[EVENTS.INVOICE_CREATED, EVENTS.INVOICE_UPDATED, EVENTS.INVOICE_REMOVED]} callback={model.refreshTable}>
      <Typography.Title level={4}>
        <Row>
          <Col flex="auto">
            Repair Orders Ready to Invoice
          </Col>
          <Col flex="none">
            <Button
              size="small"
              type="primary"
              icon={<DownloadOutlined />}
              loading={model.pullLoading}
              onClick={model.pullOrders}
            >
              Pull Repair Orders
            </Button>
          </Col>
        </Row>
      </Typography.Title>
      <div className="repair-orders-view">
        <Table
          columns={columns as any}
          scroll={{ x: 800 }}
          size="small"
          className="xsmall"
          dataSource={data}
          pagination={false}
          loading={model.tableLoading}
          rowKey={(record: RepairOrderProps) => record.roNumber}
          onChange={model.tableFiltersChange}
          footer={() => (
            <Row>
              <Col className="tr" span={24}>
                <Pagination
                  size="small"
                  className="account-view__pagination"
                  showSizeChanger
                  pageSizeOptions={['3', '5', '10', '20', '50', '100']}
                  defaultPageSize={model.pagintaion.take}
                  onShowSizeChange={model.takeChange}
                  onChange={model.paginationChange}
                  defaultCurrent={1}
                  current={currentPage}
                  total={currentTotal}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
              </Col>
            </Row>
          )}
        />
      </div>
    </EventListener>
  );
});