import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';
import { ToolOutlined, SafetyOutlined } from '@ant-design/icons';
import { AdminAccountModel } from './AdminAccountModel';
import { AdminAccountROView } from './RO/AdminAccountROView';
import { AdminAccountROModel } from './RO/AdminAccountROModel';
import { AdminAccountFIView } from './FI/AdminAccountFIView';
import { AdminAccountFIModel } from './FI/AdminAccountFIModel';

interface AdminAccountViewProps {
  model: AdminAccountModel;
}

const { TabPane } = Tabs;

export const AdminAccountView: React.FC<AdminAccountViewProps> = observer(({ model }) => {
  const roModel = new AdminAccountROModel(model.accountId);
  const fiModel = new AdminAccountFIModel(model.accountId);

  useEffect(() => {
    roModel.refresh();
    fiModel.refresh();
  }, [roModel, fiModel]);

  return (
    <div className="admin-accounts">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={<><ToolOutlined />Repair Orders</>}
          key="1"
        >
          <AdminAccountROView model={roModel} />
        </TabPane>
        <TabPane
          tab={<><SafetyOutlined />Finance and Insurance</>}
          key="2"
        >
          <AdminAccountFIView model={fiModel} />
        </TabPane>
      </Tabs>
    </div>
  );
});