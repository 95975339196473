import bind from 'bind-decorator';
import { observable } from 'mobx';
import { createAccount, changeAccount } from 'services/Accounts';
import { getClientId, express } from 'services/Onboarding';
import { AccountsStore } from 'stores/Accounts';
import { goToConnectPage } from 'services/Stripe';
import { Account } from 'models/Account';
import { AuthStore } from 'stores/Auth';
import { GlobalStore } from 'stores/Global';
import { AccountUserRoles } from 'models/AccountUser';

export class AccountCreateModel {
  @observable step: number = 0;
  @observable loading: boolean = false;
  @observable connectLoading: boolean = false;
  @observable connectSuccess: boolean = false;
  @observable connectError: string = '';
  @observable bankConnected: boolean = false;

  name: string = '';
  slug: string = '';
  logo: string = '';
  address: string = '';
  accentColor: string = '';

  accountId: string = '';
  continuation: boolean = false;
  finance: boolean = false;

  formControl: any = null;

  constructor(options?: { step: number, account: Account, code?: string, state?: string }) {
    if (options) {
      this.name = options.account.name;
      this.slug = options.account.slug || '';
      this.logo = options.account.logo;
      this.address = options.account.address;
      this.accentColor = options.account.accentColor;
      this.accountId = options.account.accountId;
      this.finance = options.account.role === AccountUserRoles.FINANCE;

      this.step = options.step;
      this.continuation = true;

      if (options.code && options.state === options.account.accountId) {
        this.connectStripeByCode(options.code);
      }
    }
  }

  async connectStripeByCode(stripeAuthCode: string) {
    this.connectLoading = true;

    try {
      await express({
        accountId: this.accountId,
        stripeAuthCode
      });
      await AccountsStore.fetchAccounts();

      this.bankConnected = true;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error.message) {
        this.connectError = e.response.data.error.message;
      } else {
        this.connectError = 'Unknown error, please try again later.'
      }
    }

    this.connectLoading = false;
  }

  @bind
  next() {
    if (this.step === 0 && this.formControl) {
      return this.formControl.submit();
    }

    this.step = this.step + 1;
  }

  @bind
  prev() {
    this.step = this.step - 1;
  }

  @bind
  async handleConnect() {
    this.connectLoading = true;

    try {
      const response = await getClientId();
      const clientId = response.data.data;
      const currentUser = AuthStore.currentUser;

      goToConnectPage(clientId, this.accountId, {
        email: currentUser.email,
        name: currentUser.name
      });
    } catch (e) {
    }

    this.connectLoading = false;
  }

  @bind
  async finishCreate(values: any) {
    this.loading = true;

    this.name = values.name;
    this.slug = values.slug;
    this.logo = values.logo;
    this.address = values.address;
    this.accentColor = values.accentColor;

    let error = null;

    try {
      if (this.accountId) {
        const response = await changeAccount(this.accountId, {
          name: this.name,
          logo: this.logo,
          slug: this.slug,
          address: this.address,
          accentColor: this.accentColor
        });
        const account = AccountsStore.getAccount(this.accountId);

        if (account) {
          account.name = response.data.data.name;
          account.slug = response.data.data.slug;
          account.logo = response.data.data.logo;
          account.address = response.data.data.address;
          account.accentColor = response.data.data.accentColor;
        }
      } else {
        const response: any = await createAccount({
          name: this.name,
          logo: this.logo,
          slug: this.slug,
          address: this.address,
          accentColor: this.accentColor
        });

        this.accountId = response.data.data.accountId;

        AccountsStore.accounts.push(new Account({ ...response.data.data, role: AccountUserRoles.OWNER }));

        GlobalStore.setCurrentAccountId(this.accountId);
      }
    } catch (e) {
      error = e;
    }

    this.loading = false;

    if (!error) {
      this.step = this.step + 1;
    }
  }
}