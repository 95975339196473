import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Skeleton, Table, Checkbox } from 'antd';
import { getEmailPreferences, setEmailPreferences } from 'services/User';
import { AuthStore } from 'stores/Auth';
import { getSignInRetpathUrl } from 'utils/Routes';

interface EmailPreferenceFlags {
  paymentReceived: boolean
}

interface EmailPreference extends EmailPreferenceFlags {
  accountId: string,
  accountName: string,
  userId: string
}

export const ProfileEmailPreferences: React.FC<{ token?: string }> = ({ token }) => {
  const [preferences, setPreferences] = useState<EmailPreference[]>([]);
  const [loading, setLoading] = useState(true);
  const updatePreference = async (preference: EmailPreference, flags: Partial<EmailPreferenceFlags>) => {
    setPreferences(preferences.map((item) => {
      if (item.accountId === preference.accountId) {
        return {
          ...item,
          ...flags
        };
      }

      return item;
    }));

    await setEmailPreferences({
      accountId: preference.accountId,
      ...flags
    }, token);
  };

  useEffect(() => {
    if (!AuthStore.authenticated && !token) {
      setLoading(false);

      return;
    }

    const loadPreferences = async () => {
      try {
        const response = await getEmailPreferences(token);

        setPreferences(response.data.data);
      } catch (e) {
      }

      setLoading(false);
    };

    loadPreferences();
  }, [token]);

  if (loading) {
    return <Skeleton active />;
  }

  if (!preferences || !preferences.length) {
    if (!AuthStore.authenticated && !token) {
      return <Redirect to={getSignInRetpathUrl()} />;
    }

    return <div>Sorry, no data available at this time.</div>;
  }

  const columns = [{
    title: 'Name',
    dataIndex: 'accountName',
    key: 'accountName'
  }, {
    title: 'Payment Received',
    dataIndex: 'paymentReceived',
    key: 'paymentReceived',
    align: 'center',
    render: (paymentReceived: boolean, record: EmailPreference) => {
      return (
        <Checkbox
          checked={paymentReceived}
          onChange={() => updatePreference(record, { paymentReceived: !paymentReceived })}
        />
      );
    }
  }];

  return (
    <Table
      columns={columns as any}
      dataSource={preferences}
      rowKey={(record: EmailPreference) => record.accountId}
      pagination={false}
    />
  );
};