import { observable } from 'mobx';

export interface CurrentUserFields {
  email?: string;
  emailVerified?: boolean;
  name?: string;
  userId?: string;
  picture?: string;
}

export class CurrentUser {
  @observable email: string = '';
  @observable emailVerified: boolean = false;
  @observable name: string = '';
  @observable picture: string = '';
  @observable userId: string = '';

  @observable authorized: boolean = false;

  assignCredentials(data: CurrentUserFields) {
    if (data.email) {
      this.email = data.email;
    }

    if (data.emailVerified) {
      this.emailVerified = data.emailVerified;
    }

    if (data.name) {
      this.name = data.name;
    }

    if (data.userId) {
      this.userId = data.userId;
    }

    if (data.picture) {
      this.picture = data.picture;
    }
  }

  clearCredentials() {
    this.email = '';
    this.emailVerified = false;
    this.name = '';
    this.userId = '';
  }
}