import { InvoiceProps } from 'models/Invoice';
import { sendNotification, NotificationOptions } from 'services/Notification';
import { notification } from 'antd';

export const notifyInvoice = async (invoice: InvoiceProps, options: NotificationOptions) => {
  const to = invoice.customer.name || 'Customer';

  try {
    await sendNotification(invoice.accountId, invoice.invoiceId, options);

    notification.success({
      message: `Notification to ${to} was sent`
    });
  } catch (e) {
  }
};