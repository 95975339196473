import { InvoiceProps } from 'models/Invoice';
import { sendNotification } from 'services/Notification';
import { notification } from 'antd';
import { pushEnsure } from './PushConfirm';

export const pushInvoice = async (invoice: InvoiceProps, confirm?: boolean) => {
  const to = invoice.customer.name || 'Customer';

  if (confirm) {
    return pushEnsure(invoice);
  }

  try {
    await sendNotification(invoice.accountId, invoice.invoiceId, { push: true });

    notification.success({
      message: `Push notification to ${to} was sent`
    });
  } catch (e) {
  }
};