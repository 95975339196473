import React from 'react';
import { Typography } from 'antd';
import { ProfileEmailPreferences } from 'components/Profile/ProfileEmailPreferences';
import { useRouteMatch } from 'react-router-dom';

export const EmailPreferencesRoute = () => {
  const { params }: { params: { token?: string } } = useRouteMatch();

  return (
    <>
      <Typography.Title level={2}>Manage Your Email Preferences</Typography.Title>
      <ProfileEmailPreferences token={params.token} />
    </>
  );
};