import bind from 'bind-decorator';
import { observable, computed } from 'mobx';
import { Focused } from 'react-credit-cards';
import { getPublicInvoice, getPublicRepairOrder } from 'services/Invoices';
import { getPublicAccount } from 'services/Accounts';
import { InvoiceProps, InvoiceLinkedItem } from 'models/Invoice';
import { AccountProps } from 'models/Account';
import { loadImage } from 'utils/Image';
import { payWithCard } from 'services/Payments';
import { RepairOrderProps } from 'models/RepairOrder';
import { addScript } from 'utils/Script';
import { getScrollbarWidth } from 'utils/Scrollbar';
import { proxyImage } from 'utils/Image';

export class InvoiceViewModel {
  @observable loading: boolean = true;
  @observable bgLoading: boolean = true;
  @observable formLoading: boolean = false;
  @observable pdfLoading: boolean = false;
  @observable error: any = null;
  @observable paymentSuccess: boolean = false;
  @observable paymentError: boolean = false;

  @observable number: string = '';
  @observable name: string = '';
  @observable expiryMonth: string = '';
  @observable expiryYear: string = '';
  @observable cvc: string = '';
  @observable focused: Focused = 'number';

  @observable invoice: InvoiceProps | null = null;
  @observable repairOrder: RepairOrderProps | null = null;
  @observable account: AccountProps | null = null;

  invoiceId: string = '';
  retUrl: string = '';

  constructor(invoiceId: string) {
    this.invoiceId = invoiceId;
  }

  @computed
  get expiry() {
    if (this.expiryMonth && this.expiryYear) {
      return this.expiryMonth + '/' + this.expiryYear;
    }

    return '';
  }

  setRetUrl(value: string) {
    this.retUrl = decodeURIComponent(value);
  }

  @bind
  async fetchInvoice() {
    this.loading = true;
    this.bgLoading = true;

    try {
      const response = await getPublicInvoice(this.invoiceId);
      const invoice = response.data.data as InvoiceProps;


      if (invoice.linkedItem === InvoiceLinkedItem.REPAIR_ORDER) {
        try {
          const repairOrderResponse = await getPublicRepairOrder(this.invoiceId);

          this.repairOrder = repairOrderResponse.data.data as RepairOrderProps;
        } catch (e) {
        }
      }

      const accountResponse = await getPublicAccount(invoice.accountId);
      const account = accountResponse.data.data as AccountProps;

      this.invoice = invoice;
      this.account = account;

      // preload logo for smooth animation
      await loadImage(proxyImage(this.account.logo), 2500);
    } catch (e) {
      this.error = e;
    }

    this.bgLoading = false;
    this.loading = false;
  }

  @bind
  onValuesChange(changedFields: IIndexable) {
    if ('number' in changedFields) {
      this.number = changedFields.number;
    }

    if ('name' in changedFields) {
      this.name = changedFields.name;
    }

    if ('expiryMonth' in changedFields) {
      this.expiryMonth = changedFields.expiryMonth;
    }

    if ('expiryYear' in changedFields) {
      this.expiryYear = changedFields.expiryYear;
    }

    if ('cvc' in changedFields) {
      this.cvc = changedFields.cvc;
    }
  }

  @bind
  handleFocus(e: any) {
    let name = e.target.name;

    if (name === 'expiryMonth' || name === 'expiryYear') {
      name = 'expiry';
    }

    this.focused = name;
  }

  @bind
  async handleSubmit() {
    this.paymentError = false;
    this.formLoading = true;

    try {
      await payWithCard(this.invoiceId, {
        number: this.number,
        name: this.name,
        expirationYear: this.expiryYear,
        expirationMonth: this.expiryMonth,
        cvc: this.cvc
      });

      this.paymentSuccess = true;
    } catch (e) {
      this.paymentError = true;
    }

    this.formLoading = false;
  }

  @bind
  print() {
    window.print();
  }

  @bind
  downloadPdf() {
    const docName = this.repairOrder ? (
      `${this.repairOrder.customerName}'s Invoice.pdf`
    ) : this.invoice ? (
      `${this.invoice?.customer.name}'s Invoice.pdf`
    ) : 'Invoice.pdf';

    this.pdfLoading = true;

    Promise.all([
      addScript('/jspdf.min.js'),
      addScript('/html2canvas.min.js')
    ])
      .then(() => {
        const element: HTMLDivElement | null = document.querySelector('.invoice-check-page');
        const rect = element?.getBoundingClientRect() || { left: 0, top: 0, width: 815, height: 1600};
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || 0;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || 0;
        const scrollWidth = getScrollbarWidth();
        const scrollGap = scrollWidth > 0 ? scrollWidth - 7 : 0;

        html2canvas(element, {
          x: rect.left + scrollLeft + scrollGap,
          y: rect.top + scrollTop + scrollGap,
          scrollY: 0,
          scrollX: 0,
          scale: 1,
          width: rect.width,
          height: rect.height,
          ignoreElements: (element: HTMLElement) => {
            if (
              element.classList.contains('ant-btn') ||
              element.classList.contains('btn') ||
              element.classList.contains('invoice-pay') ||
              element.classList.contains('invoice-check-page__buttons')
            ) {
              return true;
            }
            return false;
          },
          onclone: (doc: HTMLDocument) => {
            const pageEl: HTMLDivElement | null = doc.querySelector('.invoice-check-page');
            const animated: NodeListOf<Element> = doc.querySelectorAll('.animate__animated');

            if (pageEl) {
              pageEl.style.width = '815px';
              pageEl.style.minHeight = '1600px';
            }

            animated.forEach((el) => {
              el.classList.remove('animate__animated');
            });
          }
        }).then((canvas: HTMLCanvasElement) => {
          const doc = new jsPDF({
            format: 'a4'
          });

          doc.setFillColor(246, 246, 246);
          doc.rect(0, 0, rect.width, rect.height, 'F');

          doc.addImage(canvas, 'PNG', 0, 0);

          this.pdfLoading = false;

          doc.save(docName);
        });
      })
      .catch(() => this.pdfLoading = false);
  }

  hasVehicle() {
    if (!this.repairOrder) {
      return false;
    }

    const { vehicleId, vehicleMake } = this.repairOrder;

    return vehicleId && vehicleMake;
  }

  displayVehicle() {
    if (!this.repairOrder || !this.hasVehicle()) {
      return '';
    }

    const vehicleParts = [
      this.repairOrder.vehicleYear,
      this.repairOrder.vehicleMake,
      this.repairOrder.vehicleModel
    ].filter(Boolean);
    const additionalParts = [
      this.repairOrder.vehicleVin,
      this.repairOrder.vehicleMileage
    ].filter(Boolean)

    if (vehicleParts.length && additionalParts.length) {
      additionalParts.unshift('');
    }

    return vehicleParts.join(' ').trim() + additionalParts.join(', ').trim();
  }
}