import { Api } from './Api';
import { AccountUserRoles } from 'models/AccountUser';

export const getUsers = (accountId: string) => {
  return Api.get(`/accounts/${accountId}/users`);
};

export const inviteUser = (accountId: string, data: { email: string, name: string, message?: string, role: AccountUserRoles }) => {
  return Api.post(`/accounts/${accountId}/users`, data);
};

export const changeUser = (accountId: string, userId: string, data: { role?: AccountUserRoles, isDefault?: boolean }) => {
  return Api.put(`/accounts/${accountId}/users/${userId}`, data);
};

export const removeUser = (accountId: string, userId: string) => {
  return Api.delete(`/accounts/${accountId}/users/${userId}`);
};