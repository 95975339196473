import { observable } from 'mobx';

export interface AccountUserProps {
  userId: string,
  email: string,
  name: string,
  role: AccountUserRoles,
  isDefault: boolean,
  pending?: boolean,
}

export enum AccountUserRoles {
  OWNER = 'Owner',
  MANAGER = 'Manager',
  FINANCE = 'Finance',
  NONE = ''
}

export enum AccountUserNamesExplain {
  OWNER = 'Admin (manage access for users)',
  MANAGER = 'User (can only manage invoices)',
  FINANCE = 'Finance (manage banking)',
  NONE = ''
}

export enum AccountUserNames {
  OWNER = 'Admin',
  MANAGER = 'User',
  FINANCE = 'Finance',
  NONE = ''
}

export class AccountUser {
  userId: string = '';

  @observable name: string = '';
  @observable email: string = '';
  @observable role: AccountUserRoles = AccountUserRoles.NONE;
  @observable isDefault: boolean = false;
  @observable pending: boolean = false;

  constructor(data: AccountUserProps) {
    Object.assign(this, data);
  }
}