import React from 'react';

import { SignupView } from 'components/Signup/SignupView';
import { SignupModel } from 'components/Signup/SignupModel';
import { useRouteMatch } from 'react-router-dom';

export const SignupRoute = () => {
  const { params }: { params: { token?: string } } = useRouteMatch();
  const model = new SignupModel(params.token);

  return (
    <SignupView model={model} />
  );
};