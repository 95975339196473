import React from 'react';
import { Account } from 'models/Account';

import { AccountEditView } from 'components/Account/Edit/AccountEditView';
import { AccountEditModel } from 'components/Account/Edit/AccountEditModel';


export const AccountEditRoute: React.FC<{ account: Account }> = ({ account }) => {
  const model = new AccountEditModel(
    account.accountId,
    account.name,
    account.logo,
    account.slug || '',
    account.address,
    account.accentColor,
    account.currencies
  );

  return (
    <AccountEditView model={model} />
  );
};