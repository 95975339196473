import bind from 'bind-decorator';
import moment from 'moment';
import { observable, computed } from 'mobx';
import { getAccount } from 'services/Accounts';
import { Account } from 'models/Account';
import {
  getInvoices,
  addInvoice,
  removeInvoice,
  GetInvoicesFilters,
  GetInvoicesPagination,
  chargeInvoice
} from 'services/Invoices';
import { getCustomers, getCustomer, addCustomer, editCustomer, upsertCustomer } from 'services/Customers';
import { Invoice, InvoiceProps, InvoiceStatus, InvoiceLinkedItem } from 'models/Invoice';
import { notification } from 'antd';
import { gold } from '@ant-design/colors';
import { RadioChangeEvent } from 'antd/lib/radio';
import { openCustomerCreateModal } from '../Customers/AccountCustomerModal';
import { copyTextToClipboard } from 'utils/Copy';
import { getInvoiceUrl } from 'utils/Routes';
import { RepairOrderProps } from 'models/RepairOrder';
import { Customer, CustomerProps } from 'models/Customer';
import { AccountSettings } from 'models/AccountSettings';
import { rawPhoneNumber } from 'utils/Phone';
import { notifyInvoice } from 'utils/Notification';
import { AccessStore } from 'stores/Access';
import { EventEmitter } from 'utils/EventEmitter';
import { EVENTS } from '~/enums/common';
import { GlobalStore } from 'stores/Global';

enum INSTANT_FILTERS {
  TODAY = 'today',
  THIS_MONTH = 'this_month',
  PENDING = 'pending',
  COMPLETED = 'completed',
  NONE = 'none'
}

export class AccountViewModel {
  @observable loading: boolean = false;
  @observable tableLoading: boolean = false;
  @observable modalLoading: boolean = false;
  @observable modalVisible: boolean = false;
  @observable account: Account | null = null;
  @observable invoices: Invoice[] = [];

  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 10,
    total: 0
  };
  @observable filters: IIndexable = {
    created: [moment().startOf('month'), moment().endOf('month')]
  };
  @observable sorter: IIndexable = {};

  loaded: boolean = false;
  accountSettings: AccountSettings | null = null;
  accountId: string = '';
  customerId: string = '';

  constructor(accountId: string, customerId?: string) {
    this.accountId = accountId;
    this.accountSettings = new AccountSettings(this.accountId);

    if (customerId) {
      this.customerId = customerId;
      this.filters = {};
    }
  }

  @bind
  async fetchAccount() {
    this.loading = true;

    try {
      const [response] = await Promise.all([
        getAccount(this.accountId),
        this.accountSettings?.fetch()
      ]);

      this.account = new Account(response.data.data);

      await this.fetchInvoices({
        skip: this.pagintaion.skip,
        take: this.pagintaion.take
      });

      this.loaded = true;
    } catch (e) {
    }

    this.loading = false;
  }

  @bind
  async fetchInvoices(pagination?: GetInvoicesPagination) {
    this.tableLoading = true;

    try {
      let filters: GetInvoicesFilters = this.getResultFilters();

      const response = await getInvoices(this.accountId, pagination, filters);
      const responseData = response.data.data;

      this.invoices = responseData.items.map((item: InvoiceProps) => {
        return new Invoice(item);
      });

      this.pagintaion.skip = responseData.skip;
      this.pagintaion.take = responseData.take;
      this.pagintaion.total = responseData.total;
      this.pagintaion.page = (responseData.skip / responseData.take) + 1;
    } catch (e) {
    }

    this.tableLoading = false;
  }

  @bind
  async suggestCustomers(term: string) {
    try {
      const response = await getCustomers(this.accountId, { term });

      return response.data.data.items;
    } catch (e) {
    }

    return [];
  }

  @bind
  handleAdd() {
    this.modalVisible = true;
  }

  @bind
  async handleOk(values: any, repairOrder?: RepairOrderProps | null) {
    this.modalLoading = true;

    try {
      if (values.customerPhone) {
        values.customerPhone = rawPhoneNumber(values.customerPhone);
      }

      // upsert customer if repair order is present
      if (repairOrder && repairOrder.customerName) {
        const response = await upsertCustomer(this.accountId, {
          name: repairOrder.customerName,
          email: repairOrder.customerEmail || values.customerEmail || '',
          phoneNumber: repairOrder.customerPhone || values.customerPhone || '',
          currency: values.currency || ''
        });
        const customer = new Customer(response.data.data);

        values.customer = customer;
      }

      // update customer with a new currency
      if (values.customer && !values.customer.currency && values.currency) {
        try {
          const response = await getCustomer(this.accountId, values.customer.customerId);
          const responseCustomer: CustomerProps = response.data.data;

          await editCustomer(this.accountId, responseCustomer.customerId, {
            name: responseCustomer.name,
            email: responseCustomer.email || '',
            phoneNumber: responseCustomer.phoneNumber || '',
            currency: values.currency || '',
          });
        } catch (e) {
        }
      }

      // update customer with new phone or email if present
      if (!repairOrder && (values.customerEmail || values.customerPhone)) {
        try {
          const response = await getCustomer(this.accountId, values.customer.customerId);
          const responseCustomer: CustomerProps = response.data.data;

          await editCustomer(this.accountId, responseCustomer.customerId, {
            name: responseCustomer.name,
            email: values.customerEmail || responseCustomer.email || '',
            phoneNumber: values.customerPhone || responseCustomer.phoneNumber || '',
            currency: values.currency || '',
            notes: responseCustomer.notes
          });
        } catch (e) {
        }
      }

      const response = await addInvoice(this.accountId, {
        order: values.order,
        amount: values.amount,
        currency: values.currency,
        tax: values.tax || 0,
        billingFrom: values.billingFrom ? values.billingFrom.toISOString() : null,
        billingTo: values.billingTo ? values.billingTo.toISOString() : null,
        customerId: values.customer ? values.customer.customerId : null,
        linkedItem: repairOrder ? InvoiceLinkedItem.REPAIR_ORDER : null
      });
      const responseData: InvoiceProps = response.data.data;

      copyTextToClipboard(getInvoiceUrl(responseData.invoiceId), { silent: true });

      if (values.chargePayment) {
        await chargeInvoice(this.accountId, responseData.invoiceId, values.emailReceipt);
      }

      if (values.email || values.sms || values.push) {
        notifyInvoice(responseData, {
          email: values.email,
          text: values.sms,
          push: values.push
        });
      }

      notification.success({
        message: 'Invoice Successfully Added',
        description: `Invoice #${values.order} was saved, you can paste link from clipboard`
      });

      EventEmitter.trigger(EVENTS.INVOICE_CREATED, responseData.invoiceId);

      this.modalVisible = false;
    } catch (e) {
    }

    this.modalLoading = false;
  }

  @bind
  handleCancel() {
    this.modalVisible = false;
  }

  @bind
  handleAddCustomer(name: string, callback: (customer: any) => any) {
    openCustomerCreateModal(this.accountId, name, callback);
  }

  @bind
  async handleInvoiceRemove(invoiceId: string) {
    this.tableLoading = true;

    try {
      await removeInvoice(this.accountId, invoiceId);

      notification.success({
        message: 'Invoice was successfully removed'
      });

      EventEmitter.trigger(EVENTS.INVOICE_REMOVED, invoiceId);
    } catch (e) {
    }

    this.tableLoading = false;
  }

  @bind
  handleCompleteSetup() {
    const currentAccount = GlobalStore.getCurrentAccount();

    if (currentAccount) {
      GlobalStore.navigate(`/account/${currentAccount.slug}?complete=1`);
    }
  }

  @computed
  get instantFilter(): INSTANT_FILTERS {
    if (this.filters.created && this.filters.created.filter(Boolean).length === 2 && (
      moment().startOf('month').isSame(this.filters.created[0], 'day') &&
      moment().endOf('month').isSame(this.filters.created[1], 'day')
    )) {
      return INSTANT_FILTERS.THIS_MONTH;
    }

    if (this.filters.created && this.filters.created.filter(Boolean).length === 2 && (
      moment().isSame(this.filters.created[0], 'day') &&
      moment().isSame(this.filters.created[1], 'day')
    )) {
      return INSTANT_FILTERS.TODAY;
    }

    if (this.filters.status?.length === 1) {
      if (this.filters.status[0] === InvoiceStatus.PENDING) {
        return INSTANT_FILTERS.PENDING;
      }

      if (this.filters.status[0] === InvoiceStatus.COMPLETE) {
        return INSTANT_FILTERS.COMPLETED;
      }
    }

    return INSTANT_FILTERS.NONE;
  }

  @computed
  get repairOrdersFilters(): IIndexable {
    if (this.instantFilter === INSTANT_FILTERS.THIS_MONTH) {
      return {
        opened: [moment().startOf('month'), moment().endOf('month')]
      };
    }

    if (this.instantFilter === INSTANT_FILTERS.TODAY) {
      return {
        opened: [moment(), moment()]
      };
    }

    return {};
  }

  get showCreateButton(): boolean {
    const hasOrders = this.accountSettings?.repairOrdersEnabled();

    if (hasOrders) {
      return AccessStore.createInvoice;
    }

    return true;
  }

  @bind
  instantFilterChange(e: RadioChangeEvent) {
    const filterValue = e.target.value as INSTANT_FILTERS;

    if (filterValue === INSTANT_FILTERS.THIS_MONTH) {
      this.filters = {
        created: [moment().startOf('month'), moment().endOf('month')]
      };
    }

    if (filterValue === INSTANT_FILTERS.TODAY) {
      this.filters = {
        created: [moment(), moment()]
      };
    }

    if (filterValue === INSTANT_FILTERS.PENDING) {
      this.filters = {
        status: [InvoiceStatus.PENDING]
      };
    }

    if (filterValue === INSTANT_FILTERS.COMPLETED) {
      this.filters = {
        status: [InvoiceStatus.COMPLETE]
      };
    }

    this.fetchInvoices({
      skip: 0,
      take: this.pagintaion.take
    });
  }

  @bind
  takeChange(_current: number, pageSize: number) {
    this.fetchInvoices({
      skip: 0,
      take: pageSize
    });
  }

  @bind
  paginationChange(current: number) {
    this.fetchInvoices({
      skip: (current - 1) * this.pagintaion.take,
      take: this.pagintaion.take
    });
  }

  @bind
  getResultFilters() {
    const filters = this.filters;
    const sorter = this.sorter;

    let resultFilters: GetInvoicesFilters = {};

    if (this.customerId) {
      resultFilters.customerId = this.customerId;
    }

    if (sorter.order) {
      resultFilters.orderBy = sorter.columnKey;
      resultFilters.orderDirection = sorter.order === 'ascend' ? 0 : 1;
    }

    if (filters.customer) {
      resultFilters.customerTerm = filters.customer[0];
    }

    if (filters.user) {
      resultFilters.userTerm = filters.user[0];
    }

    if (filters.order) {
      resultFilters.order = filters.order[0];
    }

    if (filters.amount) {
      if (filters.amount[0]) {
        resultFilters.amountFrom = filters.amount[0];
      }

      if (filters.amount[1]) {
        resultFilters.amountTo = filters.amount[1];
      }
    }

    if (filters.status) {
      resultFilters.status = filters.status[0];
    }

    if (filters.created) {
      resultFilters.createdFrom = filters.created[0].startOf('day').toISOString();
      resultFilters.createdTo = filters.created[1].endOf('day').toISOString();
    }

    if (filters.paid) {
      resultFilters.paidFrom = filters.paid[0].startOf('day').toISOString();
      resultFilters.paidTo = filters.paid[1].endOf('day').toISOString();
    }

    return resultFilters;
  }

  @bind
  tableFiltersChange(_pagination: any, filters: IIndexable, sorter: IIndexable) {
    this.filters = filters;
    this.sorter = sorter;

    this.fetchInvoices({
      skip: 0,
      take: this.pagintaion.take
    });
  }

  @bind
  async refreshTable(payload: any) {
    await this.fetchInvoices({
      skip: this.pagintaion.skip,
      take: this.pagintaion.take
    });

    // highlight new row
    if (typeof payload === 'string') {
      const invoiceId = payload;
      const row: HTMLTableRowElement | null = document.querySelector(`.ant-table-row[data-row-key="${invoiceId}"]`);

      if (row) {
        const cellsLeft: NodeListOf<HTMLTableCellElement> = row.querySelectorAll('.ant-table-cell-fix-left');
        const cellsRight: NodeListOf<HTMLTableCellElement> = row.querySelectorAll('.ant-table-cell-fix-right');
        const cells: HTMLTableCellElement[] = [].slice.call(cellsLeft).concat([].slice.call(cellsRight));

        row.style.backgroundColor = gold[3];

        cells.forEach((cell) => cell.style.backgroundColor = gold[3]);

        setTimeout(() => {
          row.style.backgroundColor = '';

          cells.forEach((cell) => cell.style.backgroundColor = '');
        }, 500);
      }
    }
  }

  @bind
  resetTable() {
    this.filters = {};
    this.sorter = {};

    this.fetchInvoices({
      skip: 0,
      take: this.pagintaion.take
    });
  }
}