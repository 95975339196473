import React from 'react';
import { DatePicker, Space, Button } from 'antd';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';

export const getColumnDatesProps = (handleSearch?: (term: string) => any, handleReset?: () => any) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: 8, display: 'block' }}>
          <DatePicker.RangePicker
            value={selectedKeys}
            onChange={(value) => setSelectedKeys(value)}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();

              handleSearch && handleSearch(selectedKeys[0]);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 124 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();

              handleReset && handleReset();
            }}
            size="small"
            style={{ width: 124 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? '#96c94e' : undefined }} />
  }
};