import bind from 'bind-decorator';
import moment from 'moment';
import { isEqual } from 'lodash';
import { observable } from 'mobx';
import { extractRepairOrders, getRepairOrders, GetRepairOrdersFilters, GetRepairOrdersPagination } from 'services/RepairOrders';
import { RepairOrder, RepairOrderProps } from 'models/RepairOrder';

let reqCount = 0;

export class RepairOrdersModel {
  @observable loading: boolean = false;
  @observable tableLoading: boolean = false;
  @observable pullLoading: boolean = false;
  @observable repairOrders: RepairOrder[] = [];

  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 5,
    total: 0
  };
  @observable filters: IIndexable = {};
  @observable outsideFilters: IIndexable = {};
  @observable sorter: IIndexable = {};

  accountId: string = '';
  currentRequestId: string = '';

  constructor(accountId: string, options?: { defaultTake?: number }) {
    this.accountId = accountId;

    if (options && options.defaultTake) {
      this.pagintaion.take = options.defaultTake;
    }
  }

  private generateRequestId() {
    return 'req-' + reqCount++;
  }

  @bind
  async fetchForAccount() {
    this.loading = true;

    try {
      await this.fetchRepairOrders({
        skip: this.pagintaion.skip,
        take: this.pagintaion.take
      });
    } catch (e) {
    }

    this.loading = false;
  }

  @bind
  async fetchRepairOrders(pagination?: GetRepairOrdersPagination) {
    const requestId = this.generateRequestId();

    this.currentRequestId = requestId;
    this.tableLoading = true;

    try {
      let filters: GetRepairOrdersFilters = this.getResultFilters();

      const response = await getRepairOrders(this.accountId, pagination, filters);
      const responseData = response.data;

      if (this.currentRequestId === requestId) {
        this.repairOrders = responseData.items.map((item: RepairOrderProps) => {
          return new RepairOrder(item);
        });

        this.pagintaion.skip = responseData.skip;
        this.pagintaion.take = responseData.take;
        this.pagintaion.total = responseData.total;
        this.pagintaion.page = (responseData.skip / responseData.take) + 1;
      } else {
        return;
      }
    } catch (e) {
    }

    this.tableLoading = false;
  }

  @bind
  takeChange(_current: number, pageSize: number) {
    this.fetchRepairOrders({
      skip: 0,
      take: pageSize
    });
  }

  @bind
  paginationChange(current: number) {
    this.fetchRepairOrders({
      skip: (current - 1) * this.pagintaion.take,
      take: this.pagintaion.take
    });
  }

  @bind
  getResultFilters() {
    const filters = this.filters;
    const outsideFilters = this.outsideFilters;
    const sorter = this.sorter;

    let resultFilters: GetRepairOrdersFilters = {
      from: moment().subtract(6, 'days').startOf('day').toISOString(),
      to: moment().endOf('day').toISOString(),
      status: 'ReadyToPay'
    };

    if (sorter.order) {
      resultFilters.orderBy = sorter.columnKey;
      resultFilters.orderDirection = sorter.order === 'ascend' ? 0 : 1;
    }

    if (outsideFilters.opened) {
      resultFilters.from = outsideFilters.opened[0].startOf('day').toISOString();
      resultFilters.to = outsideFilters.opened[1].endOf('day').toISOString();
    }

    if (filters.opened) {
      resultFilters.from = filters.opened[0].startOf('day').toISOString();
      resultFilters.to = filters.opened[1].endOf('day').toISOString();
    }

    if (filters.roNumber) {
      resultFilters.roNumber = filters.roNumber[0];
    }

    if (filters.customerName) {
      resultFilters.customerName = filters.customerName[0];
    }

    if (filters.customerEmail) {
      resultFilters.customerEmail = filters.customerEmail[0];
    }

    return resultFilters;
  }

  @bind
  tableFiltersChange(_pagination: any, filters: IIndexable, sorter: IIndexable) {
    this.filters = filters;
    this.outsideFilters = {};
    this.sorter = sorter;

    this.fetchRepairOrders({
      skip: 0,
      take: this.pagintaion.take
    });
  }

  @bind
  outsideFiltersChange(filters: IIndexable) {
    if (!isEqual(this.outsideFilters, filters)) {
      this.outsideFilters = filters;

      this.fetchRepairOrders({
        skip: this.pagintaion.skip,
        take: this.pagintaion.take
      });
    }
  }

  @bind
  async pullOrders() {
    this.pullLoading = true;

    await extractRepairOrders(this.accountId);

    this.pullLoading = false;

    this.refreshTable();
  }

  @bind
  refreshTable() {
    this.fetchRepairOrders({
      skip: this.pagintaion.skip,
      take: this.pagintaion.take
    });
  }
}