import React, { useEffect } from 'react';
import { Account } from 'models/Account';

import { AccountCustomersView } from 'components/Account/Customers/AccountCustomersView';
import { AccountCustomersModel } from 'components/Account/Customers/AccountCustomersModel';

export const AccountCustomersRoute: React.FC<{ account: Account }> = ({ account }) => {
  const model = new AccountCustomersModel(account.accountId);

  useEffect(() => { model.fetchCustomers() }, [model]);

  return (
    <AccountCustomersView model={model} />
  );
};