import { observable } from 'mobx';
import { Account, AccountProps } from 'models/Account';
import { getAccounts } from 'services/Accounts';

export class AccountsStore {
  @observable static accounts: Account[] = [];

  static async fetchAccounts() {
    const response = await getAccounts();

    this.accounts = response.data.data.map((data: AccountProps) => new Account(data));
  }

  static getAccount(targetId: string): Account | void {
    return this.accounts.find(({ accountId, slug }) => accountId === targetId || slug === targetId);
  }
}