import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { Form, Modal, Input } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, ShopOutlined, HomeOutlined } from '@ant-design/icons';
import { AccountCustomersModel } from './AccountCustomersModel';
import { CurrencySuggest } from 'components/CurrencySuggest';

interface AccountCustomerModalProps {
  model: AccountCustomersModel;
  suggestedName?: string;
  onOk?: (response: any) => any;
  onCancel?: () => any;
  afterClose?: () => any;
}

export const openCustomerCreateModal = (accountId: string, name: string, callback: (data: any) => any) => {
  const node = document.createElement('div');
  const model = new AccountCustomersModel(accountId);

  document.body.appendChild(node);
  ReactDOM.render((
    <AccountCustomerModal
      model={model}
      suggestedName={name}
      onOk={(response: any) => {
        if (response) {
          const { data } = response.data;

          return callback(data);
        }

        return callback(null);
      }}
      afterClose={() => {
        ReactDOM.unmountComponentAtNode(node);
        document.body.removeChild(node);
      }}
    />
  ), node);

  setTimeout(() => {
    model.modalVisible = true
  });
};

export const AccountCustomerModal: React.FC<AccountCustomerModalProps> = observer(
  ({
     model,
     suggestedName,
     onOk,
     onCancel,
     afterClose
   }
  ) => {
    const { activeCustomer, modalVisible } = model;
    const [form] = Form.useForm();

    useEffect(() => {
      if (modalVisible) {
        if (activeCustomer) {
          form.setFieldsValue({
            name: activeCustomer.name,
            email: activeCustomer.email,
            phone: activeCustomer.phoneNumber,
            company: activeCustomer.company,
            address: activeCustomer.address,
            extraEmails: activeCustomer.extraEmails,
            currency: activeCustomer.currency,
            notes: activeCustomer.notes
          });
        } else {
          form.setFieldsValue({
            name: suggestedName || '',
            email: '',
            phone: '',
            company: '',
            address: '',
            extraEmails: '',
            currency: '',
            notes: ''
          });
        }
      }
    }, [form, suggestedName, activeCustomer, modalVisible]);

    return (
      <Modal
        title={activeCustomer ? `Edit ${activeCustomer.name}` : 'Add New Customer'}
        visible={modalVisible}
        onOk={() => form.submit()}
        afterClose={afterClose}
        okText={activeCustomer ? 'Save' : 'Add'}
        onCancel={() => {
          model.handleCancel();

          if (onCancel) {
            onCancel();
          }
        }}
        confirmLoading={model.modalLoading}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            const response = await model.handleOk(values);

            if (onOk) {
              onOk(response);
            }
          }}
        >
          <Form.Item
            label="Customer Name"
            name="name"
            rules={[
              { required: true, message: 'Please input customer name' },
              { min: 4, message: 'Customer name should be at least 4 characters long' }
            ]}
          >
            <Input placeholder="Customer Name" prefix={<UserOutlined/>} autoFocus/>
          </Form.Item>

          <Form.Item
            label="Company"
            name="company"
          >
            <Input placeholder="Company" prefix={<ShopOutlined/>}/>
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
          >
            <Input placeholder="Address" prefix={<HomeOutlined/>}/>
          </Form.Item>

          <Form.Item
            label="Customer Email"
            name="email"
            rules={[
              { type: 'email', message: 'Please enter correct email' }
            ]}
          >
            <Input placeholder="Customer Email" prefix={<MailOutlined/>}/>
          </Form.Item>

          <Form.Item
            label="Additional Emails (comma separated)"
            name="extraEmails"
          >
            <Input placeholder="Additional Emails" prefix={<MailOutlined/>}/>
          </Form.Item>

          <Form.Item
            label="Customer Phone Number"
            name="phone"
          >
            <Input type="tel" placeholder="Customer Phone Number" prefix={<PhoneOutlined/>}/>
          </Form.Item>

          <CurrencySuggest
            value={form.getFieldValue('currency')}
            onChange={(value) => form.setFieldsValue({ currency: value })}
          />

          <Form.Item
            label="Notes"
            name="notes"
          >
            <Input.TextArea placeholder="Notes" rows={4} autoSize={{ minRows: 3, maxRows: 10 }}/>
          </Form.Item>
        </Form>
      </Modal>
    )
  });