export const hexToRgb = (hex: string): { r: number, g: number, b: number, a: number } => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);

  if (!result) {
    return {
      r: 255,
      g: 255,
      b: 255,
      a: 255
    };
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: result[4] ? parseInt(result[4], 16) : 255
  };
}