import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Steps, Button, Typography, Form, Input, Result, Tooltip } from 'antd';
import { EditOutlined, BankOutlined, CloseCircleOutlined, CheckCircleFilled, SafetyCertificateFilled, UserAddOutlined, HomeOutlined } from '@ant-design/icons';
import { UploadImage, getValidator } from 'components/Upload/UploadImage';
import { AccountCreateModel } from './AccountCreateModel';
import { AccountsStore } from 'stores/Accounts';
import { GlobalStore } from 'stores/Global';
import { AccountUserRoles } from 'models/AccountUser';
import { slugify } from 'utils/Slugify';
import { getAccountRoute } from 'utils/Routes';
import { AccountAccessView } from 'components/Account/Access/AccountAccessView';
import { AccountAccessModel } from 'components/Account/Access/AccountAccessModel';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';

import './AccountCreateStyle.less';

interface AccountCreateViewProps {
  model: AccountCreateModel;
}

const Step1: React.FC<AccountCreateViewProps> = ({ model }) => {
  const [form] = Form.useForm();

  model.formControl = form;

  const onNameChange = (e: any) => {
    form.setFieldsValue({ slug: slugify(e.target.value) });
  };

  return (
    <>
      <Form
        form={form}
        className="account-create__form"
        layout="vertical"
        initialValues={{
          name: model.name,
          logo: model.logo,
          slug: model.slug || slugify(model.name),
          address: model.address,
          accentColor: model.accentColor || '#96c94e'
        }}
        onFinish={model.finishCreate}
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[
            { required: true, message: 'Please input company name' },
            { min: 4, message: 'Company name should be at least 4 characters long' }
          ]}
        >
          <Input placeholder="Company Name" prefix={<EditOutlined />} onChange={onNameChange} />
        </Form.Item>

        <Form.Item
          label="Company Url"
          name="slug"
          rules={[
            { required: true, message: 'Please input company url' },
            { min: 5, message: 'Company url should be at least 4 characters long' },
            { max: 25, message: 'Company url should be no more than 25 characters long' },
          ]}
        >
          <Input addonBefore={`${process.env.REACT_APP_ORIGIN_URL}/account/`} placeholder="Company Url" />
        </Form.Item>

        <Form.Item
          label="Billing Address"
          name="address"
          rules={[
            { required: true, message: 'Please input billing address' },
            { min: 4, message: 'Billing address should be at least 4 characters long' }
          ]}
        >
          <Input placeholder="Billing Address" prefix={<HomeOutlined />} />
        </Form.Item>

        <Form.Item
          className="account-create__logo-upload"
          label="Company Logo"
          name="logo"
          rules={[{ validator: getValidator() }]}
          required
        >
          <UploadImage
            cropProps={{
              minWidth: 300,
              minHeight: 300
            }}
          />
        </Form.Item>

        <Form.Item
          label="Accent Color"
          name="accentColor"
          required
        >
          <ColorPicker />
        </Form.Item>
      </Form>
    </>
  );
};

const Step2: React.FC<AccountCreateViewProps> = observer(({ model }) => {
  const currentAccount = AccountsStore.getAccount(GlobalStore.getCurrentAccountId());

  if (currentAccount && (currentAccount.role !== AccountUserRoles.OWNER && currentAccount.role !== AccountUserRoles.FINANCE)) {
    return (
      <Result
        status="warning"
        title="No Connected Bank Account"
        subTitle="Please wait until owner connects bank account."
      />
    );
  }

  if (model.connectError) {
    return (
      <Result
        status="error"
        title="Error While Trying To Connect Your Bank Account"
        subTitle="Please carefully check provided information and try again."
        extra={[
          <Button
            onClick={model.handleConnect}
            type="primary"
            key="connect"
            loading={model.connectLoading}
          >
            Try Again
          </Button>
        ]}
      >
        <div className="desc">
          <Typography.Paragraph>
            <Typography.Text
              strong
              style={{ fontSize: 16, }}
            >
              The content you submitted has the following error:
            </Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} /> {model.connectError}
          </Typography.Paragraph>
        </div>
      </Result>
    );
  }

  if (model.bankConnected) {
    return (
      <Result
        icon={<CheckCircleFilled />}
        status="info"
        title="Your Bank Account Was Successfully Connected"
        extra={[model.finance ? (
          <Button type="primary" key="console">
            <Link to={getAccountRoute(model.accountId, model.slug)}>Invoice Portal</Link>
          </Button>
        ) : (
            <Button
              onClick={model.next}
              type="primary"
              key="proceed"
            >
              Next
            </Button>
          )]}
      />
    );
  }

  return (
    <Result
      icon={<BankOutlined />}
      status="info"
      title="Link Your Bank Account"
      subTitle="Receive payments from customers directly to your bank account."
      extra={[
        <Button
          onClick={model.handleConnect}
          type="primary"
          key="connect"
          loading={model.connectLoading}
        >
          Connect Bank Account
        </Button>
      ]}
    />
  );
});

const Step3: React.FC<AccountCreateViewProps> = ({ model }) => {
  const accessModel = new AccountAccessModel(model.accountId);

  useEffect(() => { accessModel.fetchUsers() }, [accessModel]);

  return (
    <>
      <Result
        icon={<UserAddOutlined />}
        status="info"
        title="Please Assign Access to Users"
        subTitle="This will grant users access to invoice portal. Please assign at least one user."
        extra={(
          <AccountAccessView model={accessModel} inline />
        )}
      />
    </>
  );
};

const Step4: React.FC<AccountCreateViewProps> = ({ model }) => {
  return (
    <Result
      icon={<SafetyCertificateFilled />}
      status="info"
      title="You're ready to go!"
      extra={[
        <Button type="primary" key="console">
          <Link to={getAccountRoute(model.accountId, model.slug)}>Invoice Portal</Link>
        </Button>
      ]}
    />
  );
};

export const AccountCreateView: React.FC<AccountCreateViewProps> = observer(({ model }) => {
  const totalSteps = 4;
  const stepViews = [
    Step1,
    Step2,
    Step3,
    Step4
  ];
  const StepView = stepViews[model.step];

  return (
    <div className="account-create">
      <Typography.Title level={2} className="tc">
        Setup Mobile Pay Account
      </Typography.Title>
      {!model.finance && (
        <Steps current={model.step} status={model.step === 1 && model.connectError ? 'error' : 'process'}>
          <Steps.Step title="Basic Details" />
          <Steps.Step title="Bank Account" />
          <Steps.Step title="Manage Access" />
          <Steps.Step title="Complete" />
        </Steps>
      )}
      <div className="account-create__steps-content">
        <StepView model={model} />
      </div>
      {!model.finance && (
        <div className="account-create__steps-action">
          {model.step < totalSteps - 1 && (
            <Tooltip
              placement="topLeft"
              title={model.step === 1 && !model.bankConnected ? 'Please connect your bank account' : ''}
            >
              <Button
                disabled={model.step === 1 && !model.bankConnected}
                loading={model.loading}
                type="primary"
                onClick={model.next}
              >
                Next
            </Button>
            </Tooltip>
          )}
          {model.step === totalSteps - 1 && model.step < totalSteps - 1 && (
            <Button loading={model.loading} type="primary">
              Done
            </Button>
          )}
          {model.step > 0 && model.step < totalSteps - 1 && (
            <Button loading={model.loading} style={{ margin: '0 8px' }} onClick={model.prev}>
              Previous
            </Button>
          )}
        </div>
      )}
    </div>
  )
});