import bind from 'bind-decorator';
import moment from 'moment';
import { observable } from 'mobx';
import { Paginable, Pagination } from 'models/Paginable';
import { RepairOrder, RepairOrderProps } from 'models/RepairOrder';
import { addAccountRO, editAccountRO, getAccountROs, removeAccountRO } from 'services/Admin';
import { rawPhoneNumber } from 'utils/Phone';
import { AuthStore } from 'stores/Auth';

export class AdminAccountROModel extends Paginable {
  @observable modalLoading: boolean = false;
  @observable modalVisible: boolean = false;
  @observable activeRepairOrder: RepairOrder | null = null;
  @observable repairOrders: RepairOrder[] = [];
  @observable pagintaion = {
    page: 1,
    skip: 0,
    take: 20,
    total: 0
  };

  accountId: string = '';

  constructor(accountId: string) {
    super();

    this.accountId = accountId;
  }

  @bind
  async processResponse(response: any) {
    this.repairOrders = response.items.map((item: RepairOrderProps) => {
      return new RepairOrder(item);
    });
  }

  @bind
  async fetchFromApi(pagination?: Pagination, filters?: IIndexable) {
    return await getAccountROs(this.accountId, pagination, filters);
  }

  @bind
  mapFilters(filters: IIndexable) {
    const resultFilters: IIndexable = {};

    if (filters.roNumber) {
      resultFilters.roNumber = filters.roNumber[0];
    }

    if (filters.customerName) {
      resultFilters.customerName = filters.customerName[0];
    }

    if (filters.customerEmail) {
      resultFilters.customerEmail = filters.customerEmail[0];
    }

    return resultFilters;
  }

  @bind
  async handleRemoveRO(roNumber: string) {
    await removeAccountRO(this.accountId, roNumber);

    this.refresh();
  }

  @bind
  handleAdd() {
    this.activeRepairOrder = null;
    this.modalVisible = true;
  }

  @bind
  handleEdit(repairOrder: RepairOrder) {
    this.activeRepairOrder = repairOrder;
    this.modalVisible = true;
  }

  @bind
  handleCancel() {
    this.modalVisible = false;
  }

  @bind
  async handleOk(values: any) {
    this.modalLoading = true;

    let response = null;

    try {
      if (values.phone) {
        values.phone = rawPhoneNumber(values.phone);
      }

      const payload = {
        roNumber: values.roNumber,
        customerName: values.name,
        customerEmail: values.email,
        customerPhone: values.phone,
        customerAddress: values.address,
        vehicleMake: values.make,
        vehicleModel: values.model,
        vehicleYear: values.year,
        vehicleMileage: values.mileage,
        vehicleId: values.vin,
        vehicleVin: values.vin,
        opened: values.opened ? values.opened.toISOString() : null,
        closed: values.closed ? values.closed.toISOString() : null,
        laborItems: values.laborItems,
        partItems: values.partItems,
        subtotals: values.subtotals,
        internalStatus: values.internalStatus,
        total: values.balanceDue,
        rewardsDiscount: values.rewardsDiscount || 0
      };

      if (this.activeRepairOrder) {
        response = await editAccountRO(this.accountId, payload);
      } else {
        response = await addAccountRO(this.accountId, payload);
      }

      await this.refresh();

      this.modalVisible = false;
      this.activeRepairOrder = null;
    } catch (e) {
    }

    this.modalLoading = false;

    return response;
  }

  @bind
  prePopulateRO() {
    const now = moment();
    const currentUser = AuthStore.currentUser;
    const ro = this.activeRepairOrder;

    if (ro) {
      return {
        roNumber: ro.roNumber,
        name: ro.customerName,
        email: ro.customerEmail,
        phone: ro.customerPhone,
        address: ro.customerAddress,
        make: ro.vehicleMake,
        model: ro.vehicleModel,
        year: ro.vehicleYear,
        mileage: ro.vehicleMileage,
        vin: ro.vehicleVin,
        opened: ro.opened ? moment(ro.opened) : null,
        closed: ro.closed ? moment(ro.closed) : null,
        laborItems: ro.laborItems,
        partItems: ro.partItems,
        subtotals: ro.subtotals,
        internalStatus: ro.internalStatus,
        balanceDue: ro.total,
        rewardsDiscount: ro.rewardsDiscount || 0
      };
    }

    return {
      roNumber: now.format('YYYYMMDD'),
      name: currentUser.name,
      email: currentUser.email,
      phone: '3522383025',
      address: '9989 E. Howard Lane',
      make: 'Toyota',
      model: 'Corolla',
      year: '2018',
      mileage: '42000',
      vin: '4Y1SL65848Z411439',
      opened: now,
      closed: null,
      laborItems: [{
        description: 'SAFETY RECALL CAMPAIGN (5VZ:JR600-JR700-JR800)- REPLACE DRIV S/B: 15-033',
        quantity: '0.5',
        total: '30'
      }, {
        description: 'PERFORM MULTI-POINT INSPECTION',
        quantity: '0',
        total: '2.68'
      }],
      partItems: [{
        description: 'KIT, INFLATOR COMP',
        quantity: '1',
        total: '100'
      }],
      subtotals: [{
        name: 'Labor',
        amount: '0',
        warranty: '32.68',
        internal: '0'
      }, {
        name: 'Parts',
        amount: '0',
        warranty: '100',
        internal: '0'
      }],
      internalStatus: 'PRE-INVOICED',
      balanceDue: '132.68',
      rewardsDiscount: 0
    };
  }
}