import { getAccountSettings } from 'services/Accounts';
import { GlobalStore } from 'stores/Global';

let recent: AccountSettings | null = null;

export class AccountSettings {
  settings: { key: string, value: string }[] = [];

  accountId: string = '';

  constructor(accountId: string) {
    this.accountId = accountId;
  }

  async fetch() {
    try {
      const response = await getAccountSettings(this.accountId);

      this.settings = response.data.data;

      if (GlobalStore.getCurrentAccountId() === this.accountId) {
        recent = this;
      }
    } catch (e) {
    }
  }

  hasFlag(findKey: string): boolean {
    const setting = this.settings.find(({ key }) => key === findKey);

    if (!setting) {
      return false;
    }

    return !(setting.value === 'false');
  }

  mobileAppEnabled(): boolean {
    if (['e9eee549-0b80-4001-a98f-b9e5fcd027a9'].find((id) => id === this.accountId)) {
      return false;
    }

    return this.hasFlag('MobileDealerAppId');
  }

  repairOrdersEnabled(): boolean {
    return this.hasFlag('RepairOrdersEnabled');
  }

  // most recent relevant settings
  static getRecent(): AccountSettings | null {
    return recent;
  }
}