import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Menu, Avatar, Input } from 'antd';
import { UserOutlined, PlusOutlined, SelectOutlined, TeamOutlined, LockOutlined, SettingOutlined, BankOutlined, QuestionCircleOutlined, AuditOutlined } from '@ant-design/icons';
import { AuthStore } from 'stores/Auth';
import { AccountsStore } from 'stores/Accounts';
import { GlobalStore } from 'stores/Global';
import { AccountUserRoles } from 'models/AccountUser';
import { getAccountRoute } from 'utils/Routes';

export const AppMenu: React.FC = observer(() => {
  const { pathname } = useLocation();
  const [search, setSearch] = useState('');
  const accounts = AccountsStore.accounts;
  const currentAccount = AccountsStore.getAccount(GlobalStore.getCurrentAccountId());
  const submenu = accounts.length ? (
    <Menu.SubMenu className="accounts-submenu" popupClassName="accounts-submenu" title={(
      <>
        <Avatar
          className="accounts-submenu__image"
          shape="square"
          size="large"
          src={currentAccount ? currentAccount.logo : ''}
          icon={<SelectOutlined />}
        />
        <span className="accounts-submenu__title">{currentAccount ? currentAccount.name : 'Coose company from dropdown'}</span>
      </>
    )} onTitleClick={() => currentAccount && GlobalStore.navigate(getAccountRoute(currentAccount.accountId, currentAccount.slug))}>
      <Menu.Item key="search" disabled>
        <Input
          value={search}
          placeholder="input search text"
          onChange={(e) => setSearch(e.target.value)}
        />
      </Menu.Item>
      {accounts
        .filter(({ name }) => name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1)
        .map(({ accountId, name, slug, logo }) => (
          <Menu.Item key={getAccountRoute(accountId, slug)}>
            <Link to={getAccountRoute(accountId, slug)}>
              <Avatar
                className="accounts-submenu__image"
                shape="square"
                size="small"
                src={logo}
                icon={<UserOutlined />}
              />
              <span className="accounts-submenu__title">{name}</span>
            </Link>
          </Menu.Item>
        ))
      }
      {AuthStore.isAdmin && (
        <Menu.Item key="/account/add" icon={<PlusOutlined />}>
          <Link to="/account/add">Add new company</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  ) : null;
  const mainRole = currentAccount ? currentAccount.role === AccountUserRoles.OWNER || currentAccount.role === AccountUserRoles.FINANCE : false;
  const accountMenu = currentAccount ? ([
    <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'customers')}>
      <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'customers')}><TeamOutlined /> Customers</Link>
    </Menu.Item>,
    mainRole && (
      <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'reports')}>
        <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'reports')}><AuditOutlined /> Reports</Link>
      </Menu.Item>
    ),
    mainRole && (
      <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'access')}>
        <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'access')}><LockOutlined /> Manage Access</Link>
      </Menu.Item>
    ),
    mainRole && (
      <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'edit')}>
        <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'edit')}><SettingOutlined /> Settings</Link>
      </Menu.Item>
    ),
    mainRole && !currentAccount.stripeAccountId && (
      <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'banking')}>
        <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'banking')}><BankOutlined /> Banking</Link>
      </Menu.Item>
    )
  ].filter(Boolean)) : null;

  return (
    <Menu theme="light" mode="horizontal" selectedKeys={[pathname]} onOpenChange={() => setSearch('')}>
      <Menu.Item><a href="/" className="logo" key="logo">md</a></Menu.Item>
      {submenu}
      {accountMenu}
      <>
        <span style={{ marginRight: 'auto' }} />
      </>
      <Menu.Item>
        <a
          href="mailto:support@mobile-dealer.com"
          className="support"
          key="support"
        >
          <QuestionCircleOutlined />
        </a>
      </Menu.Item>
      {AuthStore.authenticated ? (
        <Menu.SubMenu title={(
          <Avatar
            icon={<UserOutlined />}
            src={AuthStore.currentUser.picture}
          />
        )}>
          {AuthStore.isAdmin && (
            <Menu.Item key="/admin/accounts">
              <Link to="/admin/accounts">Administrate</Link>
            </Menu.Item>
          )}
          {currentAccount && currentAccount.stripeAccountId ? (
            <Menu.Item key={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'stripe_dashboard')}>
              <Link to={getAccountRoute(currentAccount.accountId, currentAccount.slug, 'stripe_dashboard')}>Stripe Dashboard</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item key="/profile"><Link to="/profile">Profile</Link></Menu.Item>
          <Menu.Item key="/signout" onClick={() => AuthStore.logout()}>Sign-out</Menu.Item>
        </Menu.SubMenu>
      ) : <Menu.Item><Link to="/sign-in">Sign-in</Link></Menu.Item>}
    </Menu>
  )
});