import React from 'react';
import { Route } from 'react-router-dom';
import { Result, Button } from 'antd';
import { AuthStore } from '../stores/Auth';
import { GlobalStore } from 'stores/Global';
import { getSignInRetpathUrl } from 'utils/Routes';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route {...rest} render={props => (
      AuthStore.authenticated ? (
        <Component {...props} />
      ) : (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" onClick={() => GlobalStore.navigate(getSignInRetpathUrl())}>
                Sign-in
              </Button>
            }
          />
        )
    )} />
  );
};