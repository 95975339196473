import React from 'react';
import { Result, Button } from 'antd';

import { GlobalStore } from 'stores/Global';
import { VerifyView } from 'components/Verify/VerifyView';
import { VerifyModel } from 'components/Verify/VerifyModel';
import { useRouteMatch } from 'react-router-dom';

export const VerifyRoute = () => {
  const { params }: { params: { token?: string } } = useRouteMatch();

  if (!params.token) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, we cannot find page you're looking for."
        extra={
          <Button type="primary" onClick={() => GlobalStore.navigate('/')}>
            Back Home
          </Button>
        }
      />
    );
  }

  const model = new VerifyModel(params.token);

  return (
    <VerifyView model={model} />
  );
};