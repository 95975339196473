import React, { useEffect } from 'react';
import { Account } from 'models/Account';

import { AccountAccessView } from 'components/Account/Access/AccountAccessView';
import { AccountAccessModel } from 'components/Account/Access/AccountAccessModel';

export const AccountAccessRoute: React.FC<{ account: Account }> = ({ account }) => {
  const model = new AccountAccessModel(account.accountId);

  useEffect(() => { model.fetchUsers() }, [model]);

  return (
    <AccountAccessView model={model} />
  );
};