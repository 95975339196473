export interface RepairOrderProps {
  accountId: string,
  closed: string | null,
  customerId: string,
  customerName: string,
  customerEmail: string,
  customerPhone: string,
  customerAddress: string,
  internalStatus: string,
  invoiceStatus: string,
  status: string,
  laborItems: RepairOrderDesc[]
  opened: string,
  partItems: RepairOrderDesc[],
  roNumber: string,
  subtotals: RepairOrderSubtotal[],
  total: number,
  rewardsDiscount: number,
  vehicleId: string | null,
  vehicleVin: string | null,
  vehicleMake: string | null,
  vehicleModel: string | null,
  vehicleYear: number | null,
  vehicleMileage: number | null,
}

export interface RepairOrderDesc {
  description: string,
  quantity: number,
  total: number
}

export interface RepairOrderSubtotal {
  name: string,
  amount: number,
  warranty: number,
  internal: number
}

export class RepairOrder {
  accountId: string = '';
  closed: string | null = null;
  customerId: string = '';
  customerName: string = '';
  customerEmail: string = '';
  customerPhone: string = '';
  customerAddress: string = '';
  internalStatus: string = '';
  invoiceStatus: string = '';
  status: string = '';
  laborItems: RepairOrderDesc[] = [];
  opened: string = '';
  partItems: RepairOrderDesc[] = [];
  roNumber: string = '';
  subtotals: RepairOrderSubtotal[] = [];
  total: number = 0;
  rewardsDiscount: number = 0;
  vehicleId: string | null = null;
  vehicleVin: string | null = null;
  vehicleMake: string | null = null;
  vehicleModel: string | null = null;
  vehicleYear: number | null = null;
  vehicleMileage: number | null = null;

  constructor(data: RepairOrderProps) {
    Object.assign(this, data);
  }
}