export interface FIProps {
  id: string,
  customerName: string,
  customerEmail: string,
  customerPhone: string,
  salesDate: string | null,
  vehicleId: string | null,
  vehicleVin: string | null,
  vehicleMake: string | null,
  vehicleModel: string | null,
  vehicleYear: number | null,
  vehicleMileage: number | null,
}

export class FI {
  id: string = '';
  customerName: string = '';
  customerEmail: string = '';
  customerPhone: string = '';
  salesDate: string | null = null;
  vehicleId: string | null = null;
  vehicleVin: string | null = null;
  vehicleMake: string | null = null;
  vehicleModel: string | null = null;
  vehicleYear: number | null = null;
  vehicleMileage: number | null = null;

  constructor(data: FIProps) {
    Object.assign(this, data);
  }
}