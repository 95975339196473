import bind from 'bind-decorator';
import { observable } from 'mobx';
import { chargeInvoice, getInvoice, getInvoiceCharge, refundInvoice } from 'services/Invoices';
import { Invoice, InvoiceCharge } from 'models/Invoice';
import { notification } from 'antd';
import { EventEmitter } from 'utils/EventEmitter';
import { EVENTS } from '~/enums/common';

export class AccountInvoiceDetailsModel {
  @observable loading: boolean = true;
  @observable refundVisible: boolean = false;
  @observable refundLoading: boolean = false;
  @observable chargeLoading: boolean = false;
  @observable emailInvoice: boolean = false;
  @observable invoice: Invoice | null = null;
  @observable invoiceCharge: InvoiceCharge | null = null;

  accountId: string = '';
  invoiceId: string = '';

  constructor(accountId: string, invoiceId: string) {
    this.accountId = accountId;
    this.invoiceId = invoiceId;
  }

  @bind
  async fetchInvoice() {
    this.loading = true;

    try {
      const response = await getInvoice(this.accountId, this.invoiceId);
      const chargeResponse = await getInvoiceCharge(this.accountId, this.invoiceId);

      this.invoice = new Invoice(response.data.data);
      this.invoiceCharge = chargeResponse.data.data;
    } catch (e) {
    }

    this.loading = false;
  }

  @bind
  showRefund() {
    this.refundVisible = true;
  }

  @bind
  handleCancelRefund() {
    this.refundVisible = false;
  }

  @bind
  async handleOkRefund(values: any) {
    this.refundLoading = true;

    try {
      await refundInvoice(this.accountId, this.invoiceId, values);

      const chargeResponse = await getInvoiceCharge(this.accountId, this.invoiceId);

      this.invoiceCharge = chargeResponse.data.data;

      this.refundVisible = false;
    } catch (e) {
    }

    this.refundLoading = false;
  }

  @bind
  async chargeInvoice() {
    this.chargeLoading = true;

    try {
      await chargeInvoice(this.accountId, this.invoiceId, this.emailInvoice);
      await this.fetchInvoice();

      EventEmitter.trigger(EVENTS.INVOICE_UPDATED, this.invoiceId);

      notification.success({
        message: 'Invoice Successfully Charged',
        description: `Invoice #${this.invoiceId} was charged successfully`
      });
    } catch (e) {
    } finally {
      this.chargeLoading = false;
    }
  }
}