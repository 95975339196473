import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Col, Pagination, Popconfirm, Row, Skeleton, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { AdminAccountFIModel } from './AdminAccountFIModel';
import { AdminAccountFIModal } from './AdminAccountFIModal';
import { FIProps } from 'models/FI';
import { FIDetails } from 'components/FI/FIDetails';
import { formatPhoneNumber } from 'utils/Phone';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';

interface AdminAccountViewProps {
  model: AdminAccountFIModel;
}

export const AdminAccountFIView: React.FC<AdminAccountViewProps> = observer(({ model }) => {
  const { fis, loading, loaded } = model;

  if (loading && !loaded) {
    return (
      <Skeleton active />
    );
  }

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '60px'
  }, {
    title: <span>F&I&nbsp;Id</span>,
    dataIndex: 'id',
    key: 'id',
    width: '140px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'id' && model.sorter.order,
    render: (id: string, record: FIProps) => {
      return (
        <FIDetails accountId={model.accountId} fi={record}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                {id}
              </span>
            </Tooltip>
          )}
        </FIDetails>
      );
    },
  }, {
    title: 'Customer',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '200px',
    sorter: true,
    filteredValue: model.filters.customerName || null,
    sortOrder: model.sorter.columnKey === 'customerName' && model.sorter.order,
    ...getColumnSearchProps('by customer name')
  }, {
    title: 'Email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
    width: '200px',
    sorter: true,
    filteredValue: model.filters.customerEmail || null,
    sortOrder: model.sorter.columnKey === 'customerEmail' && model.sorter.order,
    render: (customerEmail: string | null) => customerEmail || '—',
    ...getColumnSearchProps('by customer email')
  }, {
    title: 'Phone',
    dataIndex: 'customerPhone',
    key: 'customerPhone',
    width: '160px',
    render: (customerPhone: string | null) => formatPhoneNumber(customerPhone) || '—',
  }, {
    title: 'Sales Date',
    dataIndex: 'salesDate',
    key: 'salesDate',
    width: '120px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'salesDate' && model.sorter.order,
    render: (salesDate: any) => {
      return (
        <Tooltip title={salesDate ? moment(salesDate).format('LLL') : ''}>
          <span>
            {salesDate ? moment(salesDate).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
  }, {
    title: <span>Actions</span>,
    key: 'action',
    width: '100px',
    className: 'account-view__actions',
    render: (_text: any, record: FIProps) => (
      <Space size="small">
        <FIDetails accountId={model.accountId} fi={record}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <Button size="small" icon={<FileSearchOutlined />} onClick={openDrawer} />
            </Tooltip>
          )}
        </FIDetails>
        <Popconfirm title="Are you sure?" onConfirm={() => model.handleRemoveFI(record.id)}>
          <Tooltip title="Delete" placement="right" mouseEnterDelay={0.5}>
            <Button size="small" icon={<DeleteOutlined />} danger />
          </Tooltip>
        </Popconfirm>
      </Space>
    ),
  }];

  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: any[] = fis.map((fi, i) => ({
    ...fi,
    original: fi,
    '#': currentSkip + i + 1
  }));

  return (
    <>
      <Table
        columns={columns as any}
        size="small"
        className="xsmall"
        dataSource={data}
        pagination={false}
        loading={model.loading}
        rowKey={(record: FIProps) => record.id}
        onChange={model.tableFiltersChange}
        footer={() => (
          <Row>
            <Col span={12}>
              <Button size="small" type="primary" onClick={model.handleAdd}>
                <PlusOutlined /> Add F&I
              </Button>
            </Col>
            <Col className="tr" span={12}>
              <Pagination
                size="small"
                className="account-view__pagination"
                showSizeChanger
                onShowSizeChange={model.takeChange}
                onChange={model.paginationChange}
                defaultPageSize={model.pagintaion.take}
                defaultCurrent={1}
                current={currentPage}
                total={currentTotal}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Col>
          </Row>
        )}
      />

      <AdminAccountFIModal model={model} />
    </>
  );
});