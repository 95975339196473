import React from 'react';

import { ForgotView } from 'components/Forgot/ForgotView';
import { ForgotModel } from 'components/Forgot/ForgotModel';

export const ForgotRoute = () => {
  const model = new ForgotModel();

  return (
    <ForgotView model={model} />
  );
};