import React from 'react';
import { Tabs } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { ProfileBasicInfo } from 'components/Profile/ProfileBasicInfo';
import { ProfilePassword } from 'components/Profile/ProfilePassword';
import { ProfileEmailPreferences } from 'components/Profile/ProfileEmailPreferences';

export const ProfileRoute = () => {
  return (
    <Tabs defaultActiveKey="1" type="line">
      <Tabs.TabPane
        tab={
          <span>
            <UserOutlined />
              Basic Info
            </span>
        }
        key="1"
      >
        <ProfileBasicInfo />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            <LockOutlined />
              Password
            </span>
        }
        key="2"
      >
        <ProfilePassword />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <span>
            <MailOutlined />
              Email Preferences
            </span>
        }
        key="3"
      >
        <ProfileEmailPreferences />
      </Tabs.TabPane>
    </Tabs>
  );
};