export const loadImage = (src: string, timeoutDuration: number = 5000): Promise<string> => {
  return new Promise((resolve, _reject) => {
    const image = new Image();
    const timeout = setTimeout(() => resolve(''), timeoutDuration);

    image.onload = () => {
      clearTimeout(timeout);

      resolve(src);
    };
    image.onerror = () => {
      clearTimeout(timeout);

      resolve('');
    };

    image.src = src;
  });
};

export const proxyImage = (url: string) => {
  if (!url) {
    return url;
  }

  if (url.indexOf('http') === 0) {
    return `/image-proxy.php?url=${url}`;
  }

  return `/image-proxy.php?url=${window.location.origin + url}`;
};