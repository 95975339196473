import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Typography } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { ResetModel } from './ResetModel';

import './ResetStyle.less'

interface ResetViewProps {
  model: ResetModel;
}

const { Title, Text } = Typography;

export const ResetView: React.FC<ResetViewProps> = observer(({ model }) => {
  return (
    <Row justify="center" align="middle" className="signup-page">
      <Form
        className="signup-page__form box"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={model.finishReset}
      >
        <div className="tc mb-5">
          <a href="/" className="brand">md</a>
          <Title level={4} className="mb-0 mt-3">Password reset</Title>
          <Text type="secondary">reset your password to a new one</Text>
        </div>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password' },
            { min: 6, message: 'Password should be at least 6 characters long' }
          ]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          label="Confirm password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Passwords do not match');
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-3"
            loading={model.loading}
            block
          >
            Change Password
          </Button>
        </Form.Item>

        <div className="tc">
          <Text type="secondary">
            <span>Already have an account?</span> <Link to="/sign-in">Sign-in now!</Link>
          </Text>
        </div>
      </Form>
    </Row>

  );
});