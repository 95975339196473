import React from 'react';
import { InvoiceViewModel } from './InvoiceViewModel';
import { formatCurrency, getFormattedNumber } from 'utils/Currency';

interface InvoiceViewBodyROProps {
  model: InvoiceViewModel;
}

export const InvoiceViewBodyRO: React.FC<InvoiceViewBodyROProps> = ({ model }) => {
  const color = model.account?.accentColor || '#96c94e';
  const tax = (model.invoice?.tax || 0);
  const total = (model.invoice?.amount || 0) + tax;
  const extraColumns = model.repairOrder?.subtotals.some(({ warranty, internal }) => warranty || internal);
  const subtotalsItems = model.repairOrder?.subtotals.filter(({ name }) => {
    if (!name) {
      return true;
    }

    name = name.toLowerCase();

    if (name.indexOf('tax') === 0) {
      return false;
    }

    return true;
  }) || [];
  const laborItems = (model.repairOrder?.laborItems || []).concat(model.repairOrder?.partItems || []);

  return (
    <>
      {model.hasVehicle() && (<table style={{ marginTop: '32px' }}>
        <thead>
          <tr>
            <th align="left" style={{ width: '40%' }}>
              Vehicle
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {model.displayVehicle()}
            </td>
          </tr>
        </tbody>
      </table>)}
      <table style={{ marginTop: '32px' }}>
        <thead>
          <tr>
            <th align="left" style={{ width: '40%' }}>
              Description Of Services
            </th>
            <th align="center">
              Quantity
            </th>
            {extraColumns && (
              <th align="center">
                Warranty
              </th>
            )}
            {extraColumns && (
              <th align="center">
                Internal
              </th>
            )}
            <th align="center">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {laborItems.map(({ description, quantity, total }, i) => {
            return (
              <tr key={i}>
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }}>
                  {description}
                </td>
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                  {quantity}
                </td>
                {extraColumns && <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">—</td>}
                {extraColumns && <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">—</td>}
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                  {getFormattedNumber(total, model.invoice?.currency)}
                </td>
              </tr>
            );
          })}
          {subtotalsItems.map(({ name, internal, warranty, amount }, i) => {
            return (
              <tr key={i}>
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }}>
                  {name}
                </td>
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                  —
                </td>
                {extraColumns && (
                  <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                    {getFormattedNumber(warranty, model.invoice?.currency)}
                  </td>
                )}
                {extraColumns && (
                  <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                    {getFormattedNumber(internal, model.invoice?.currency)}
                  </td>
                )}
                <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                  {getFormattedNumber(amount, model.invoice?.currency)}
                </td>
              </tr>
            );
          })}
          {tax > 0 && (
            <tr>
              <td style={{ padding: '8px 0' }}>
                Service Charge:
              </td>
              <td></td>
              {extraColumns && <td></td>}
              {extraColumns && <td></td>}
              <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                {formatCurrency(total - tax, model.invoice?.currency)}
              </td>
            </tr>
          )}
          {tax > 0 && (
            <tr>
              <td style={{ padding: '8px 0' }}>
                Tax:
              </td>
              <td></td>
              {extraColumns && <td></td>}
              {extraColumns && <td></td>}
              <td style={{ borderBottom: '1px solid #eaeaea', padding: '8px 0' }} align="center">
                {formatCurrency(tax, model.invoice?.currency)}
              </td>
            </tr>
          )}
          <tr>
            <td style={{ padding: '8px 0' }}>
              TOTAL:
            </td>
            <td></td>
            {extraColumns && <td></td>}
            {extraColumns && <td></td>}
            <td style={{ borderBottom: `1px solid ${color}`, padding: '8px 0' }} align="center">
              <strong style={{ color }}>{formatCurrency(total, model.invoice?.currency)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};