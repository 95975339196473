import Axios from 'axios';

export const getIpAddress = async () => {
  try {
    const response = await Axios.get('https://api.ipify.org');

    return response.data;
  } catch (e) {
  }

  return '';
}