import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Skeleton, Result, Button, Descriptions, Divider } from 'antd';
import { FileProtectOutlined } from '@ant-design/icons';
import { AccountCustomerDetailsModel } from './AccountCustomerDetailsModel';
import { AccountViewView } from '../View/AccountViewView';
import { AccountViewModel } from '../View/AccountViewModel';
import { formatPhoneNumber } from 'utils/Phone';

import './AccountCustomerDetailsStyle.less'

interface AccountCustomerDetailsViewProps {
  model: AccountCustomerDetailsModel;
  accountModel: AccountViewModel;
  inline?: boolean;
  onClose?: () => any;
}

export const AccountCustomerDetailsView: React.FC<AccountCustomerDetailsViewProps> = observer((
  {
    model,
    accountModel,
    inline,
    onClose
  }
) => {
  if (model.loading) {
    return (
      <Skeleton active/>
    );
  }

  if (!model.customer) {
    return (
      <Result
        status="error"
        title="Can't Load Customer"
        subTitle="Sorry, we cannot display this cutomer at this time."
        extra={[
          <Button type="primary" key="retry" onClick={model.fetchCustomer}>
            Try Again
          </Button>,
          inline && onClose ? (
            <Button key="close" onClick={onClose}>
              Close
            </Button>
          ) : null
        ]}
      />
    );
  }

  return (
    <>
      <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="Name">
          {model.customer.name}
        </Descriptions.Item>
        <Descriptions.Item label="Company">
          {model.customer.company || '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {model.customer.address || '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {model.customer.email ? (
            <a href={`mailto:${model.customer.email}`}>{model.customer.email}</a>
          ) : '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Additional Emails">
          {model.customer.extraEmails ? model.customer.extraEmails : '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {moment(model.customer.created).format('LLL')}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number">
          {model.customer.phoneNumber ? (
            <a href={`tel:${model.customer.phoneNumber}`}>
              {formatPhoneNumber(model.customer.phoneNumber)}
            </a>
          ) : '—'}
        </Descriptions.Item>
        {model.customer.currency ? (
          <Descriptions.Item label="Currency">
            {model.customer.currency}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Updated Date">
          {model.customer.updated ? moment(model.customer.updated).format('LLL') : '—'}
        </Descriptions.Item>
        {model.customer.notes ? (
          <Descriptions.Item label="Notes" span={2} style={{ display: 'flex', flexDirection: 'column' }}>
            <p>
              <pre style={{ whiteSpace: 'normal' }}>{model.customer.notes}</pre>
            </p>
          </Descriptions.Item>
        ) : null}
      </Descriptions>

      {model.customer.stripeCustomerId ? <Divider/> : null}

      {model.customer.stripeCustomerId ? (
        <Button
          icon={<FileProtectOutlined/>}
          onClick={() => model.chargeInvoice(accountModel.invoices)}
        >
          Create Invoice
        </Button>
      ) : null}

      <Divider/>

      <div className="ant-drawer-title mb-4">Payment History</div>

      <AccountViewView
        model={accountModel}
        inline
      />
    </>
  );
});