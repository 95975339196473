import { Customer, CustomerProps } from './Customer';
import { User, UserProps } from './User';

export enum InvoiceStatus {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  DELETED = 'Deleted'
}

export enum InvoiceLinkedItem {
  REPAIR_ORDER = 'RepairOrder'
}

export interface InvoiceProps {
  accountId: string;
  invoiceId: string;
  currency: string;
  order: string;
  amount: number;
  tax?: number;
  processingFee?: number;
  status: InvoiceStatus;
  attempts: number;
  error: string;
  stripeChargeId: string;
  paid: string;
  created: string;
  updated: string;
  billingFrom?: string;
  billingTo?: string;
  customer: CustomerProps;
  user: UserProps;
  linkedItem: InvoiceLinkedItem | null;
}

export interface InvoiceCharge {
  last4: string,
  amount: number,
  amountRefunded: number,
  created: string,
  refunds: {
    amount: number,
    created: string,
    createdBy: string,
    reason: string
  }[]
}

export class Invoice {
  accountId: string = '';
  invoiceId: string = '';
  currency: string = '';
  order: string = '';
  amount: number = 0;
  tax: number = 0;
  processingFee: number = 0;
  status: InvoiceStatus = InvoiceStatus.PENDING;
  attempts: number = 0;
  error: string = '';
  stripeChargeId: string = '';
  paid: string = '';
  created: string = '';
  updated: string = '';
  billingFrom: string = '';
  billingTo: string = '';
  customer: Customer;
  user: User;
  linkedItem: InvoiceLinkedItem | null = null;

  constructor(data: InvoiceProps) {
    Object.assign(this, data);

    this.customer = new Customer(data.customer);
    this.user = new User(data.user);
  }
}