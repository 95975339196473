import { InvoiceProps } from 'models/Invoice';
import { sendNotification } from 'services/Notification';
import { notification } from 'antd';
import { smsConfirm, smsEnsure } from './SmsConfirm';

export const smsInvoice = async (invoice: InvoiceProps, confirm?: boolean) => {
  if (!invoice.customer.phoneNumber) {
    return smsConfirm(invoice);
  }

  if (confirm) {
    return smsEnsure(invoice);
  }

  const to = invoice.customer.name || 'Customer';

  try {
    await sendNotification(invoice.accountId, invoice.invoiceId, { text: true });

    notification.success({
      message: `Sms to ${to} was sent`
    });
  } catch (e) {
  }
};