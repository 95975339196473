import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { AccountCustomerDetailsModel } from './AccountCustomerDetailsModel';
import { AccountCustomerDetailsView } from './AccountCustomerDetailsView';
import { AccountViewModel } from '../View/AccountViewModel';

interface AccountCustomerDetailsDrawerProps {
  customerId: string;
  accountId: string;
  children: ({ openDrawer }: { openDrawer: any }) => React.ReactNode
}

export const AccountCustomerDetailsDrawer: React.FC<AccountCustomerDetailsDrawerProps> = observer(({ customerId, accountId, children }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = useCallback(() => setVisible(true), [setVisible]);
  const onClose = useCallback(() => setVisible(false), [setVisible]);
  const model = useMemo(() => new AccountCustomerDetailsModel(accountId, customerId), [accountId, customerId]);
  const accountModel = useMemo(() => new AccountViewModel(accountId, customerId), [accountId, customerId]);

  useEffect(() => {
    if (visible) {
      model.fetchCustomer();
      accountModel.fetchAccount();
    }
  }, [model, accountModel, visible]);

  return (
    <>
      {children ? children({ openDrawer: () => setVisible(true) }) : (
        <Button type="primary" onClick={showDrawer}>
          Open
        </Button>
      )}
      <Drawer
        width={960}
        title={(
          <>
            <span>Customer Details</span>
            <Button icon={<CloseOutlined />} onClick={() => setVisible(false)} />
          </>
        )}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <AccountCustomerDetailsView
          model={model}
          accountModel={accountModel}
          onClose={() => setVisible(false)}
          inline
        />
      </Drawer>
    </>
  );
});