import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { ForgotModel } from './ForgotModel';

import './ForgotStyle.less'

interface ForgotViewProps {
  model: ForgotModel;
}

const { Title, Text } = Typography;

export const ForgotView: React.FC<ForgotViewProps> = observer(({ model }) => {
  return (
    <Row justify="center" align="middle" className="forgot-page">
      <Form
        className="forgot-page__form box"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={model.finishForgot}
      >
        <div className="tc mb-5">
          <a href="/" className="brand">md</a>
          <Title level={4} className="mb-0 mt-3">Recover your password</Title>
          <Text type="secondary">receive password reset instructions</Text>
        </div>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
        >
          <Input placeholder="Email" prefix={<MailOutlined />} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-3"
            loading={model.loading}
            block
          >
            Reset Password
          </Button>
        </Form.Item>

        <div className="tc">
          <Text type="secondary">
            <span>Don't have an account yet?</span> <Link to="/sign-up">Create one now!</Link>
          </Text>
        </div>
      </Form>
    </Row>

  );
});