import React, { useEffect } from 'react';

import { AdminAccountsView } from 'components/Admin/Accounts/AdminAccountsView';
import { AdminAccountsModel } from 'components/Admin/Accounts/AdminAccountsModel';


export const AdminAccountsRoute: React.FC = () => {
  const model = new AdminAccountsModel();

  useEffect(() => { model.refresh() }, [model]);

  return (
    <AdminAccountsView model={model} />
  );
};