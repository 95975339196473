import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { AccountInvoiceDetailsModel } from './AccountInvoiceDetailsModel';
import { AccountInvoiceDetailsView } from './AccountInvoiceDetailsView';

interface AccountInvoiceDetailsDrawerProps {
  invoiceId: string;
  accountId: string;
  children: ({ openDrawer }: { openDrawer: any }) => React.ReactNode
}

export const AccountInvoiceDetailsDrawer: React.FC<AccountInvoiceDetailsDrawerProps> = observer(({ invoiceId, accountId, children }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = useCallback(() => setVisible(true), [setVisible]);
  const onClose = useCallback(() => setVisible(false), [setVisible]);
  const model = useMemo(() => new AccountInvoiceDetailsModel(accountId, invoiceId), [accountId, invoiceId]);

  useEffect(() => {
    if (visible) {
      model.fetchInvoice();
    }
  }, [model, visible]);

  return (
    <>
      {children ? children({ openDrawer: () => setVisible(true) }) : (
        <Button type="primary" onClick={showDrawer}>
          Open
        </Button>
      )}
      <Drawer
        width={960}
        title={
          <>
            <span>Invoice Details</span>
            <Button icon={<CloseOutlined />} onClick={() => setVisible(false)} />
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <AccountInvoiceDetailsView
          model={model}
          onClose={() => setVisible(false)}
          inline
        />
      </Drawer>
    </>
  );
});