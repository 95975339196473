import { History } from 'history';
import { observable } from 'mobx';
import { AccountsStore } from './Accounts';
import { AccountUserRoles } from 'models/AccountUser';

const ACCOUNT_STORAGE_KEY = '__md_pay_account_id';

export class GlobalStore {
  static env: string = process.env.REACT_APP_TARGET_NODE_ENV || 'undefined';
  static originUrl: string = process.env.REACT_APP_ORIGIN_URL || '';
  static apiUrl: string = process.env.REACT_APP_API_URL || '';
  static blobUrl: string = process.env.REACT_APP_BLOB_URL || '';

  static history: History;

  @observable static currentAccountId: string = '';

  static setHistory(history: History) {
    GlobalStore.history = history;
  }

  static navigate(path: string) {
    GlobalStore.history.push(path);
  }

  static setCurrentAccountId(accountId: string) {
    this.currentAccountId = accountId;

    if (accountId) {
      localStorage.setItem(ACCOUNT_STORAGE_KEY, accountId);
    } else {
      localStorage.removeItem(ACCOUNT_STORAGE_KEY);
    }
  }

  static getCurrentAccountId() {
    if (!this.currentAccountId) {
      const storedId = localStorage.getItem(ACCOUNT_STORAGE_KEY);

      if (storedId) {
        this.currentAccountId = storedId;
      }
    }

    return this.currentAccountId;
  }

  static getCurrentAccount() {
    return AccountsStore.getAccount(GlobalStore.getCurrentAccountId());
  }

  static isCurrentAccountOwner() {
    const currentAccount = GlobalStore.getCurrentAccount();

    return currentAccount && (currentAccount.role === AccountUserRoles.OWNER || currentAccount.role !== AccountUserRoles.FINANCE);
  }
}