import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { Form, Modal, Input, InputNumber, AutoComplete, Tag, Button, Checkbox, Row, Col, DatePicker } from 'antd';
import {
  UserOutlined,
  NumberOutlined,
  LoadingOutlined,
  CloseOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { AccountViewModel } from './AccountViewModel';
import { CustomerProps, Customer } from 'models/Customer';
import { formatPhoneNumber } from 'utils/Phone';
import { RepairOrderProps } from 'models/RepairOrder';
import { AccountSettings } from 'models/AccountSettings';
import { CurrencySuggest } from 'components/CurrencySuggest';
import { formatCurrency } from 'utils/Currency';
import { Invoice } from 'models/Invoice';
import moment from 'moment';

interface AccountInvoiceModalProps {
  model: AccountViewModel;
  repairOrder?: RepairOrderProps | null;
  selectedCustomer?: Customer | null;
  selectedCustomerInvoices?: Invoice[];
  onOk?: (response: any) => any;
  onCancel?: () => any;
  afterClose?: () => any;
}

export const openInvoiceCreateModal = (accountId: string, repairOrder: RepairOrderProps | null, callback: (data: any) => any) => {
  const node = document.createElement('div');
  const model = new AccountViewModel(accountId);

  document.body.appendChild(node);
  ReactDOM.render((
    <AccountInvoiceModal
      model={model}
      repairOrder={repairOrder}
      onOk={(response: any) => {
        if (response) {
          const { data } = response.data;

          return callback(data);
        }

        return callback(null);
      }}
      afterClose={() => {
        ReactDOM.unmountComponentAtNode(node);
        document.body.removeChild(node);
      }}
    />
  ), node);

  setTimeout(() => {
    model.modalVisible = true
  });
};

export const openInvoiceChargeModal = (accountId: string, customer: Customer, invoices: Invoice[], callback: (data: any) => any) => {
  const node = document.createElement('div');
  const model = new AccountViewModel(accountId);

  document.body.appendChild(node);
  ReactDOM.render((
    <AccountInvoiceModal
      model={model}
      selectedCustomer={customer}
      selectedCustomerInvoices={invoices}
      onOk={(response: any) => {
        if (response) {
          const { data } = response.data;

          return callback(data);
        }

        return callback(null);
      }}
      afterClose={() => {
        ReactDOM.unmountComponentAtNode(node);
        document.body.removeChild(node);
      }}
    />
  ), node);

  setTimeout(() => {
    model.modalVisible = true
  });
};

const SuggestLabel: React.FC<{ name: string, email: string | null, phone: string | null }> = (
  {
    name,
    email,
    phone
  }
) => {
  return (
    <div className="customers-suggest-label">
      <span className="customers-suggest-label__name">
        {name}
      </span>
      {Boolean(email) && (
        <Tag className="customers-suggest-label__email" style={{ marginLeft: 'auto' }}>
          {email}
        </Tag>
      )}
      {Boolean(phone) && (
        <Tag className="customers-suggest-label__email"  style={{ marginLeft: Boolean(email) ? 0 : 'auto' }}>
          {formatPhoneNumber(phone)}
        </Tag>
      )}
    </div>
  );
};

const CustomerTag: React.FC<{ customer: CustomerProps | null }> = ({ customer }) => {
  if (!customer) {
    return null;
  }

  return (
    <>
      <UserOutlined/> {customer.name} {Boolean(customer.email) && (
      <Tag>{customer.email}</Tag>)} {Boolean(customer.phoneNumber) && (
      <Tag>{formatPhoneNumber(customer.phoneNumber)}</Tag>)}
    </>
  );
};

export const AccountInvoiceModal: React.FC<AccountInvoiceModalProps> = observer(
  ({
     model,
     repairOrder,
     selectedCustomer,
     selectedCustomerInvoices,
     onOk,
     onCancel,
     afterClose
   }) => {
    const { modalVisible } = model;
    const [form] = Form.useForm();
    const [sendEmail, setSendEmail] = useState(false);
    const [sendSms, setSendSms] = useState(false);
    const [suggestLoading, setSuggestLoading] = useState(false);
    const [suggestCreate, setSuggestCreate] = useState(false);
    const [chargePayment, setChargePayment] = useState(false);
    const [suggestOptions, setSuggestOptions] = useState([]);
    const [suggestValue, setSuggestValue] = useState('');
    const [customer, setCustomer] = useState<CustomerProps | null>(selectedCustomer || null);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState(null);
    const [showAdditional, setShowAdditional] = useState(false);
    const settings = AccountSettings.getRecent();
    const customerRequiresEmail = customer && sendEmail && !customer.email;
    const customerRequiresPhone = customer && sendSms && !customer.phoneNumber;
    const customerHasPaymentMethod = customer && customer.stripeCustomerId;

    const searchSuggest = async (term: string) => {
      setSuggestLoading(true);

      const customers = await model.suggestCustomers(term);

      setSuggestCreate(Boolean(term && !customers.length));
      setSuggestOptions(customers.map((customer: CustomerProps) => ({
        value: customer.name + '__[' + customer.customerId + ']',
        label: <SuggestLabel name={customer.name} email={customer.email} phone={customer.phoneNumber}/>,
        key: customer.customerId,
        customer
      })));

      setSuggestLoading(false);
    };
    const handleFocus = () => searchSuggest(suggestValue);
    const handleChange = debounce((value: string) => {
      searchSuggest(value);
      setSuggestValue(value);
    }, 300);
    const handleSelect = (_value: string, option: any) => {
      setCustomerValue(option.customer);

      if (option.customer && option.customer.currency) {
        form.setFieldsValue({ currency: option.customer.currency });
      }
    };
    const setCustomerValue = (customer: CustomerProps | null) => {
      setCustomer(customer);

      form.setFieldsValue({ customer });
    };

    useEffect(() => {
      if (modalVisible) {
        if (repairOrder) {
          const roCustomer = Customer.fromRepairOrder(repairOrder);
          const roTax = repairOrder.subtotals.find((subtotal) => subtotal.name.toLowerCase().includes('tax'));

          let tax = 0;

          if (roTax && roTax.amount) {
            tax = roTax.amount;
          }

          form.setFieldsValue({
            order: repairOrder.roNumber,
            amount: repairOrder.total,
            tax: tax || '',
            to: repairOrder.customerName,
            customer: roCustomer,
            currency: '',
            billingFrom: null,
            billingTo: null,
            sms: false,
            email: false,
            push: false,
            chargePayment: false,
            emailReceipt: false
          });
          setChargePayment(false);
          setSuggestCreate(false);
          setSuggestOptions([]);
          setSuggestValue(repairOrder.customerName);
          setCustomer(roCustomer);
        } else {
          let amount = '';
          let tax = '';

          if (selectedCustomerInvoices && selectedCustomerInvoices.length) {
            const sortedInvoices = selectedCustomerInvoices.slice().sort((a, b) => {
              if (!a.paid) {
                return 1;
              }

              if (!b.paid) {
                return -1;
              }

              return moment(b.paid).valueOf() - moment(a.paid).valueOf();
            });

            amount = (sortedInvoices[0].amount || '') as any;
            tax = (sortedInvoices[0].tax || '') as any;
          }

          form.setFieldsValue({
            order: '',
            amount,
            tax,
            to: selectedCustomer ? selectedCustomer.name + '__[' + selectedCustomer.customerId + ']' : '',
            customer: selectedCustomer || null,
            currency: selectedCustomer && selectedCustomer.currency ? selectedCustomer.currency : '',
            billingFrom: null,
            billingTo: null,
            sms: false,
            email: false,
            push: false,
            chargePayment: !!selectedCustomer,
            emailReceipt: !!selectedCustomer
          });
          setChargePayment(!!selectedCustomer);
          setSuggestCreate(false);
          setSuggestOptions([]);
          setSuggestValue('');
          setCustomer(selectedCustomer || null);
        }

        setTotal((form.getFieldValue('amount') || 0) + (form.getFieldValue('tax') || 0))
        setCurrency(form.getFieldValue('currency'));
        setShowAdditional(false)
      }
    }, [form, modalVisible, repairOrder, selectedCustomer]);

    return (
      <Modal
        title={repairOrder ? 'Create New Invoice From Repair Order' : 'Create New Invoice'}
        visible={modalVisible}
        onOk={() => form.submit()}
        afterClose={afterClose}
        okText={'Create'}
        onCancel={() => {
          model.handleCancel();

          if (onCancel) {
            onCancel();
          }
        }}
        confirmLoading={model.modalLoading}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={async (values) => {
            const response = await model.handleOk(values, repairOrder);

            if (onOk) {
              onOk(response);
            }
          }}
          onChange={() => {
            const amount = (form.getFieldValue('amount') || 0);
            const tax = (form.getFieldValue('tax') || 0);

            if (!isNaN(Number(amount)) && !isNaN(Number(tax))) {
              setTotal(Number(amount) + Number(tax));
            }

            setCurrency(form.getFieldValue('currency'));
          }}
        >
          <Form.Item
            label="Order Number"
            name="order"
            rules={[
              { required: true, message: 'Please input order number' }
            ]}
          >
            <Input
              disabled={Boolean(repairOrder)}
              placeholder="Order Number"
              prefix={<NumberOutlined/>}
              autoFocus
            />
          </Form.Item>

          <Form.Item
            style={customer ? { display: 'none' } : {}}
            label="To"
            name="to"
            rules={[
              ({ getFieldValue }) => ({
                validator(_rule: any, value: any) {
                  if (!value) {
                    return Promise.reject('Please input customer name');
                  }

                  if (!repairOrder && !getFieldValue('customer')) {
                    return Promise.reject('Please select or create customer');
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            required
          >
            <AutoComplete
              disabled={Boolean(repairOrder)}
              options={suggestOptions}
              onFocus={handleFocus}
              onSelect={handleSelect}
              onChange={(value: string) => {
                setCustomerValue(null);

                handleChange(value);
              }}
            >
              <Input
                placeholder="Customer name"
                prefix={<UserOutlined/>}
                suffix={suggestLoading ? <LoadingOutlined/> : (
                  suggestCreate ? (
                    <Button
                      className="suggest-create"
                      type="link"
                      onClick={() => model.handleAddCustomer(suggestValue, (newCustomer: any) => setCustomerValue(newCustomer))}
                    >
                      Create Customer
                    </Button>
                  ) : null
                )}
              />
            </AutoComplete>
          </Form.Item>

          <Form.Item
            style={customer ? {} : { display: 'none' }}
            label="To"
            name="customer"
            required
          >
            <>
              <CustomerTag customer={customer}/>
              {!repairOrder && !selectedCustomer && (
                <Button
                  onClick={() => {
                    setSuggestCreate(false);
                    setCustomer(null);
                    setSuggestValue('');
                    setSuggestOptions([]);

                    form.setFieldsValue({ to: '' });
                  }}
                  size="small"
                  icon={<CloseOutlined/>}
                />
              )}
            </>
          </Form.Item>

          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{
                  validator: (_rule: any, value: any) => {
                    if (!value) {
                      return Promise.reject('Please input amount');
                    }

                    return Promise.resolve();
                  }
                }]}
                required
              >
                <InputNumber
                  style={{ minWidth: '30%', width: '100%' }}
                  placeholder="Amount"
                  min={0}
                  formatter={(value: any) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => String(value).replace(/(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CurrencySuggest
                value={form.getFieldValue('currency')}
                onChange={(value) => {
                  form.setFieldsValue({ currency: value });

                  setCurrency(value as any);
                }}
              />
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                label="Tax"
                name="tax"
              >
                <InputNumber
                  style={{ minWidth: '30%', width: '100%' }}
                  placeholder="Tax"
                  min={0}
                  formatter={(value: any) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => String(value).replace(/(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ marginTop: 38 }}>
                <strong>TOTAL:</strong> {formatCurrency(total, currency || 'USD')}
              </div>
            </Col>
          </Row>

          <Form.Item style={showAdditional ? { display: 'none' } : {}}>
            <Button type="default" onClick={() => setShowAdditional(true)}><PlusOutlined/> Additional fields</Button>
          </Form.Item>

          <Row gutter={32} style={showAdditional ? {} : { display: 'none' }}>
            <Col span={12}>
              <Form.Item
                label="Billing From"
                name="billingFrom"
              >
                <DatePicker/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Billing To"
                name="billingTo"
              >
                <DatePicker/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Notification Channel"
            name="email"
            valuePropName="checked"
            style={customerRequiresEmail ? { marginBottom: 8 } : { marginBottom: 0 }}
          >
            <Checkbox onChange={(e) => setSendEmail(e.target.checked)}>Email the Invoice</Checkbox>
          </Form.Item>

          {customerRequiresEmail && (
            <Form.Item
              name="customerEmail"
              rules={[
                { required: true, message: 'Please input customer email' },
                { type: 'email', message: 'Please enter correct email' }
              ]}
            >
              <Input placeholder="Customer Email" prefix={<MailOutlined/>} autoFocus/>
            </Form.Item>
          )}

          <Form.Item
            name="sms"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox onChange={(e) => setSendSms(e.target.checked)}>Text Invoice</Checkbox>
          </Form.Item>

          {customerRequiresPhone && (
            <Form.Item
              name="customerPhone"
              rules={[
                { required: true, message: 'Please input customer phone number' },
              ]}
            >
              <Input placeholder="Customer Phone Number" prefix={<PhoneOutlined/>} autoFocus/>
            </Form.Item>
          )}

          {settings?.mobileAppEnabled() ? (
            <Form.Item
              name="push"
              valuePropName="checked"
            >
              <Checkbox>Invoice by Push Notification</Checkbox>
            </Form.Item>
          ) : null}

          {customerHasPaymentMethod ? (
            <Form.Item
              label="Collect Payment"
              name="chargePayment"
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Checkbox onChange={(e) => {
                setChargePayment(e.target.checked);

                if (e.target.checked) {
                  form.setFieldsValue({ emailReceipt: true });
                }
              }}>Charge Current Payment Method</Checkbox>
            </Form.Item>
          ) : null}

          {customerHasPaymentMethod && chargePayment ? (
            <Form.Item
              name="emailReceipt"
              valuePropName="checked"
            >
              <Checkbox>Email the Receipt</Checkbox>
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    )
  });