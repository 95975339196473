import bind from 'bind-decorator';
import qs from 'query-string';
import { observable } from 'mobx';
import { AuthStore } from 'stores/Auth';

export class LoginModel {
  @observable loading: boolean = false;

  @bind
  async finishSignin(values: any) {
    this.loading = true;

    const { retpath }: any = qs.parse(window.location.search);
    const { email, password } = values;

    await AuthStore.login({ email, password, retpath });

    this.loading = false;
  }
}