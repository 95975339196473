import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Drawer, Descriptions, Divider, Table } from 'antd';
import { CloseOutlined, FileAddOutlined } from '@ant-design/icons';
import { RepairOrderProps } from 'models/RepairOrder';
import { formatPhoneNumber } from 'utils/Phone';
import { getFormattedNumber } from 'utils/Currency';
import { openInvoiceCreateModal } from 'components/Account/View/AccountInvoiceModal';

interface RepairOrdersDetailsProps {
  accountId: string,
  repairOrder: RepairOrderProps,
  children: ({ openDrawer }: { openDrawer: any }) => React.ReactNode,
  onInvoiceCreated?: (data: any) => any
}

export const RepairOrdersDetails: React.FC<RepairOrdersDetailsProps> = observer(({ accountId, repairOrder, children, onInvoiceCreated }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = useCallback(() => setVisible(true), [setVisible]);
  const onClose = useCallback(() => setVisible(false), [setVisible]);

  return (
    <>
      {children ? children({ openDrawer: () => setVisible(true) }) : (
        <Button type="primary" onClick={showDrawer}>
          Open
        </Button>
      )}
      <Drawer
        className="repair-order-drawer"
        width={960}
        title={
          <>
            <span>Repair Order Details</span>
            <Button icon={<CloseOutlined />} onClick={() => setVisible(false)} />
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        {visible ? (
          <>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item label="RO Number">
                {repairOrder.roNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Name">
                {repairOrder.customerName || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Address">
                {repairOrder.customerAddress || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Email">
                {repairOrder.customerEmail || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Phone">
                {formatPhoneNumber(repairOrder.customerPhone) || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Opened Date">
                {moment(repairOrder.opened).format('LLL')}
              </Descriptions.Item>
              <Descriptions.Item label="Closed Date">
                {repairOrder.closed ? moment(repairOrder.closed).format('LLL') : '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle ID">
                {repairOrder.vehicleId || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Status" span={2}>
                {repairOrder.internalStatus || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Labor Items" className="repair-order-drawer__labor" span={2}>
                <Table
                  dataSource={repairOrder.laborItems.map((item, i) => ({ ...item, key: i }))}
                  pagination={false}
                  size="small"
                  tableLayout="fixed"
                  bordered
                >
                  <Table.Column title="Description" dataIndex="description" key="description" />
                  <Table.Column title="Quantity" dataIndex="quantity" key="quantity" />
                  <Table.Column title="Total" dataIndex="total" key="total" />
                </Table>
              </Descriptions.Item>
              <Descriptions.Item label="Part Items" className="repair-order-drawer__labor" span={2}>
                <Table
                  dataSource={repairOrder.partItems.map((item, i) => ({ ...item, key: i }))}
                  pagination={false}
                  size="small"
                  tableLayout="fixed"
                  bordered
                >
                  <Table.Column title="Description" dataIndex="description" key="description" />
                  <Table.Column title="Quantity" dataIndex="quantity" key="quantity" />
                  <Table.Column title="Total" dataIndex="total" key="total" />
                </Table>
              </Descriptions.Item>
              <Descriptions.Item label="Subtotal" className="repair-order-drawer__labor" span={2}>
                <Table
                  dataSource={repairOrder.subtotals.map((item, i) => ({ ...item, key: i }))}
                  pagination={false}
                  size="small"
                  tableLayout="fixed"
                  bordered
                >
                  <Table.Column title="Name" dataIndex="name" key="name" />
                  <Table.Column title="Amount" dataIndex="amount" key="amount" />
                  <Table.Column title="Internal" dataIndex="internal" key="internal" />
                  <Table.Column title="Warranty" dataIndex="warranty" key="warranty" />
                </Table>
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                {getFormattedNumber(repairOrder.total)}
              </Descriptions.Item>
              {repairOrder.rewardsDiscount && (
                <Descriptions.Item label="Rewards Discount">
                  {getFormattedNumber(repairOrder.rewardsDiscount)}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Divider />
            <Button
              type="primary"
              icon={<FileAddOutlined />}
              onClick={() => {
                openInvoiceCreateModal(accountId, repairOrder, (data) => {
                  onInvoiceCreated && onInvoiceCreated(data);

                  setVisible(false);
                });
              }}
              disabled={repairOrder.total === 0}
            >
              Create Invoice
            </Button>
          </>
        ) : null}
      </Drawer>
    </>
  );
});