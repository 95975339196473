import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Account } from 'models/Account';
import { GlobalStore } from 'stores/Global';
import { goToDashboardPage } from 'services/Stripe';

export const AccountStripeDashboardRoute: React.FC<{ account: Account }> = ({ account }) => {
  useEffect(() => {
    if (account.stripeAccountId) {
      goToDashboardPage(account.accountId);
    }
  }, [account]);

  if (account.stripeAccountId) {
    return (
      <Result
        icon={<LoadingOutlined />}
        status="info"
        title="Redirecting..."
        subTitle="Please wait, redirecting you to stripe dashboard."
      />
    );
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, bank account is not setted up."
      extra={
        <Button type="primary" onClick={() => GlobalStore.navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};