import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InvoiceProps } from 'models/Invoice';
import { pushInvoice } from './Push';

export const pushEnsure = (invoice: InvoiceProps) => {
  Modal.confirm({
    title: 'Please Confirm',
    icon: <ExclamationCircleOutlined />,
    content: `The push notification will be sent to ${invoice.customer.name}. Would you like to proceed?`,
    async onOk() {
      await pushInvoice(invoice);

      return true;
    }
  });
}