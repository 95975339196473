import { Api } from './Api';

export interface UploadImageProps {
  base64Image: string,
  crop?: {
    x: number,
    y: number,
    width: number,
    height: number
  },
  resize?: {
    width?: number,
    height?: number
  }
}

export const uploadImage = (data: UploadImageProps) => {
  return Api.post('/images', data);
};