import bind from 'bind-decorator';
import { observable } from 'mobx';
import { sendResetPassword } from 'services/User';
import { notification } from 'antd';

export class ForgotModel {
  @observable loading: boolean = false;

  @bind
  async finishForgot(values: any) {
    this.loading = true;

    const { email } = values;

    await sendResetPassword({ email })
      .then(() => notification.success({
        message: 'Password reset email has been sent!',
        description: 'Check you email to follow the instructions'
      }))
      .catch(() => '');

    this.loading = false;
  }
}