import bind from 'bind-decorator';
import { observable } from 'mobx';
import { confirmEmail } from 'services/User';
import { AuthStore } from 'stores/Auth';
import { notification } from 'antd';
import { GlobalStore } from 'stores/Global';

export class VerifyModel {
  @observable loading: boolean = false;
  @observable verifySuccess: boolean = false;

  token: string = '';

  constructor(token: string) {
    this.token = token;
  }

  @bind
  async verify() {
    this.loading = true;

    await confirmEmail(this.token)
      .then(() => notification.success({
        message: 'Email is successfully verified'
      }))
      .then(() => GlobalStore.navigate('/'))
      .then(() => this.verifySuccess = true)
      .catch(() => this.verifySuccess = false);

    AuthStore.setVerified(this.verifySuccess);

    this.loading = false;
  }
}