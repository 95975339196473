import jwt from 'jwt-decode';
import { observable } from 'mobx';
import { GlobalStore } from './Global';
import { CurrentUser, CurrentUserFields } from 'models/CurrentUser';
import { me, signin } from 'services/User';
import { notification } from 'antd';
import { AccountsStore } from './Accounts';

const TOKEN_STORAGE_KEY = '__md_pay_auth_token';

export class AuthStore {
  @observable static authenticated = false;
  @observable static isAdmin = false;
  @observable static currentUser = new CurrentUser();
  @observable static token = '';

  private static authorize(payload: CurrentUserFields) {
    const tokenDecoded: any = jwt(this.token);

    this.authenticated = true;
    this.isAdmin = tokenDecoded.UserType === 'Admin';

    this.currentUser.assignCredentials(payload);

    this.currentUser.authorized = true;
  }

  private static deauthorize() {
    this.authenticated = false;
    this.isAdmin = false;

    this.currentUser.clearCredentials();

    this.currentUser.authorized = false;

    AccountsStore.accounts = [];
  }

  static async checkAuth() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (token) {
      AuthStore.token = token;
    }

    try {
      const response = await me();

      this.authorize(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.deauthorize();

        return 401;
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.error('%O', err);
        }

        return 500;
      }
    }

    return 0;
  }

  static async login(data: { email: string, password: string, retpath?: string }) {
    const response: any = await signin(data).catch((err) => {
      if (err.response && err.response.status === 401) {
        const desc = err.response.data.error && err.response.data.error.message;

        notification.error({
          message: 'Sign-In Error',
          description: desc || 'Unknown error.'
        });
      }
    });
    const token = response && response.data ? response.data.data : '';

    if (token) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);

      AuthStore.token = token;

      // refresh user data
      await AuthStore.checkAuth();
      await AccountsStore.fetchAccounts();

      if (data.retpath) {
        if (AccountsStore.accounts.length) {
          GlobalStore.setCurrentAccountId(AccountsStore.accounts[0].accountId);
        }

        GlobalStore.navigate(data.retpath);
      } else {
        GlobalStore.navigate('/');
      }
    }
  }

  static logout() {
    this.deauthorize();

    localStorage.removeItem(TOKEN_STORAGE_KEY);

    GlobalStore.navigate('/sign-in');
  }

  static sessionExpired(refresh = true) {
    if (this.authenticated) {
      this.deauthorize();

      localStorage.removeItem(TOKEN_STORAGE_KEY);

      if (refresh) {
        window.location.href = '';
      }
    }
  }

  static setVerified(verified: boolean) {
    this.currentUser.emailVerified = verified;
  }

  static setName(name: string) {
    this.currentUser.name = name;
  }

  static setAvatar(avatar: string) {
    this.currentUser.picture = avatar;
  }
}