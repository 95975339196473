import React from 'react';
import { AccountsStore } from 'stores/Accounts';
import { AccountCreateRoute } from './AccountCreateRoute';
import { GlobalStore } from 'stores/Global';
import { Redirect } from 'react-router-dom';
import { getAccountRoute } from 'utils/Routes';

export const DefaultRoute: React.FC = () => {
  const accounts = AccountsStore.accounts;
  const currentAccountId = GlobalStore.getCurrentAccountId();
  const currentAccount = AccountsStore.getAccount(currentAccountId);

  if (!accounts.length) {
    return <AccountCreateRoute />;
  }

  if (currentAccount) {
    return <Redirect to={getAccountRoute(currentAccount.accountId, currentAccount.slug)} />;
  }

  return <Redirect to={getAccountRoute(accounts[0].accountId, accounts[0].slug)} />;
};