import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { getIp, shouldRestrictIp } from 'utils/IpRestrictions';

interface IpifyCheckProps {
  pass: () => any,
  restrict: (ip: string) => any,
  accountId: string
}

export const IpifyCheck: React.FC<IpifyCheckProps> = ({ accountId, pass, restrict }) => {
  const [loading, setLoading] = useState(false);
  const [component, setComponent] = useState(null);

  useEffect(() => {
    const restrictIp = async () => {
      if (await shouldRestrictIp(accountId)) {
        setComponent(restrict(await getIp()));
      } else {
        setComponent(pass());
      }

      setLoading(false);
    };

    setLoading(true);

    restrictIp();
  }, [accountId, setLoading, restrict, pass]);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Spin size="large" tip="Checking your ip address..." />
      </div>
    );
  }

  return component;
};