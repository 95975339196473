import { observable } from 'mobx';
import { AccountUserRoles } from './AccountUser';

export interface AccountProps {
  accountId: string,
  slug: string | null,
  stripeAccountId: string,
  created: string,
  name: string,
  logo: string,
  address: string,
  accentColor: string,
  updated: string,
  currency: string,
  currencies?: string[],
  role: AccountUserRoles
}

export class Account {
  accountId: string = '';
  stripeAccountId: string = '';
  created: string = '';
  currency: string = '';
  currencies: string[] = [];
  role: AccountUserRoles = AccountUserRoles.NONE;

  @observable name: string = '';
  @observable slug: string | null = '';
  @observable logo: string = '';
  @observable address: string = '';
  @observable accentColor: string = '';
  @observable updated: string = '';

  constructor(data: AccountProps) {
    Object.assign(this, data);
  }
}