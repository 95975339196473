const scriptsReg: IIndexable = {};

export function addScript(src: string, timeout = 15000, force = false): Promise<unknown> {
  if (scriptsReg[src] && !force) {
    return scriptsReg[src] === true ? Promise.resolve() : scriptsReg[src];
  }

  const promise = new Promise((resolve, reject) => {
    const scriptEl: HTMLScriptElement = document.createElement('script');
    const timeoutTimer: NodeJS.Timeout = setTimeout(reject, timeout);

    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('src', src);

    scriptEl.onload = () => {
      scriptsReg[src] = true;

      clearTimeout(timeoutTimer);
      resolve();
    };

    scriptEl.onerror = () => {
      scriptsReg[src] = false;

      clearTimeout(timeoutTimer);
      reject();
    };

    document.body.appendChild(scriptEl);
  });

  scriptsReg[src] = promise;

  return promise;
}

const cssReg: IIndexable = {};

export function addCss(src: string, timeout = 15000, force = false): Promise<unknown> {
  if (cssReg[src] && !force) {
    return cssReg[src] === true ? Promise.resolve() : cssReg[src];
  }

  const promise = new Promise((resolve, reject) => {
    const link: HTMLLinkElement = document.createElement('link');
    const timeoutTimer: NodeJS.Timeout = setTimeout(reject, timeout);

    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', src);
    link.onload = function () {
      cssReg[src] = true;

      clearTimeout(timeoutTimer);
      resolve();
    };

    link.onerror = () => {
      cssReg[src] = false;

      clearTimeout(timeoutTimer);
      reject();
    };

    document.getElementsByTagName('head')[0].appendChild(link);
  });

  cssReg[src] = promise;

  return promise;
}