import React from 'react';
import ReactDOM from 'react-dom';
import 'mobx-react-lite/batchingForReactDom'
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { observable } from 'mobx';
import { AuthStore } from 'stores/Auth';
import { AccountsStore } from 'stores/Accounts';
import { isAutoload } from 'utils/Routes';

const appState = observable({
  loading: true,
  errorOnBoot: false
});

// check initial login
if (isAutoload(window.location)) {
  AuthStore
    .checkAuth()
    .then((errorCode) => {
      if (errorCode === 500) {
        appState.errorOnBoot = true;
      } else if (AuthStore.authenticated) {
        return AccountsStore.fetchAccounts().catch(() => appState.errorOnBoot = true);
      }
    })
    .then(() => appState.loading = false);
} else {
  setTimeout(() => appState.loading = false);
}

ReactDOM.render(
  <App appState={appState} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
