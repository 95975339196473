import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Drawer, Descriptions } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FIProps } from 'models/FI';
import { formatPhoneNumber } from 'utils/Phone';

interface FIDetailsProps {
  accountId: string,
  fi: FIProps,
  children: ({ openDrawer }: { openDrawer: any }) => React.ReactNode,
  onInvoiceCreated?: (data: any) => any
}

export const FIDetails: React.FC<FIDetailsProps> = observer(({ accountId, fi, children }) => {
  const [visible, setVisible] = useState(false);
  const showDrawer = useCallback(() => setVisible(true), [setVisible]);
  const onClose = useCallback(() => setVisible(false), [setVisible]);

  return (
    <>
      {children ? children({ openDrawer: () => setVisible(true) }) : (
        <Button type="primary" onClick={showDrawer}>
          Open
        </Button>
      )}
      <Drawer
        className="repair-order-drawer"
        width={960}
        title={
          <>
            <span>Finance and Insurance Details</span>
            <Button icon={<CloseOutlined />} onClick={() => setVisible(false)} />
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        {visible ? (
          <>
            <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Id">
                {fi.id}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Name">
                {fi.customerName || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Email">
                {fi.customerEmail || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Customer Phone">
                {formatPhoneNumber(fi.customerPhone) || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Sales Date">
                {moment(fi.salesDate).format('LLL')}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Make">
                {fi.vehicleMake || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Model">
                {fi.vehicleModel || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Year">
                {fi.vehicleYear || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Mileage">
                {fi.vehicleMileage || '—'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle ID">
                {fi.vehicleId || '—'}
              </Descriptions.Item>
            </Descriptions>
          </>
        ) : null}
      </Drawer>
    </>
  );
});