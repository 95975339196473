import React from 'react';
import { CompactPicker } from 'react-color';

export const ColorPicker: React.FC<{ value?: string, onChange?: (value: string) => any }> = ({ value, onChange }) => {
  return (
    <CompactPicker
      color={value}
      onChange={({ hex }) => onChange && onChange(hex)}
    />
  );
};