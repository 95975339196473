import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Skeleton, Result, Button, Descriptions, Space, Avatar, Divider, Tag, List, Tooltip, Checkbox } from 'antd';
import { UserOutlined, RetweetOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { AccountInvoiceDetailsModel } from './AccountInvoiceDetailsModel';
import { AccountInvoiceRefundModal } from './AccountInvoiceRefundModal';
import { InvoiceShareButton } from 'components/Invoice/InvoiceShareButton';
import { getFormattedNumber } from 'utils/Currency';
import { getStatusDescriptor } from 'utils/Status';
import { getInvoiceUrl } from 'utils/Routes';
import { copyTextToClipboard } from 'utils/Copy';
import { InvoiceStatus } from 'models/Invoice';
import { AccountCustomerDetailsDrawer } from '../CustomerDetails/AccountCustomerDetailsDrawer';

import './AccountInvoiceDetailsStyle.less';

interface AccountInvoiceDetailsViewProps {
  model: AccountInvoiceDetailsModel;
  inline?: boolean;
  onClose?: () => any;
}

export const AccountInvoiceDetailsView: React.FC<AccountInvoiceDetailsViewProps> = observer((
  {
    model,
    inline,
    onClose
  }
) => {
  if (model.loading) {
    return (
      <Skeleton active/>
    );
  }

  if (!model.invoice) {
    return (
      <Result
        status="error"
        title="Can't Load Invoice"
        subTitle="Sorry, we cannot display this invoice at this time."
        extra={[
          <Button type="primary" key="retry" onClick={model.fetchInvoice}>
            Try Again
          </Button>,
          inline && onClose ? (
            <Button key="close" onClick={onClose}>
              Close
            </Button>
          ) : null
        ]}
      />
    );
  }

  const statusDescriptor = getStatusDescriptor(model.invoice);
  const invoiceUrl = getInvoiceUrl(model.invoice.invoiceId);

  return (
    <>
      <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
        <Descriptions.Item label="Order Number">
          {model.invoice.order}
        </Descriptions.Item>
        <Descriptions.Item label="Customer">
          {model.invoice.customer.name ? (
            <AccountCustomerDetailsDrawer customerId={model.invoice.customer.customerId}
                                          accountId={model.invoice.accountId}>
              {({ openDrawer }) => (
                <Tooltip title="View details" mouseEnterDelay={0.5}>
                  <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                    {model.invoice!.customer.name}
                  </span>
                </Tooltip>
              )}
            </AccountCustomerDetailsDrawer>
          ) : '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          {getFormattedNumber(model.invoice.amount)}
        </Descriptions.Item>
        {model.invoice.tax ? (
          <Descriptions.Item label="Tax">
            {getFormattedNumber(model.invoice.tax)}
          </Descriptions.Item>
        ) : null}
        {model.invoice.tax ? (
          <Descriptions.Item label="Total">
            {getFormattedNumber(model.invoice.amount + model.invoice.tax)}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Currency">
          {model.invoice.currency || '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Service Rep">
          <Space>
            <Avatar size="small" icon={<UserOutlined/>} src={model.invoice.user.picture}/>
            <span>{model.invoice.user.name}</span>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {moment(model.invoice.created).format('LLL')}
        </Descriptions.Item>
        <Descriptions.Item label="Paid Date">
          {model.invoice.paid ? moment(model.invoice.paid).format('LLL') : '—'}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <span style={{ color: statusDescriptor.color }}>
            {statusDescriptor.text}
          </span>
        </Descriptions.Item>
        {model.invoice.billingFrom ? (
          <Descriptions.Item label="Billing From">
            {moment(model.invoice.billingFrom).format('LL')}
          </Descriptions.Item>
        ) : null}
        {model.invoice.billingTo ? (
          <Descriptions.Item label="Billing To">
            {moment(model.invoice.billingTo).format('LL')}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Attempts">
          {model.invoice.attempts}
        </Descriptions.Item>
        {model.invoice.error && model.invoice.status !== InvoiceStatus.COMPLETE ? (
          <Descriptions.Item label="Error" span={2}>
            <span style={{ color: red[5], whiteSpace: 'break-spaces' }}>
              {model.invoice.error}
            </span>
          </Descriptions.Item>
        ) : null}
        {model.invoice.status !== InvoiceStatus.COMPLETE ? (
          <Descriptions.Item label="Customer Invoice Link" span={2}>
            <a
              href={invoiceUrl}
              onClick={(e) => {
                if (!e.ctrlKey) {
                  e.preventDefault();
                }

                copyTextToClipboard(invoiceUrl);
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {invoiceUrl}
            </a>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Share Invoice" span={2}>
          <InvoiceShareButton invoice={model.invoice}/>
        </Descriptions.Item>
      </Descriptions>

      {model.invoice.status === InvoiceStatus.COMPLETE && model.invoiceCharge ? (
        <>
          <Divider/>

          <div className="ant-drawer-title mb-4">Transaction Details</div>

          <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
            <Descriptions.Item label="Card">
              <Tag>**** **** **** {model.invoiceCharge.last4}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              {moment(model.invoiceCharge.created).format('LLL')}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {getFormattedNumber(model.invoiceCharge.amount)}
            </Descriptions.Item>
            <Descriptions.Item label="Refunded">
              {getFormattedNumber(model.invoiceCharge.amountRefunded)}
            </Descriptions.Item>
            {model.invoice.processingFee ? (
              <Descriptions.Item label="Stripe Fee">
                {getFormattedNumber(model.invoice.processingFee)}
              </Descriptions.Item>
            ) : null}
          </Descriptions>

          {model.invoiceCharge.refunds && model.invoiceCharge.refunds.length ? (
            <>
              <Divider/>

              <div className="ant-drawer-title mb-4">Refunds History</div>

              <List
                itemLayout="horizontal"
                dataSource={model.invoiceCharge.refunds}
                pagination={model.invoiceCharge.refunds.length <= 10 ? false : {
                  pageSize: 10
                }}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={(
                        <Tooltip title={item.createdBy} placement="topLeft">
                          <Avatar icon={<UserOutlined/>}/>
                        </Tooltip>
                      )}
                      title={(
                        <>
                          {getFormattedNumber(item.amount)} on {moment(item.created).format('LLL')}
                        </>
                      )}
                      description={<>{item.reason}</>}
                    />
                  </List.Item>
                )}
              />
            </>
          ) : null}

          <Divider/>

          <Button icon={<RetweetOutlined/>} onClick={model.showRefund}>Refund</Button>

          <AccountInvoiceRefundModal model={model}/>
        </>
      ) : null}

      {model.invoice.status !== InvoiceStatus.COMPLETE && model.invoice.customer.stripeCustomerId ? (
        <>
          <Divider/>

          <Button
            icon={<DollarCircleOutlined/>}
            onClick={model.chargeInvoice}
            loading={model.chargeLoading}
          >
            Charge
          </Button>

          <Checkbox
            onChange={(e) => model.emailInvoice = e.target.checked}
            style={{ marginLeft: 12 }}
          >
            Email the Receipt
          </Checkbox>
        </>
      ) : null}
    </>
  );
});