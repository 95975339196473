import { Api } from './Api';
import { InvoiceLinkedItem } from 'models/Invoice';

export interface AddInvoiceProps {
  order: string,
  amount: number,
  currency: string,
  tax?: number,
  customerId?: string,
  billingFrom?: string,
  billingTo?: string,
  linkedItem?: InvoiceLinkedItem | null
}

export interface GetInvoicesFilters {
  statuses?: string[];
  status?: string;
  order?: string;
  createdFrom?: string;
  createdTo?: string;
  paidFrom?: string;
  paidTo?: string;
  amountFrom?: string;
  amountTo?: string;
  userTerm?: string;
  customerTerm?: string;
  customerId?: string;
  orderBy?: string;
  orderDirection?: 0 | 1;
}

export interface GetInvoicesPagination {
  skip: number;
  take: number;
}

export const getInvoices = (accountId: string, pagination?: GetInvoicesPagination, filters?: GetInvoicesFilters) => {
  return Api.get(`/accounts/${accountId}/invoices`, { ...pagination, ...filters });
};

export const addInvoice = (accountId: string, data: AddInvoiceProps) => {
  return Api.post(`/accounts/${accountId}/invoices`, data);
};

export const refundInvoice = (accountId: string, invoiceId: string, data: { amount: number, reason: string }) => {
  return Api.post(`/accounts/${accountId}/invoices/${invoiceId}/refund`, data);
};

export const removeInvoice = (accountId: string, invoiceId: string) => {
  return Api.delete(`/accounts/${accountId}/invoices/${invoiceId}`);
};

export const getInvoice = (accountId: string, invoiceId: string) => {
  return Api.get(`/accounts/${accountId}/invoices/${invoiceId}`);
};

export const getInvoiceCharge = (accountId: string, invoiceId: string) => {
  return Api.get(`/accounts/${accountId}/invoices/${invoiceId}/charge`);
};

export const getPublicInvoice = (invoiceId: string) => {
  return Api.get(`/public/invoices/${invoiceId}`);
};

export const getPublicRepairOrder = (invoiceId: string) => {
  return Api.get(`/public/invoices/${invoiceId}/repair-order`);
};

export const chargeInvoice = (accountId: string, invoiceId: string, sendReceipt: boolean) => {
  return Api.post(`/accounts/${accountId}/invoices/${invoiceId}/charge?sendReceipt=${sendReceipt}`, {});
};