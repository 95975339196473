import bind from 'bind-decorator';
import { Api } from 'services/Api';
import { observable } from 'mobx';

export class AccountReportsModel {
  @observable invoicesLoading: boolean = false;

  accountId: string = '';

  constructor(accountId: string) {
    this.accountId = accountId;
  }

  @bind
  async handlePaidInvoicesDownload(values: any) {
    this.invoicesLoading = true;

    const type = values.type === 'openDates' ? `completed-by-invoice-open-dates` : `completed-by-invoice-paid-dates`;

    const response = await Api.get(`/accounts/${this.accountId}/reports/${type}`, {
      from: values.invoicesFrom.startOf('day').toISOString(),
      to: values.invoicesTo.endOf('day').toISOString(),
      ...(values.currency !== 'Any' ? { currency: values.currency } : null)
    });

    const a = document.createElement('a');

    a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
    a.download = 'paid_invoices.csv';
    a.target = '_blank';

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    this.invoicesLoading = false;
  }
}