import { notification } from 'antd';
import { InvoiceProps } from 'models/Invoice';
import { sendNotification } from 'services/Notification';
import { mailConfirm, mailEnsure } from './MailConfirm';

export const mailInvoice = async (invoice: InvoiceProps, confirm?: boolean) => {
  if (!invoice.customer.email) {
    return mailConfirm(invoice);
  }

  if (confirm) {
    return mailEnsure(invoice);
  }

  const to = invoice.customer.name || 'Customer';

  try {
    await sendNotification(invoice.accountId, invoice.invoiceId, { email: true });

    notification.success({
      message: `Email to ${to} was sent`
    });
  } catch (e) {
  }
};