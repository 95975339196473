import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Button, Col, Pagination, Popconfirm, Row, Skeleton, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, FileSearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { AdminAccountROModel } from './AdminAccountROModel';
import { AdminAccountROModal } from './AdminAccountROModal';
import { RepairOrderProps } from 'models/RepairOrder';
import { RepairOrdersDetails } from 'components/RepairOrders/RepairOrdersDetails';
import { getFormattedNumber } from 'utils/Currency';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';

interface AdminAccountViewProps {
  model: AdminAccountROModel;
}

export const AdminAccountROView: React.FC<AdminAccountViewProps> = observer(({ model }) => {
  const { repairOrders, loading, loaded } = model;

  if (loading && !loaded) {
    return (
      <Skeleton active />
    );
  }

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: '60px'
  }, {
    title: <span>Order&nbsp;Number</span>,
    dataIndex: 'roNumber',
    key: 'roNumber',
    width: '140px',
    sorter: true,
    filteredValue: model.filters.roNumber || null,
    sortOrder: model.sorter.columnKey === 'roNumber' && model.sorter.order,
    render: (roNumber: string, record: RepairOrderProps) => {
      return (
        <RepairOrdersDetails accountId={model.accountId} repairOrder={record}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                {roNumber}
              </span>
            </Tooltip>
          )}
        </RepairOrdersDetails>
      );
    },
    ...getColumnSearchProps('by RO number')
  }, {
    title: 'Customer',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '200px',
    sorter: true,
    filteredValue: model.filters.customerName || null,
    sortOrder: model.sorter.columnKey === 'customerName' && model.sorter.order,
    ...getColumnSearchProps('by customer name')
  }, {
    title: 'Email',
    dataIndex: 'customerEmail',
    key: 'customerEmail',
    width: '200px',
    sorter: true,
    filteredValue: model.filters.customerEmail || null,
    sortOrder: model.sorter.columnKey === 'customerEmail' && model.sorter.order,
    render: (customerEmail: string | null) => customerEmail || '—',
    ...getColumnSearchProps('by customer email')
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '160px',
    render: (status: string) => status || '—'
  }, {
    title: 'Opened',
    dataIndex: 'opened',
    key: 'opened',
    width: '120px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'opened' && model.sorter.order,
    render: (created: any) => {
      return (
        <Tooltip title={created ? moment(created).format('LLL') : ''}>
          <span>
            {created ? moment(created).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
  }, {
    title: 'Closed',
    dataIndex: 'closed',
    key: 'closed',
    width: '120px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'closed' && model.sorter.order,
    render: (closed: any) => {
      return (
        <Tooltip title={closed ? moment(closed).format('LLL') : ''}>
          <span>
            {closed ? moment(closed).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
  }, {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    width: '100px',
    sorter: true,
    sortOrder: model.sorter.columnKey === 'total' && model.sorter.order,
    render: (total: number) => getFormattedNumber(total)
  }, {
    title: 'Internal',
    dataIndex: 'internalStatus',
    key: 'internalStatus',
    width: '160px',
    render: (status: string) => status || '—'
  }, {
    title: <span>Actions</span>,
    key: 'action',
    width: '100px',
    className: 'account-view__actions',
    render: (_text: any, record: RepairOrderProps) => (
      <Space size="small">
        <RepairOrdersDetails accountId={model.accountId} repairOrder={record}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <Button size="small" icon={<FileSearchOutlined />} onClick={openDrawer} />
            </Tooltip>
          )}
        </RepairOrdersDetails>
        <Button size="small" icon={<EditOutlined />} onClick={() => model.handleEdit(record)} />
        <Popconfirm title="Are you sure?" onConfirm={() => model.handleRemoveRO(record.roNumber)}>
          <Tooltip title="Delete" placement="right" mouseEnterDelay={0.5}>
            <Button size="small" icon={<DeleteOutlined />} danger />
          </Tooltip>
        </Popconfirm>
      </Space>
    ),
  }];

  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: any[] = repairOrders.map((repairOrder, i) => ({
    ...repairOrder,
    original: repairOrder,
    '#': currentSkip + i + 1
  }));

  return (
    <>
      <Table
        columns={columns as any}
        size="small"
        className="xsmall"
        dataSource={data}
        pagination={false}
        loading={model.loading}
        rowKey={(record: RepairOrderProps) => record.roNumber}
        onChange={model.tableFiltersChange}
        footer={() => (
          <Row>
            <Col span={12}>
              <Button size="small" type="primary" onClick={model.handleAdd}>
                <PlusOutlined /> Add Repair Order
              </Button>
            </Col>
            <Col className="tr" span={12}>
              <Pagination
                size="small"
                className="account-view__pagination"
                showSizeChanger
                onShowSizeChange={model.takeChange}
                onChange={model.paginationChange}
                defaultPageSize={model.pagintaion.take}
                defaultCurrent={1}
                current={currentPage}
                total={currentTotal}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Col>
          </Row>
        )}
      />

      <AdminAccountROModal model={model} />
    </>
  );
});