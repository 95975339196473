import React, { useState } from 'react';
import { AuthStore } from 'stores/Auth';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UploadImage } from 'components/Upload/UploadImage';
import { updateUser } from 'services/User';
import { useForm } from 'antd/lib/form/util';

export const ProfileBasicInfo: React.FC = () => {
  const [form] = useForm();
  const user = AuthStore.currentUser;
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (values: any) => {
    setLoading(true);

    try {
      const response = await updateUser(user.userId, {
        name: values.name,
        picture: values.avatar
      });
      const { data } = response.data;

      AuthStore.setName(data.name);
      AuthStore.setAvatar(data.picture);

      notification.success({
        message: 'Profile was successfully updated!'
      });
    } catch (e) {
    }

    setLoading(false);
  };

  return (
    <Form
      form={form}
      className="profile-form"
      layout="vertical"
      initialValues={{
        name: user.name,
        avatar: user.picture
      }}
      onFinish={handleUpdate}
      style={{ maxWidth: 300 }}
    >
      <Form.Item
        label="Full Name"
        name="name"
        rules={[
          { required: true, message: 'Please input your full name' },
          { min: 4, message: 'Name should be at least 4 characters long' }
        ]}
      >
        <Input placeholder="Full Name" prefix={<UserOutlined />} />
      </Form.Item>

      <Form.Item label="Email">
        {user.email}
      </Form.Item>

      <Form.Item
        label="Your Avatar"
        name="avatar"
      >
        <UploadImage
          cropProps={{
            minWidth: 150,
            minHeight: 150,
            resizeWidth: 150,
            resizeHeight: 150
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};