import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Skeleton, Result, Button, Table, Row, Col, Space, Popconfirm, Pagination, Radio, Tooltip, Typography } from 'antd';
import { DeleteOutlined, FileProtectOutlined, FileSearchOutlined, ClockCircleOutlined, CheckOutlined, CalendarOutlined, SyncOutlined, ClearOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { AccountViewModel } from './AccountViewModel';
import { AccountInvoiceModal } from './AccountInvoiceModal';
import { InvoiceProps, InvoiceStatus } from 'models/Invoice';
import { EventListener } from 'components/EventListener';
import { getColumnSearchProps } from 'components/Filters/TableSearchFilter';
import { getColumnRangeSearchProps } from 'components/Filters/TableRangeSearchFilter';
import { getColumnDatesProps } from 'components/Filters/TableDatesFilter';
import { RepairOrdersView } from 'components/RepairOrders/RepairOrdersView';
import { InvoiceShareButton } from 'components/Invoice/InvoiceShareButton';
import { getFormattedNumber } from 'utils/Currency';
import { AccountInvoiceDetailsDrawer } from '../InvoiceDetails/AccountInvoiceDetailsDrawer';
import { AccountCustomerDetailsDrawer } from '../CustomerDetails/AccountCustomerDetailsDrawer';
import { getStatusDescriptor } from 'utils/Status';
import { EVENTS } from '~/enums/common';

import './AccountViewStyle.less';
import { CustomerProps } from 'models/Customer';

interface AccountViewViewProps {
  model: AccountViewModel;
  inline?: boolean;
}

export const AccountViewView: React.FC<AccountViewViewProps> = observer(({ model, inline }) => {
  const { account, loading } = model;

  if (loading) {
    return (
      <Skeleton active />
    );
  }

  if (!account) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, we cannot display this page."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Try Again
          </Button>
        }
      />
    );
  }

  const columns = [{
    title: '#',
    dataIndex: '#',
    key: '#',
    width: inline ? '30px' : '60px',
    fixed: 'left'
  }, {
    title: <span>Order&nbsp;Number</span>,
    dataIndex: 'order',
    key: 'order',
    width: inline ? '135px' : '180px',
    sorter: true,
    fixed: 'left',
    filteredValue: model.filters.order || null,
    sortOrder: model.sorter.columnKey === 'order' && model.sorter.order,
    render: (order: string, record: InvoiceProps) => {
      return (
        <AccountInvoiceDetailsDrawer invoiceId={record.invoiceId} accountId={model.accountId}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                {order}
              </span>
            </Tooltip>
          )}
        </AccountInvoiceDetailsDrawer>
      )
    },
    ...getColumnSearchProps('by order number')
  }, !model.customerId && {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    width: '130px',
    sorter: true,
    filteredValue: model.filters.customer || null,
    sortOrder: model.sorter.columnKey === 'customer' && model.sorter.order,
    render: (_: any, record: InvoiceProps) => {
      if (record.customer.customerId) {
        return (
          <AccountCustomerDetailsDrawer customerId={record.customer.customerId} accountId={model.accountId}>
            {({ openDrawer }) => (
              <Tooltip title="View details" mouseEnterDelay={0.5}>
                <span style={{ cursor: 'pointer' }} onClick={openDrawer}>
                  {record.customer.name}
                </span>
              </Tooltip>
            )}
          </AccountCustomerDetailsDrawer>
        );
      }

      return '—';
    },
    ...getColumnSearchProps('by customer name')
  }, !inline && {
    title: 'Company',
    dataIndex: 'customer',
    key: 'company',
    width: '100px',
    render: (customer: CustomerProps, _: InvoiceProps) => {
      return customer.company || '—';
    }
  }, {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: inline ? '95px' : '130px',
    sorter: true,
    filteredValue: model.filters.amount || null,
    sortOrder: model.sorter.columnKey === 'amount' && model.sorter.order,
    render: (amount: number) => getFormattedNumber(amount, account.currency),
    ...getColumnRangeSearchProps('by min amount', 'by max amount', 'number')
  }, {
    title: 'Tax',
    dataIndex: 'tax',
    key: 'tax',
    width: '65px',
    sorter: true,
    filteredValue: model.filters.tax || null,
    sortOrder: model.sorter.columnKey === 'tax' && model.sorter.order,
    render: (tax: number) => getFormattedNumber(tax, account.currency)
  }, !inline && {
    title: <span>Service&nbsp;Rep</span>,
    dataIndex: 'user',
    key: 'user',
    sorter: true,
    filteredValue: model.filters.user || null,
    sortOrder: model.sorter.columnKey === 'user' && model.sorter.order,
    render: (_: any, record: InvoiceProps) => {
      return (
        <span>
          {record.user.name ? record.user.name : '—'}
        </span>
      );
    },
    ...getColumnSearchProps('by service rep')
  }, !inline && {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: '120px',
    sorter: true,
    filteredValue: model.filters.created || null,
    sortOrder: model.sorter.columnKey === 'created' && model.sorter.order,
    render: (created: any) => {
      return (
        <Tooltip title={created ? moment(created).format('LLL') : ''}>
          <span>
            {created ? moment(created).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
    ...getColumnDatesProps()
  }, {
    title: 'Paid',
    dataIndex: 'paid',
    key: 'paid',
    width: inline ? '90px' : '120px',
    sorter: true,
    filteredValue: model.filters.paid || null,
    sortOrder: model.sorter.columnKey === 'paid' && model.sorter.order,
    render: (paid: any) => {
      return (
        <Tooltip title={paid ? moment(paid).format('LLL') : ''}>
          <span>
            {paid ? moment(paid).format('ll') : '—'}
          </span>
        </Tooltip>
      );
    },
    ...getColumnDatesProps()
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: inline ? '80px' : '120px',
    filters: [
      { text: 'Pending', value: InvoiceStatus.PENDING },
      { text: 'Completed', value: InvoiceStatus.COMPLETE },
      { text: 'Deleted', value: InvoiceStatus.DELETED },
    ],
    filterMultiple: false,
    sorter: true,
    filteredValue: model.filters.status || null,
    sortOrder: model.sorter.columnKey === 'status' && model.sorter.order,
    fixed: inline ? 'right' : '',
    render: (status: InvoiceStatus, record: InvoiceProps) => {
      let config = getStatusDescriptor(record);

      return (
        <Tooltip title={config.tooltip}>
          <span style={{ color: config.color }}>{config.text}</span>
        </Tooltip>
      );
    }
  }, !inline && {
    title: 'Action',
    key: 'action',
    width: '105px',
    className: 'account-view__actions',
    fixed: 'right',
    render: (_text: any, record: InvoiceProps) => (
      <Space size="small">
        <InvoiceShareButton size="small" invoice={record} />
        <AccountInvoiceDetailsDrawer invoiceId={record.invoiceId} accountId={model.accountId}>
          {({ openDrawer }) => (
            <Tooltip title="View details" mouseEnterDelay={0.5}>
              <Button size="small" icon={<FileSearchOutlined />} onClick={openDrawer} />
            </Tooltip>
          )}
        </AccountInvoiceDetailsDrawer>
        <Popconfirm disabled={record.status !== InvoiceStatus.PENDING} title="Are you sure?" onConfirm={() => model.handleInvoiceRemove(record.invoiceId)}>
          <Tooltip title="Delete" placement="right" mouseEnterDelay={0.5}>
            <Button size="small" disabled={record.status !== InvoiceStatus.PENDING} icon={<DeleteOutlined />} danger />
          </Tooltip>
        </Popconfirm>
      </Space>
    ),
  }].filter(Boolean);
  const hasOrders = !inline && model.accountSettings?.repairOrdersEnabled();
  const hasBank = Boolean(account.stripeAccountId);
  const currentPage = model.pagintaion.page;
  const currentSkip = model.pagintaion.skip;
  const currentTotal = model.pagintaion.total;
  const data: any[] = model.invoices.map((invoice, i) => ({
    ...invoice,
    original: invoice,
    '#': currentSkip + i + 1
  }));

  return (
    <EventListener events={[EVENTS.CUSTOMER_UPDATED, EVENTS.INVOICE_CREATED, EVENTS.INVOICE_UPDATED, EVENTS.INVOICE_REMOVED]} callback={model.refreshTable}>
      <div className="account-view">
        {hasOrders && (
          <RepairOrdersView
            accountId={model.accountId}
            filters={model.repairOrdersFilters}
            defaultTake={hasBank ? 5 : 20}
          />
        )}

        {hasOrders && hasBank && (
          <Typography.Title level={4}>Invoices</Typography.Title>
        )}

        {!inline && hasBank && (
          <div className="account-view__toolbar">
            <Space>
              {model.showCreateButton && (
                <Button
                  size="small"
                  type="primary"
                  className="account-view__title-create"
                  onClick={model.handleAdd}
                >
                  <FileProtectOutlined /> Create Invoice
                </Button>
              )}
              <Popconfirm title="Are you sure?" onConfirm={model.resetTable}>
                <Tooltip title="Reset all filters" placement="bottom">
                  <Button size="small" type="link" icon={<ClearOutlined />} />
                </Tooltip>
              </Popconfirm>
              <Tooltip title="Refresh table data" placement="bottom">
                <Button size="small" type="link" icon={<SyncOutlined />} onClick={model.refreshTable} />
              </Tooltip>
              <Radio.Group size="small" value={model.instantFilter} onChange={model.instantFilterChange}>
                <Radio.Button value="this_month"><CalendarOutlined /> This Month</Radio.Button>
                <Radio.Button value="today"><CalendarOutlined /> Today</Radio.Button>
                <Radio.Button value="pending"><ClockCircleOutlined /> Pending</Radio.Button>
                <Radio.Button value="completed"><CheckOutlined /> Completed</Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        )}

        {hasBank && (
          <Table
            columns={columns as any}
            scroll={inline ? {} : { x: 1200 }}
            size="small"
            className={inline ? '' : 'xsmall'}
            dataSource={data}
            pagination={false}
            loading={model.tableLoading}
            rowKey={(record: InvoiceProps) => record.invoiceId}
            onChange={model.tableFiltersChange}
            footer={() => (
              <Row>
                <Col className="tr" span={24}>
                  <Pagination
                    size="small"
                    className="account-view__pagination"
                    showSizeChanger
                    onShowSizeChange={model.takeChange}
                    onChange={model.paginationChange}
                    defaultCurrent={1}
                    current={currentPage}
                    total={currentTotal}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  />
                </Col>
              </Row>
            )}
          />
        )}

        {!hasBank && !hasOrders && (
          <Result
            icon={<IssuesCloseOutlined />}
            status="info"
            title="Almost There"
            subTitle="Please complete account setup to receive payments from customers."
            extra={[
              <Button
                onClick={model.handleCompleteSetup}
                type="primary"
                key="connect"
              >
                Complete Account Setup
              </Button>
            ]}
          />
        )}

        <AccountInvoiceModal model={model} />
      </div>
    </EventListener>
  );
});