export const formatPhoneNumber = (phone: string | null): string => {
  if (!phone) {
    return '';
  }

  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return cleaned;
}

export const rawPhoneNumber = (phone: string | null): string => {
  if (!phone) {
    return '';
  }

  return phone.replace(/\D/g, '').slice(-10);
};