import React from 'react';

import { LoginView } from 'components/Login/LoginView';
import { LoginModel } from 'components/Login/LoginModel';

export const LoginRoute = () => {
  const model = new LoginModel();

  return (
    <LoginView model={model} />
  );
};