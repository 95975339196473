import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { changePassword } from 'services/User';
import { useForm } from 'antd/lib/form/util';

export const ProfilePassword: React.FC = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const handleUpdate = async (values: any) => {
    setLoading(true);

    try {
      await changePassword({
        password: values.password,
        newPassword: values.newPassword
      });

      form.resetFields(['password', 'newPassword']);

      notification.success({
        message: 'Password was successfully changed!'
      });
    } catch (e) {
    }

    setLoading(false);
  };

  return (
    <Form
      form={form}
      className="profile-form"
      layout="vertical"
      onFinish={handleUpdate}
      style={{ maxWidth: 300 }}
    >
      <Form.Item
        label="Old Password"
        name="password"
        rules={[
          { required: true, message: 'Please input your password' },
          { min: 6, message: 'Password should be at least 6 characters long' }
        ]}
      >
        <Input.Password placeholder="Password" prefix={<LockOutlined />} />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          { required: true, message: 'Please input your new password' },
          { min: 6, message: 'Password should be at least 6 characters long' }
        ]}
      >
        <Input.Password placeholder="New Password" prefix={<LockOutlined />} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};