import React from 'react';
import { Button, Result } from 'antd';

import { AccountCreateView } from 'components/Account/Create/AccountCreateView';
import { AccountCreateModel } from 'components/Account/Create/AccountCreateModel';
import { Account } from 'models/Account';
import { GlobalStore } from 'stores/Global';

export const AccountBankingRoute: React.FC<{ account: Account }> = ({ account }) => {
  if (!account.stripeAccountId) {
    const model = new AccountCreateModel({
      step: 1,
      account
    });

    return (
      <AccountCreateView model={model} />
    );
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, bank account was already set up."
      extra={
        <Button type="primary" onClick={() => GlobalStore.navigate('/')}>
          Back Home
        </Button>
      }
    />
  );
};