import bind from 'bind-decorator';
import jwt from 'jwt-decode';
import { observable } from 'mobx';
import { signup } from 'services/User';
import { AuthStore } from 'stores/Auth';
import { notification } from 'antd';

export class SignupModel {
  @observable loading: boolean = false;

  token: string = '';
  tokenPayload: any = {};

  constructor(token?: string) {
    if (token) {
      this.token = token;

      try {
        this.tokenPayload = jwt(token);
      } catch (e) {
      }
    }
  }

  @bind
  initialValues(): any {
    let values: any = {};

    if (this.tokenPayload) {
      if (this.tokenPayload['Name']) {
        values.name = this.tokenPayload['Name'];
      }

      if (this.tokenPayload['Email']) {
        values.email = this.tokenPayload['Email'];
      }
    }

    return values;
  }

  @bind
  async finishSignup(values: any) {
    this.loading = true;

    const { email, password, name } = values;

    await signup({ name, email, password })
      .then(() => notification.info({
        message: `We've sent an email to ${email}, please verify your email address.`
      }))
      .then(() => AuthStore.login({ email, password }))
      .catch(() => '');

    this.loading = false;
  }
}