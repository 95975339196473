import bind from 'bind-decorator';
import { observable } from 'mobx';
import { resetPassword } from 'services/User';
import { notification } from 'antd';
import { AuthStore } from 'stores/Auth';

export class ResetModel {
  @observable loading: boolean = false;

  token: string = '';

  constructor(token: string) {
    this.token = token;
  }

  @bind
  async finishReset(values: any) {
    this.loading = true;

    const { password } = values;

    await resetPassword(this.token, { password })
      .then(() => {
        notification.success({
          message: 'Password has been reset!',
          description: 'Sign-in now with new password'
        });

        AuthStore.logout();
      })
      .catch(() => notification.error({
        message: 'Failed to reset your password',
        description: 'Something went wrong, please try again later'
      }));

    this.loading = false;
  }
}