import { gold, grey, red } from '@ant-design/colors';
import { InvoiceStatus, InvoiceProps } from 'models/Invoice';

interface StatusDescriptor {
  color: string,
  text: string,
  tooltip: string
}

export const getStatusDescriptor = (invoice: InvoiceProps) => {
  const status = invoice.status;
  const error = invoice.error;

  let config: StatusDescriptor = {
    color: grey[7],
    text: status,
    tooltip: ''
  };

  if (status === InvoiceStatus.PENDING) {
    config = {
      ...config,
      color: gold[5]
    };

    if (error) {
      config = {
        ...config,
        text: 'Declined',
        color: red[5]
      };
    }
  }

  if (status === InvoiceStatus.DELETED) {
    config = {
      ...config,
      color: grey[2]
    };
  }

  if (status === InvoiceStatus.COMPLETE) {
    config = {
      ...config,
      text: invoice.paid ? 'Paid' : status,
      color: '#96c94e'
    };
  }

  return config;
}