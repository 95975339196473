import React from 'react';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const getColumnRangeSearchProps = (minSuffix: string, maxSuffix: string, type: string = 'text', handleSearch?: (minTerm: string, maxTerm: string) => any, handleReset?: () => any) => {
  let searchInput: any = null;

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          type={type}
          ref={(node) => searchInput = node}
          placeholder={`Search ${minSuffix}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1]] : ['', selectedKeys[1]])}
          onPressEnter={() => {
            confirm();

            handleSearch && handleSearch(selectedKeys[0], selectedKeys[1]);
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Input
          type={type}
          placeholder={`Search ${maxSuffix}`}
          value={selectedKeys[1]}
          onChange={e => setSelectedKeys(e.target.value ? [selectedKeys[0], e.target.value] : [selectedKeys[0], ''])}
          onPressEnter={() => {
            confirm();

            handleSearch && handleSearch(selectedKeys[0], selectedKeys[1]);
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();

              handleSearch && handleSearch(selectedKeys[0], selectedKeys[1]);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();

              handleReset && handleReset();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#96c94e' : undefined }} />,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    }
  }
};