import React, { useEffect } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { AccountCreateView } from 'components/Account/Create/AccountCreateView';
import { AccountCreateModel } from 'components/Account/Create/AccountCreateModel';
import { AccountViewView } from 'components/Account/View/AccountViewView';
import { AccountViewModel } from 'components/Account/View/AccountViewModel';
import { Account } from 'models/Account';
import { AccountUserRoles } from 'models/AccountUser';

const AccountView: React.FC<{ accountId: string }> = ({ accountId }) => {
  const model = new AccountViewModel(accountId);

  useEffect(() => { model.fetchAccount() }, [model]);

  return (
    <AccountViewView model={model} />
  );
};

export const AccountViewRoute: React.FC<{ account: Account }> = ({ account }) => {
  const { search } = useLocation();

  if (!account.stripeAccountId) {
    const { code, state, complete }: any = qs.parse(search);

    if ((code && state) || complete || account.role === AccountUserRoles.FINANCE) {
      const model = new AccountCreateModel({
        step: 1,
        code,
        state,
        account
      });

      return (
        <AccountCreateView model={model} />
      );
    }
  }

  return (
    <AccountView accountId={account.accountId} />
  );
};