import React from 'react';

import { AdminAccountView } from 'components/Admin/Account/AdminAccountView';
import { AdminAccountModel } from 'components/Admin/Account/AdminAccountModel';
import { useRouteMatch } from 'react-router-dom';


export const AdminAccountRoute: React.FC = () => {
  const { params }: { params: { accountId: string } } = useRouteMatch();

  const model = new AdminAccountModel(params.accountId);

  return (
    <AdminAccountView model={model} />
  );
};