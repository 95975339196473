import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Row, Typography, Alert, Spin } from 'antd';
import { VerifyModel } from './VerifyModel';

import './VerifyStyle.less'

interface VerifyViewProps {
  model: VerifyModel;
}

const { Title } = Typography;

export const VerifyView: React.FC<VerifyViewProps> = observer(({ model }) => {
  useEffect(() => {
    model.verify();
  }, [model]);

  let content = null;

  if (model.loading) {
    content = (
      <div className="tc">
        <Spin size="large" />
      </div>
    );
  } else if (model.verifySuccess) {
    content = (
      <Alert
        message={(
          <div>We successfully verified your email. Got to <Link to="/">home page</Link></div>
        )}
        type="success"
      />
    );
  } else {
    content = (
      <Alert
        message={(
          <div>
            Failed to verify given email.
            You can <a href="/" onClick={(e) => { e.preventDefault(); model.verify() }}>try it again</a>
          </div>
        )}
        type="error"
      />
    );
  }

  return (
    <Row justify="center" align="middle" className="verify-page">
      <div className="verify-page__form box">
        <div className="tc mb-5">
          <a href="/" className="brand">md</a>
          <Title level={4} className="mb-0 mt-3">Email verification</Title>
        </div>
        {content}
      </div>
    </Row>
  );
});