import { Api } from './Api';

interface EmailPreferencesPayloadFlags {
  paymentReceived?: boolean
}

interface EmailPreferencesPayload extends EmailPreferencesPayloadFlags {
  accountId: string
}

const config = { authorizationRequest: true };

export const me = () => {
  return Api.get('/users/me', {}, config);
};

export const updateUser = (userId: string, data: { name?: string, picture?: string }) => {
  return Api.put(`/users/${userId}`, data, config);
};

export const signin = (data: { email: string, password: string }) => {
  return Api.post('/users/sign-in', data, config);
};

export const signup = (data: { name: string, email: string, password: string }) => {
  return Api.post('/users/sign-up', data, config);
};

export const sendResetPassword = (data: { email: string }) => {
  return Api.post('/users/send-password-reset', data, config);
};

export const resetPassword = (token: string, data: { password: string }) => {
  return Api.put(`/users/reset-password/${token}`, data, config);
};

export const changePassword = (data: { password: string, newPassword: string }) => {
  return Api.put('/users/change-password', data, config);
};

export const confirmEmail = (token: string) => {
  return Api.put(`/users/confirm-email/${token}`, {}, config);
};

export const getEmailPreferences = (token?: string) => {
  return Api.get(`/users/email-preferences`, { token }, { errorsHandled: [401] });
};

export const setEmailPreferences = (payload: EmailPreferencesPayload, token?: string) => {
  return Api.put(`/users/email-preferences`, payload, { params: { token }, errorsHandled: [401] });
};