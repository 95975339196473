export const getFormattedNumber = (number: number, currency?: string): string => {
  const num = String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (num[0] === '-') {
    return '($' + num.substring(1) + ')';
  }

  return '$' + num;
};

export const formatCurrency = (amount: number, currency?: string): string => {
  const sym = currency === 'CAD' ? 'C$': '$';

  return `${sym}${amount.toFixed(2)}`;
};

export const getCurrencySymbol = (symbol: string = 'USD'): string => {
  const currencyPart = new Intl.NumberFormat('en-US', { style: 'currency', currency: symbol })
    .formatToParts(1)
    .find(({ type }) => type = 'currency');

  return currencyPart ? currencyPart.value : '$';
};