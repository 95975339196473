import { Api } from './Api';

export interface CardProps {
  number: string,
  name: string,
  expirationYear: string,
  expirationMonth: string,
  cvc: string
}

export const payWithCard = (invoiceId: string, card: CardProps) => {
  return Api.post(`/public/invoices/${invoiceId}/payments`, card);
}