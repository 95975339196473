import { Api } from './Api';

export interface CustomersPagination {
  term?: string,
  skip?: number,
  take?: number
}

export interface CustomerProps {
  name: string,
  email: string,
  phoneNumber: string,
  company?: string,
  address?: string,
  extraEmails?: string,
  currency?: string,
  notes?: string
}

export const getCustomers = (accountId: string, pagination?: CustomersPagination) => {
  return Api.get(`/accounts/${accountId}/customers`, pagination);
};

export const addCustomer = (accountId: string, data: CustomerProps) => {
  return Api.post(`/accounts/${accountId}/customers`, data);
};

export const upsertCustomer = (accountId: string, data: CustomerProps) => {
  return Api.put(`/accounts/${accountId}/customers`, data);
};

export const getCustomer = (accountId: string, customerId: string) => {
  return Api.get(`/accounts/${accountId}/customers/${customerId}`);
};

export const editCustomer = (accountId: string, customerId: string, data: CustomerProps) => {
  return Api.put(`/accounts/${accountId}/customers/${customerId}`, data);
};

export const removeCustomer = (accountId: string, customerId: string) => {
  return Api.delete(`/accounts/${accountId}/customers/${customerId}`);
};