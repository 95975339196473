import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Form, Input, Radio, Space, Typography } from 'antd';
import { EditOutlined, HomeOutlined } from '@ant-design/icons';
import { getValidator, UploadImage } from 'components/Upload/UploadImage';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { AccountEditModel, CURRENCY_OPTIONS } from './AccountEditModel';
import { slugify } from 'utils/Slugify';

import './AccountEditStyle.less'

interface AccountEditViewProps {
  model: AccountEditModel;
}

export const AccountEditView: React.FC<AccountEditViewProps> = observer(({ model }) => {
  const [form] = Form.useForm();

  return (
    <div className="account-edit">
      <Typography.Title level={2} className="tc">Edit Company Details</Typography.Title>
      <div className="account-create__steps-content">
        <Form
          form={form}
          className="account-create__form"
          layout="vertical"
          initialValues={{
            name: model.name,
            logo: model.logo,
            slug: model.slug || slugify(model.name),
            address: model.address,
            accentColor: model.accentColor || '#96c94e',
            currencyOption: model.currencyOption || CURRENCY_OPTIONS.MY_BANK,
            currencies: model.currencies || []
          }}
          onFinish={model.finishEdit}
          onChange={(_) => {
            const currencyOption = form.getFieldValue('currencyOption');

            if (model.currencyOption !== currencyOption) {
              model.currencyOption = currencyOption;

              if (currencyOption === CURRENCY_OPTIONS.CUSTOM) {
                form.setFieldsValue({
                  currencies: ['CAD', 'USD']
                });
              } else {
                form.setFieldsValue({
                  currencies: []
                });
              }
            }
          }}
        >
          <Form.Item
            label="Company Name"
            name="name"
            rules={[
              { required: true, message: 'Please input company name' },
              { min: 4, message: 'Company name should be at least 4 characters long' }
            ]}
          >
            <Input placeholder="Company Name" prefix={<EditOutlined/>}/>
          </Form.Item>

          <Form.Item
            label="Company Url"
            name="slug"
            rules={[
              { required: true, message: 'Please input company url' },
              { min: 5, message: 'Company url should be at least 4 characters long' },
              { max: 25, message: 'Company url should be no more than 25 characters long' },
            ]}
          >
            <Input addonBefore={`${process.env.REACT_APP_ORIGIN_URL}/account/`} placeholder="Company Url"
                   value={model.slug}/>
          </Form.Item>

          <Form.Item
            label="Billing Address"
            name="address"
            rules={[
              { required: true, message: 'Please input billing address' },
              { min: 4, message: 'Billing address should be at least 4 characters long' }
            ]}
          >
            <Input placeholder="Billing Address" prefix={<HomeOutlined/>}/>
          </Form.Item>

          <Form.Item name="currencyOption">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={1}>Use My Bank's Currencies</Radio>
                <Radio value={2}>Use Specific Currencies</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          {model.currencyOption === CURRENCY_OPTIONS.CUSTOM ? (
            <Form.Item name="currencies" style={{ marginTop: -12 }}>
              <Checkbox.Group options={[{
                label: 'Canadian Dollar (CAD)',
                value: 'CAD'
              }, {
                label: 'United States Dollar (USD)',
                value: 'USD'
              }]}/>
            </Form.Item>
          ) : null}

          <Form.Item
            className="account-create__logo-upload"
            label="Company Logo"
            name="logo"
            rules={[{ validator: getValidator() }]}
            required
          >
            <UploadImage
              cropProps={{
                minWidth: 300,
                minHeight: 300
              }}
            />
          </Form.Item>

          <Form.Item
            label="Accent Color"
            name="accentColor"
            required
          >
            <ColorPicker/>
          </Form.Item>
        </Form>
      </div>
      <div className="account-create__steps-action">
        <Button loading={model.loading} type="primary" onClick={() => form.submit()}>
          Done
        </Button>
      </div>
    </div>
  )
});