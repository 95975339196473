import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Typography } from 'antd';
import { MailOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { SignupModel } from './SignupModel';

import './SignupStyle.less'

interface SignupViewProps {
  model: SignupModel;
}

const { Title, Text } = Typography;

export const SignupView: React.FC<SignupViewProps> = observer(({ model }) => {
  const initialValues = model.initialValues();

  return (
    <Row justify="center" align="middle" className="signup-page">
      <Form
        className="signup-page__form box"
        layout="vertical"
        initialValues={initialValues}
        onFinish={model.finishSignup}
      >
        <div className="tc mb-5">
          <a href="/" className="brand">md</a>
          <Title level={4} className="mb-0 mt-3">Sign-up</Title>
          <Text type="secondary">create a new account</Text>
        </div>

        <Form.Item
          label="Full Name"
          name="name"
          rules={[
            { required: true, message: 'Please input your full name' },
            { min: 4, message: 'Name should be at least 4 characters long' }
          ]}
        >
          <Input placeholder="Full Name" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'Please enter correct email' }
          ]}
        >
          <Input
            disabled={Boolean(initialValues.email)}
            placeholder="Email"
            prefix={<MailOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input your password' },
            { min: 6, message: 'Password should be at least 6 characters long' }
          ]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          label="Confirm password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Passwords do not match');
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-3"
            loading={model.loading}
            block
          >
            Sign-up
          </Button>
        </Form.Item>

        <div className="tc">
          <Text type="secondary">
            <span>Already have an account?</span> <Link to="/sign-in">Sign-in now!</Link>
          </Text>
        </div>
      </Form>
    </Row>

  );
});