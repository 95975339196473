import { observable } from 'mobx';

export interface UserProps {
  email?: string;
  emailVerified?: boolean;
  name?: string;
  userId?: string;
  picture?: string;
}

export class User {
  @observable email: string = '';
  @observable emailVerified: boolean = false;
  @observable name: string = '';
  @observable picture: string = '';
  @observable userId: string = '';

  @observable authorized: boolean = false;

  constructor(data: UserProps) {
    Object.assign(this, data);
  }
}