import React from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Layout, Result, Button } from 'antd';

import { PageLoading } from 'components/PageLoading';
import { AppMenu } from 'components/AppMenu';
import { HomeRoute } from './routes/HomeRoute';
import { PrivateRoute } from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';
import { PublicOnlyRoute } from './routes/PublicOnlyRoute';
import { AdminRoute } from './routes/AdminRoute';
import { AccountRoute } from './routes/AccountRoute';
import { DefaultRoute } from './routes/DeafultRoute';
import { NotFoundRoute } from './routes/NotFoundRoute';
import { LoginRoute } from './routes/LoginRoute';
import { SignupRoute } from './routes/SignupRoute';
import { ForgotRoute } from './routes/ForgotRoute';
import { VerifyRoute } from './routes/VerifyRoute';
import { ResetRoute } from './routes/ResetRoute';
import { ProfileRoute } from './routes/ProfileRoute';
import { AccountCreateRoute } from './routes/AccountCreateRoute';
import { AccountEditRoute } from './routes/AccountEditRoute';
import { AccountViewRoute } from './routes/AccountViewRoute';
import { AccountBankingRoute } from './routes/AccountBankingRoute';
import { AccountStripeDashboardRoute } from './routes/AccountStripeDashboardRoute';
import { AccountCustomersRoute } from './routes/AccountCustomersRoute';
import { AccountReportsRoute } from './routes/AccountReportsRoute';
import { AccountAccessRoute } from './routes/AccountAccessRoute';
import { OnboardingRoute } from './routes/OnboardingRoute';
import { InvoiceRoute } from './routes/InvoiceRoute';
import { EmailPreferencesRoute } from './routes/EmailPreferencesRoute';
import { AdminAccountRoute } from './routes/AdminAccountRoute';
import { AdminAccountsRoute } from './routes/AdminAccountsRoute';
import { GlobalStore } from './stores/Global';

const history = createBrowserHistory();

GlobalStore.setHistory(history);

interface AppProps {
  appState: any;
}

const AppWrapper: React.FC<AppProps> = observer(({ children, appState }) => {
  const { Header, Content, Footer } = Layout;
  const { pathname } = useLocation();
  const fullscreenPages = [
    '/sign-in',
    '/sign-up',
    '/forgot',
    '/accept-invitation',
    '/confirm-email',
    '/reset-password',
  ];
  const cleanPages = [
    '/invoice/',
    '/secure/',
  ];
  const footer = <Footer className="tc">© {new Date().getFullYear()} Mobileinstein Software Inc.</Footer>;
  const fullscreenLayout = (children: React.ReactElement) => (
    <Layout className="layout layout_fullscreen">
      <Content style={{ padding: '0 50px' }} className="ant-layout-content_fullscreen">
        {children}
      </Content>
      {footer}
    </Layout>
  );

  if (appState.loading) {
    return fullscreenLayout(
      <PageLoading tip="Wait a moment while we load your app" />
    );
  }

  if (appState.errorOnBoot) {
    return fullscreenLayout(
      <Result
        status="500"
        title="500"
        subTitle="Sorry, we cannot display this page."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Refresh This Page
          </Button>
        }
      />
    );
  }

  if (fullscreenPages.some((p) => pathname.indexOf(p) === 0)) {
    return fullscreenLayout(
      <div className="site-layout-content site-layout-content_fullscreen">
        {children}
      </div>
    );
  } else if (cleanPages.some((p) => pathname.indexOf(p) === 0)) {
    return children as any;
  } else {
    return (
      <Layout className="layout">
        <Header>
          <AppMenu />
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <div className="site-layout-content">
            {children}
          </div>
        </Content>
        {footer}
      </Layout>
    );
  }
})

const App: React.FC<AppProps> = ({ appState }) => {
  return (
    <Router history={history}>
      <AppWrapper appState={appState}>
        <Switch>
          <HomeRoute exact path="/" component={DefaultRoute} />

          <PublicOnlyRoute path="/sign-in" component={LoginRoute} />
          <PublicOnlyRoute path="/sign-up" exact component={SignupRoute} />
          <PublicOnlyRoute path="/sign-up/:token" component={SignupRoute} />
          <PublicOnlyRoute path="/forgot" component={ForgotRoute} />

          <PublicRoute path="/confirm-email/:token" component={VerifyRoute} />
          <PublicRoute path="/reset-password/:token" component={ResetRoute} />

          <PrivateRoute path="/account/add" component={AccountCreateRoute} />

          <Route path="/account/:accountId">
            <AccountRoute path="/account/:accountId/edit" component={AccountEditRoute} />
            <AccountRoute path="/account/:accountId/customers" component={AccountCustomersRoute} />
            <AccountRoute path="/account/:accountId/reports" component={AccountReportsRoute} />
            <AccountRoute path="/account/:accountId/access" component={AccountAccessRoute} />
            <AccountRoute path="/account/:accountId/banking" component={AccountBankingRoute} />
            <AccountRoute path="/account/:accountId/stripe_dashboard" component={AccountStripeDashboardRoute} />
            <AccountRoute exact path="/account/:accountId" component={AccountViewRoute} />
          </Route>

          <PrivateRoute path="/onboarding" component={OnboardingRoute} />

          <PrivateRoute path="/profile" component={ProfileRoute} />

          <PublicRoute path="/secure/:invoiceId" component={InvoiceRoute} />

          <PublicRoute path="/email-preferences/:token?" component={EmailPreferencesRoute} />

          <AdminRoute path="/admin/accounts/:accountId" component={AdminAccountRoute} />
          <AdminRoute exact path="/admin/accounts" component={AdminAccountsRoute} />

          <PublicRoute component={NotFoundRoute} />
        </Switch>
      </AppWrapper>
    </Router>
  );
}

export default App;
