import React, { useState, useEffect, useCallback } from 'react';
import { Upload, Modal, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { uploadImage, UploadImageProps as ApiUploadImageProps } from 'services/Images';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function getBase64(img: Blob | undefined, callback: (imageUrl: string, immediate?: boolean) => void, cropProps?: UploadImageCropProps) {
  if (!img) {
    return;
  }

  const reader = new FileReader();

  reader.addEventListener('load', () => {
    const imageUrl = reader.result as string;
    const image = new Image();

    image.onload = () => {
      if (cropProps) {
        const { naturalWidth, naturalHeight } = image;

        if (cropProps.minWidth && naturalWidth < cropProps.minWidth) {
          callback('');

          return message.error(`Image should be at least ${cropProps.minWidth} pixels wide`);
        }

        if (cropProps.minHeight && naturalHeight < cropProps.minHeight) {
          callback('');

          return message.error(`Image should be at least ${cropProps.minHeight} pixels high`);
        }

        if (cropProps.minWidth && cropProps.minHeight &&
          cropProps.minWidth === naturalWidth && cropProps.minHeight === naturalHeight) {
          return callback(imageUrl, true);
        }
      }

      callback(imageUrl);
    };

    image.onerror = () => callback(imageUrl);
    image.src = imageUrl;
  });
  reader.readAsDataURL(img);
}

function beforeUpload(file: File) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
}

export const getValidator = () => (rule: any, value: any) => {
  if (!value) {
    return Promise.reject('Please upload image');
  }

  return Promise.resolve();
};

export interface UploadImageProps {
  value?: string;
  onChange?: (value: string) => void;
  cropProps?: UploadImageCropProps
}

export interface UploadImageCropProps {
  minWidth?: number;
  minHeight?: number;
  resizeWidth?: number;
  resizeHeight?: number;
}

export const UploadImage: React.FC<UploadImageProps> = ({ value, cropProps, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageSize, setImageSize] = useState({
    width: 1,
    naturalWidth: 1,
    height: 1,
    naturalHeight: 1
  });
  const [modalVisible, setModalVisible] = useState(false);

  const widthFactor = imageSize.width / imageSize.naturalWidth;
  const heightFactor = imageSize.height / imageSize.naturalHeight;
  const minWidth = cropProps ? (cropProps.minWidth || 100) * widthFactor : 100;
  const minHeight = cropProps ? (cropProps.minHeight || 100) * heightFactor : 100;
  const initialCrop: any = useCallback(() => ({
    x: 0,
    y: 0,
    width: minWidth,
    height: minHeight
  }), [minWidth, minHeight]);
  const [crop, setCrop] = useState(initialCrop());

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const apiUpload = async (imageUrl: string, crop?: any) => {
    let uploadOptions: ApiUploadImageProps = {
      base64Image: imageUrl.split(',')[1],
      crop
    };

    if (cropProps?.resizeWidth) {
      uploadOptions.resize = {
        width: cropProps.resizeWidth
      };

      if (cropProps.resizeHeight) {
        uploadOptions.resize = {
          ...uploadOptions.resize,
          height: cropProps.resizeHeight
        };
      }
    }

    await uploadImage(uploadOptions)
      .then((response) => {
        setImageUrl(response.data.data);

        if (onChange) {
          onChange(response.data.data);
        }
      }).catch(() => {
        setImageUrl('');
        setLoading(false);

        if (onChange) {
          onChange('');
        }
      });
  };
  const handleChange = (info: UploadChangeParam) => {
    if (!info.file.originFileObj) {
      return;
    }

    setLoading(true);

    // Get this url from response in real world.
    getBase64(info.file.originFileObj, async (imageUrl, immediate) => {
      // image sizes match perfectly
      if (immediate) {
        await apiUpload(imageUrl);

        setLoading(false);
        setCrop(initialCrop());

        return;
      }

      setLoading(false);

      if (imageUrl) {
        setImageUrl(imageUrl);
        setModalVisible(true);
      }
    }, cropProps);
  };
  const handleOk = async () => {
    setLoading(true);

    const wm = imageSize.naturalWidth / imageSize.width;
    const hm = imageSize.naturalHeight / imageSize.height;

    await apiUpload(imageUrl, {
      x: Math.round(crop.x * wm),
      y: Math.round(crop.y * hm),
      width: Math.round(crop.width * wm),
      height: Math.round(crop.height * hm)
    });

    setModalVisible(false);
    setTimeout(() => setCrop(initialCrop()), 200);
  };
  const handleCancel = () => {
    setModalVisible(false);
    setImageUrl('');
    setCrop(initialCrop());
  };
  const handleImageLoad = (image: HTMLImageElement) => {
    setImageSize({
      width: image.width,
      naturalWidth: image.naturalWidth,
      height: image.height,
      naturalHeight: image.naturalHeight
    });
  };

  useEffect(() => {
    setCrop(initialCrop());
  }, [imageSize, initialCrop]);

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={() => ''}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl || value ? <img src={imageUrl || value} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
      <Modal
        title="Crop Image"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <ReactCrop
          {...{ ...cropProps, minWidth, minHeight }}
          src={imageUrl}
          crop={crop}
          ruleOfThirds
          onImageLoaded={handleImageLoad}
          onChange={setCrop}
          keepSelection
        />
      </Modal>
    </>
  );
};