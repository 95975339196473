import React from 'react';
import { InvoiceProps, InvoiceStatus } from 'models/Invoice';
import { Button, Tooltip, Menu, Dropdown } from 'antd';
import { ShareAltOutlined, CopyOutlined, MailOutlined, MessageOutlined, BellOutlined } from '@ant-design/icons';
import { copyTextToClipboard } from 'utils/Copy';
import { getInvoiceUrl } from 'utils/Routes';
import { mailInvoice } from 'utils/Mail';
import { smsInvoice } from 'utils/Sms';
import { pushInvoice } from 'utils/Push';
import { AccountSettings } from 'models/AccountSettings';

interface InvoiceShareButtonProps {
  invoice: InvoiceProps;
  size?: 'small' | 'middle' | 'large'
}

export const InvoiceShareButton: React.FC<InvoiceShareButtonProps> = ({ invoice, size }) => {
  const settings = AccountSettings.getRecent();
  const menu = (
    <Menu>
      <Menu.Item
        icon={<CopyOutlined />}
        onClick={() => copyTextToClipboard(getInvoiceUrl(invoice.invoiceId))}
      >
        Copy invoice link
      </Menu.Item>
      <Menu.Item
        icon={<MailOutlined />}
        onClick={() => mailInvoice(invoice, true)}
      >
        Send invoice by email
      </Menu.Item>
      <Menu.Item
        icon={<MessageOutlined />}
        onClick={() => smsInvoice(invoice, true)}
      >
        Send invoice by sms
      </Menu.Item>
      {settings?.mobileAppEnabled() ? (
        <Menu.Item
          icon={<BellOutlined />}
          onClick={() => pushInvoice(invoice, true)}
        >
          Send invoice by push notification
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <Tooltip title="Share invoice">
      <Dropdown overlay={menu}>
        <Button size={size} icon={<ShareAltOutlined />} disabled={invoice.status === InvoiceStatus.COMPLETE} />
      </Dropdown>
    </Tooltip>
  );
};