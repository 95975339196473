import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, DatePicker, Form, Select, Typography } from 'antd';
import {} from '@ant-design/icons';
import { AccountReportsModel } from './AccountReportsModel';
import { useForm } from 'antd/lib/form/util';
import { CurrencySuggest } from 'components/CurrencySuggest';
import { AccountUserNames, AccountUserRoles } from 'models/AccountUser';

interface AccountReportsViewProps {
  model: AccountReportsModel;
}

export const AccountReportsView: React.FC<AccountReportsViewProps> = observer(({ model }) => {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({ type: 'paidDates' })
  }, [form]);

  return (
    <div className="account-reports">
      <Typography.Title level={2}>Paid Invoices</Typography.Title>

      <Form
        form={form}
        layout="inline"
        onFinish={model.handlePaidInvoicesDownload}
      >
        <Form.Item
          label="Type"
          name="type"
        >
          <Select >
            <Select.Option value="paidDates">Invoice Paid Dates</Select.Option>
            <Select.Option value="openDates">Invoice Open Dates</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="From"
          name="invoicesFrom"
          rules={[
            { required: true, message: 'Please select the date' }
          ]}
          required
        >
          <DatePicker/>
        </Form.Item>
        <Form.Item
          label="To"
          name="invoicesTo"
          rules={[
            { required: true, message: 'Please select the date' }
          ]}
          required
        >
          <DatePicker/>
        </Form.Item>
        <CurrencySuggest
          value={form.getFieldValue('currency')}
          onChange={(value) => form.setFieldsValue({ currency: value })}
          anyCurrency
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={model.invoicesLoading}
          >
            Download
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});