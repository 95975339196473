import bind from 'bind-decorator';
import { observable } from 'mobx';
import { changeAccount } from 'services/Accounts';
import { AccountsStore } from 'stores/Accounts';
import { GlobalStore } from 'stores/Global';
import { notification } from 'antd';
import { getAccountRoute } from 'utils/Routes';

export enum CURRENCY_OPTIONS {
  MY_BANK = 1,
  CUSTOM = 2
}

export class AccountEditModel {
  @observable loading: boolean = false;
  @observable currencyOption: CURRENCY_OPTIONS = CURRENCY_OPTIONS.MY_BANK;
  @observable currencies: string[] = [];

  accountId: string = '';
  name: string = '';
  slug: string = '';
  logo: string = '';
  address: string = '';
  accentColor: string = '';

  constructor(accountId: string, name: string, logo: string, slug: string, address: string, accentColor: string, currencies: string[]) {
    this.accountId = accountId;
    this.name = name;
    this.slug = slug;
    this.logo = logo;
    this.address = address;
    this.accentColor = accentColor;
    this.currencies = currencies || [];

    if (currencies && currencies.length) {
      this.currencyOption = CURRENCY_OPTIONS.CUSTOM;
    }
  }

  @bind
  async finishEdit(values: any) {
    this.loading = true;

    try {
      const response = await changeAccount(this.accountId, {
        name: values.name,
        slug: values.slug,
        logo: values.logo,
        address: values.address,
        accentColor: values.accentColor,
        currencies: values.currencies?.length ? values.currencies : null
      });
      const account = AccountsStore.getAccount(this.accountId);

      if (account) {
        account.name = response.data.data.name;
        account.slug = response.data.data.slug;
        account.logo = response.data.data.logo;
        account.address = response.data.data.address;
        account.accentColor = response.data.data.accentColor;
      }

      notification.success({
        message: 'Company saved',
        description: 'Company was successfully edited and saved'
      });

      GlobalStore.navigate(getAccountRoute(this.accountId, values.slug));
    } catch (e) {
    }

    this.loading = false;
  }
}