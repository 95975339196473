import bind from 'bind-decorator';
import { observable } from 'mobx';
import { AccountUser, AccountUserProps, AccountUserRoles } from 'models/AccountUser';
import { AccountSettings } from 'models/AccountSettings';
import { getUsers, inviteUser, removeUser, changeUser } from 'services/Users';
import { notification } from 'antd';

export class AccountAccessModel {
  @observable loading: boolean = true;
  @observable tableLoading: boolean = false;
  @observable modalLoading: boolean = false;
  @observable modalVisible: boolean = false;
  @observable users: AccountUser[] = [];

  @observable pagintaion = {
    skip: 0,
    take: 10
  };

  loaded: boolean = false;
  accountSettings: AccountSettings | null = null;
  accountId: string = '';

  constructor(accountId: string) {
    this.accountId = accountId;
    this.accountSettings = new AccountSettings(this.accountId);
  }

  @bind
  async fetchUsers() {
    if (this.loaded) {
      this.tableLoading = true;
    } else {
      this.loading = true;

      await this.accountSettings?.fetch();
    }

    try {
      const response = await getUsers(this.accountId);

      this.users = response.data.data.map((item: AccountUserProps) => {
        return new AccountUser(item);
      });
    } catch (e) {
    }

    this.tableLoading = false;
    this.loading = false;
    this.loaded = true;
  }

  @bind
  handleInvite() {
    this.modalVisible = true;
  }

  @bind
  async handleRevoke(userId: string) {
    this.tableLoading = true;

    try {
      await removeUser(this.accountId, userId);

      await this.fetchUsers();
    } catch (e) {
    }

    this.tableLoading = false;
  }

  @bind
  async handleResend(userId: string) {
    const user = this.users.find((user) => user.userId === userId);

    if (user) {
      try {
        await inviteUser(this.accountId, {
          name: user.name,
          email: user.email,
          role: user.role
        });

        notification.success({
          message: 'Invitation Sent',
          description: `User ${user.name} was invited to account`
        });
      } catch (e) {
      }
    }
  }

  @bind
  async handleRoleChange(userId: string, role: AccountUserRoles) {
    await changeUser(this.accountId, userId, { role });
  }

  @bind
  async handleDefaultToggle(userId: string, isDefault: boolean) {
    const user = this.users.find((user) => user.userId === userId);

    if (user) {
      user.isDefault = isDefault;
    }

    await changeUser(this.accountId, userId, { isDefault });
  }

  @bind
  handleCancel() {
    this.modalVisible = false;
  }

  @bind
  async handleOk(values: any) {
    this.modalLoading = true;

    try {
      await inviteUser(this.accountId, {
        name: values.name,
        email: values.email,
        message: values.message,
        role: values.role
      });

      notification.success({
        message: 'Invitation Sent',
        description: `User ${values.name} was invited to account`
      });

      await this.fetchUsers();

      this.modalVisible = false;
    } catch (e) {
    }

    this.modalLoading = false;
  }

  @bind
  takeChange(_current: number, pageSize: number) {
    this.pagintaion.take = pageSize;
    this.pagintaion.skip = 0;
  }

  @bind
  paginationChange(current: number) {
    this.pagintaion.skip = (current - 1) * this.pagintaion.take;
  }
}