import { Api } from './Api';

interface AccountProps {
  name: string,
  slug?: string | null,
  logo: string,
  address: string,
  accentColor: string,
  currencies?: string[]
}

export const getAccounts = () => {
  return Api.get('/accounts');
};

export const createAccount = (data: AccountProps) => {
  return Api.post('/accounts', data);
};

export const changeAccount = (accountId: string, data: AccountProps) => {
  return Api.put(`/accounts/${accountId}`, data);
};

export const getAccount = (accountId: string) => {
  return Api.get(`/accounts/${accountId}`);
};

export const getAccountSettings = (accountId: string) => {
  return Api.get(`/accounts/${accountId}/settings`, {}, { errorsHandled: [404] });
};

export const getPublicAccount = (accountId: string) => {
  return Api.get(`/public/accounts/${accountId}`);
};

export const getCurrencies = (accountId: string) => {
  return Api.get(`/accounts/${accountId}/currencies`);
};