import { Api } from './Api';

export interface Pagination {
  skip: number;
  take: number;
}

export const getAccounts = (pagination?: Pagination, filters?: IIndexable) => {
  return Api.get('/admin/accounts', { ...pagination, ...filters });
};

export const getAccountROs = (accountId: string, pagination?: Pagination, filters?: IIndexable) => {
  return Api.get(`/admin/accounts/${accountId}/repair-orders`, { ...pagination, ...filters });
};

export const addAccountRO = (accountId: string, ro: IIndexable) => {
  return Api.post(`/admin/accounts/${accountId}/repair-orders`, ro);
};

export const editAccountRO = (accountId: string, ro: IIndexable) => {
  return Api.put(`/admin/accounts/${accountId}/repair-orders/${ro.roNumber}`, ro);
};

export const removeAccountRO = (accountId: string, roNumber: string) => {
  return Api.delete(`/admin/accounts/${accountId}/repair-orders/${roNumber}`);
};

export const getAccountFIs = (accountId: string, pagination?: Pagination, filters?: IIndexable) => {
  return Api.get(`/admin/accounts/${accountId}/finance-insurances`, { ...pagination, ...filters });
};

export const addAccountFI = (accountId: string, fi: IIndexable) => {
  return Api.post(`/admin/accounts/${accountId}/finance-insurances`, fi);
};

export const removeAccountFI = (accountId: string, fiId: string) => {
  return Api.delete(`/admin/accounts/${accountId}/finance-insurances/${fiId}`);
};