import React from 'react';
import { Modal, Input } from 'antd';
import { ExclamationCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { InvoiceProps } from 'models/Invoice';
import { formatPhoneNumber, rawPhoneNumber } from './Phone';
import { editCustomer, getCustomer } from 'services/Customers';
import { smsInvoice } from './Sms';
import { CustomerProps } from 'models/Customer';
import { EventEmitter } from './EventEmitter';
import { EVENTS } from '~/enums/common';

export const smsConfirm = (invoice: InvoiceProps) => {
  let input: Input | null = null;

  Modal.confirm({
    title: 'Please specify customer phone number',
    icon: <ExclamationCircleOutlined />,
    content: (
      <Input
        ref={(ref) => {
          input = ref;

          if (ref) {
            setTimeout(() => ref.focus(), 150);
          }
        }}
        type="tel"
        placeholder="Customer Phone Number"
        prefix={<PhoneOutlined />}
      />
    ),
    async onOk() {
      if (input) {
        const phoneNumber = rawPhoneNumber(input.input.value);

        if (!phoneNumber) {
          return;
        }

        if (invoice.customer.customerId) {
          try {
            const response = await getCustomer(invoice.accountId, invoice.customer.customerId);
            const responseCustomer: CustomerProps = response.data.data;

            await editCustomer(invoice.accountId, invoice.customer.customerId, {
              name: responseCustomer.name,
              email: responseCustomer.email || '',
              phoneNumber: phoneNumber,
              notes: responseCustomer.notes
            });

            invoice.customer.phoneNumber = phoneNumber;

            EventEmitter.trigger(EVENTS.CUSTOMER_UPDATED);
          } catch (e) {
            return false;
          }
        }

        await smsInvoice(invoice);

        return true;
      }

      return false;
    }
  });
};

export const smsEnsure = (invoice: InvoiceProps) => {
  Modal.confirm({
    title: 'Please Confirm',
    icon: <ExclamationCircleOutlined />,
    content: `The sms will be sent to ${invoice.customer.name} (${formatPhoneNumber(invoice.customer.phoneNumber)}). Would you like to proceed?`,
    async onOk() {
      await smsInvoice(invoice);

      return true;
    }
  });
}